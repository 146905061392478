import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import { openPostbox } from 'mewe/utils/dialogs-common';
import { Theme } from 'mewe/constants';
import GroupApi from 'mewe/api/group-api';

export default class AppGroupFeed extends Component {
  @service router;
  @service dynamicDialogs;

  @tracked showDiscoverBanner;

  constructor() {
    super(...arguments);

    this.groupUpdatedWrapped = () => scheduleOnce('afterRender', this, this.groupUpdated);
    this.groupUpdatedWrapped();

    addObserver(this, 'model.appGroup.groupId', this.groupUpdatedWrapped);
    addObserver(this, 'model.appGroup.group.isFetching', this.groupUpdatedWrapped);
  }

  willDestroy() {
    removeObserver(this, 'model.appGroup.groupId', this.groupUpdatedWrapped);
    removeObserver(this, 'model.appGroup.group.isFetching', this.groupUpdatedWrapped);
  }

  get model() {
    return this.args.model;
  }

  groupUpdated() {
    this.showDiscoverBanner = false;

    const group = this.model.appGroup.group;

    if (group.isOwnerAdmin && group.isFetching === false) {
      GroupApi.isDiscoverHintValid(this.model.appGroup.groupId).then((isValid) => {
        if (this.isDestroyed || this.isDestroying) return;
        this.showDiscoverBanner = isValid;
      });
    }
  }

  @action
  openInviteMembersDialog() {
    this.dynamicDialogs.openDialog('invitations-dialog', {
      group: this.model.appGroup.group,
    });
  }

  @action
  openPostBoxDialog() {
    const params = {
      theme: Theme.GROUP,
      target: Theme.GROUP,
      groupId: this.model.appGroup.group.id,
      preselectedGroup: this.model.appGroup.group,
    };

    openPostbox(params, this.dynamicDialogs);
  }

  @action
  openPendingPostHashTag(hash) {
    this.router.transitionTo('app.group', { queryParams: { tag: hash } });
  }

  @action
  goToSettings() {
    this.router.transitionTo('app.group.settings', { queryParams: { initialTabNumber: 1 } });
  }
}
