/* eslint-disable ember/no-private-routing-service */
/* eslint-disable ember/no-get */
/* eslint-disable ember/no-computed-properties-in-native-classes */
/* eslint-disable ember/no-observers */
import { each, escape, unescape } from 'lodash';
import 'mewe/services/tasks';
import 'mewe/services/chat';
import GroupStore from 'mewe/stores/group-store';
import InvitationsUtils from 'mewe/utils/invitations-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import dispatcher from 'mewe/dispatcher';
let { isDefined } = MiscellaneousUtils;

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { addObserver, removeObserver } from '@ember/object/observers';
import { action, set, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import FollowingApi from 'mewe/api/following-api';
export default class ProfileGroup extends Component {
  @tracked isEditingPermissions;

  @tracked dropdownMenuOpened;

  @tracked optionsDropdownOpened;

  @tracked task;

  @service('chat') ChatService;

  @service tasks;

  @service router;

  @service account;

  @service dynamicDialogs;

  @reads('args.contact') contact;

  @reads('args.group') group;

  photoDialogTitles = {
    choosePhoto: __('Choose Group Profile Photo'),
    cropPhoto: __('Edit Group Profile Photo'),
  };

  @action
  onInsert() {
    this.task = this.tasks.getTask(`photo.avatar.group.${this.args.groupId}`);
    this.selectPhotoBind = this.selectPhoto.bind(this);
    addObserver(this, 'contact.isContact', this, this.userLoaded);
    addObserver(this, 'contact.groupRole', this, this.userLoaded);
    addObserver(this, 'args.event', this, this.userLoaded);
  }

  @action
  willDestroy() {
    super.willDestroy(...arguments);
    removeObserver(this, 'contact.isContact', this, this.userLoaded);
    removeObserver(this, 'contact.groupRole', this, this.userLoaded);
    removeObserver(this, 'args.event', this, this.userLoaded);
  }

  userLoaded() {
    /**
     * redirect to main profile if opened group profile
     * and user is not group member
     */
    if (isDefined(this.contact.isContact) && !this.contact.groupRole && !this.args.event) {
      this.router.transitionTo('app.publicid', this.contact.profileId);
    }
  }

  @computed('account.activeUser.id', 'contact.{id,isContact,nsfwAvatar}')
  get isNsfwBlurred() {
    if (this.contact.isContact) {
      return false;
    }
    if (this.contact.id == this.account.activeUser?.id) {
      return false;
    }
    return this.contact.nsfwAvatar;
  }

  @computed('group.isConfirmed')
  get canEditAvatar() {
    return this.group?.isConfirmed;
  }

  @computed('args.{event,groupId}')
  get isEventWithoutGroup() {
    return this.args.event && !this.args.groupId;
  }

  @computed('isEventWithoutGroup', 'group.isConfirmed')
  get showProfileIntroduction() {
    if (this.group) {
      return this.group?.isConfirmed;
    } else {
      return !this.isEventWithoutGroup;
    }
  }

  @computed('isEventWithoutGroup')
  get url() {
    return this.isEventWithoutGroup ? '/api/v2/photo/ev' : '/api/v2/photo/gp';
  }

  @computed('contact.role.name')
  get groupRoleText() {
    return __(this.contact.role.name); // __("Owner"); __("Admin"); __("Contributor"); __("Viewer"); __("Custom")
  }

  @computed('contact.groupRole', 'group.isOwnerAdmin')
  get canBlockUserInAllGroups() {
    return !['Admin', 'Owner'].includes(this.contact.groupRole) && this.group?.isOwnerAdmin;
  }

  @computed('contact.{isMe,groupRole}', 'group.role')
  get canEditPermissions() {
    if (this.contact.isMe) {
      return false;
    }

    let currentUserGroupRole = this.group.role;

    if (currentUserGroupRole === 'Owner') {
      return true;
    } else if (currentUserGroupRole === 'Admin') {
      return this.contact.groupRole !== 'Owner' && this.contact.groupRole !== 'Admin';
    } else {
      return false;
    }
  }

  @computed('group.groupQuestions.@each.question', 'contact.groupDescription.answers')
  get groupQuestions() {
    let questions = A();
    let answers = this.contact.groupDescription.answers;

    each(this.group?.groupQuestions, (q, i) => {
      let answer = '';
      if (answers && answers[i] && answers[i].answer) {
        answer = answers[i].answer;
      }

      questions.push({
        question: q.question,
        questionDisplay: EmojisParser.toDisplay(q.questionDisplay),
        answerEdit: EmojisParser.toEdit(escape(answer)),
        answerDisplay: EmojisParser.toDisplay(escape(answer)),
      });
    });

    return questions;
  }

  @computed('contact.isMe', 'args.groupId')
  get alwaysShowAnswer() {
    let groupData = GroupStore.getState({ id: this.args.groupId });
    return (groupData && groupData.get('isOwnerAdmin')) || this.contact.isMe;
  }

  selectPhoto(blob, params) {
    params.groupId = this.args.groupId;
    dispatcher.dispatch('contact', 'setAvatar', blob, params, this.task);
  }

  @action
  openPermissions() {
    this.isEditingPermissions = true;
  }

  @action
  closePermissions() {
    this.isEditingPermissions = false;
  }

  @action
  removeMember() {
    if (this.group) {
      dispatcher.dispatch('group-member', 'removeMember', this.contact, this.group);
    }
  }

  @action
  save() {
    const answers = this.groupQuestions.map((q) => {
      return {
        question: q.question,
        answer: EmojisParser.toServer(unescape(q.answerEdit)),
      };
    });

    set(this, 'contact.groupDescription.answers', answers);

    dispatcher.dispatch('contact', 'saveGroupProfile', this.args.groupId, this.contact);
  }

  @action
  opendEditDropdown() {
    this.dropdownMenuOpened = !this.dropdownMenuOpened;
  }

  @action
  openOptionsDropdown() {
    this.optionsDropdownOpened = !this.optionsDropdownOpened;
  }

  @action
  async chat() {
    if (this.contact.canChat) {
      this.ChatService.openThreadByParticipants([this.contact], { doNotClose: true });
    } else {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        message: __('This member has turned off chat requests. To chat with them you must first follow each other.'),
        cancelButtonText: __('Cancel'),
        okButtonText: __('Follow'),
        onConfirm: async () => {
          const request = await FollowingApi.doFollow(this.contact.id);
          if (request?.limitReached) {
            this.account.followLimitPopup();
            return;
          }

          FunctionalUtils.info(__('Invitation sent'));
        },
      });
    }
  }

  @action
  blockFromAllGroups() {
    dispatcher.dispatch('contact', 'blockUserFromAllGroup', this.contact, this.args.groupId, () => {
      if (this.isEventWithoutGroup) {
        this.router.transitionTo('app.event', this.args.event?.id);
        // if user was blocked by event owner then he's automatically removed from event
      } else {
        this.router.transitionTo('app.group.index', this.args.groupId);
      }
    });
  }

  @action
  block() {
    dispatcher.dispatch(
      'contact',
      'blockUser',
      this.contact,
      () => {
        if (this.isEventWithoutGroup) {
          this.router.transitionTo('app.event', this.args.event?.id);
          // if user was blocked by event owner then he's automatically removed from event
        } else {
          this.router.transitionTo('app.group.index', this.args.groupId);
        }
      },
      this.args.groupId,
      this.args.event?.id
    );
  }

  @action
  report() {
    dispatcher.dispatch('contact', 'reportUser', this.contact, this.args.groupId, this.args.event?.id);
  }

  @action
  openProfilePhoto() {
    const photoUrl = this.contact._links.avatar.href;

    this.dynamicDialogs.openDialog('media-dialog', {
      photoUrl: photoUrl,
      mediaType: 'photoUrl',
      allowMultipleInstances: true,
    });
  }

  @action
  setDescription(newValue) {
    set(this, 'contact.groupDescription.textPlainEdit', newValue);
  }

  @action
  setGroupQuestion(question, newValue) {
    set(question, 'answerEdit', EmojisParser.toEdit(escape(newValue)));
    set(question, 'answerDisplay', EmojisParser.toDisplay(escape(newValue)));
  }
}
