import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import GroupStore from 'mewe/stores/group-store';
import { showGroupPreviewDialog } from 'mewe/utils/dialogs-common';
import { postCustomInteraction } from 'mewe/utils/post-utils';

export default class MwPostOriginLinkComponent extends Component {
  @service dynamicDialogs;

  @service router;

  get post() {
    return this.args.post;
  }

  get group() {
    return this.post?.group;
  }

  get isContacts() {
    return this.post?.contacts || this.group?.id === 'contacts';
  }

  get isPage() {
    return this.args.postedByPage && this.post?.page?.publicLinkId;
  }

  get isGroup() {
    return this.group?.id && !this.isContacts;
  }

  get isEvent() {
    return this.post?.event2 && !this.post?.eventInfo;
  }

  get route() {
    if (this.isEvent) {
      return 'app.event';
    } else if (this.isPage) {
      return 'app.publicid';
    } else if (this.isGroup) {
      return 'app.group';
    } else {
      return 'app.myworld';
    }
  }

  get models() {
    if (this.isEvent) {
      return [this.post?.event2?.id];
    } else if (this.isPage) {
      return [this.post?.page?.publicLinkId];
    } else if (this.isGroup) {
      return [this.group?.id];
    } else {
      return [];
    } // array has to be always returned
  }

  get avatarUrl() {
    if (this.isPage) {
      return this.post.page.cover;
    } else if (this.isGroup) {
      return this.post?.group?.avatarLink;
    } else {
      return '';
    }
  }

  get showJoinButton() {
    if (this.args.postInPostbox) {
      return false;
    }
    if (this.args.isSharedPost) {
      return false;
    }
    if (this.args.inMediaDialog) {
      return false;
    }

    return this.post?.discoverScope && this.isGroup && !this.post?.group?.alreadyApplied;
  }

  @action
  handleClick() {
    this.dynamicDialogs.closeAll();

    if (this.isGroup && !this.args.postInPostbox) {
      if (this.post?.customInteraction) {
        postCustomInteraction(this.dynamicDialogs, this.post);
        return false;
      } else {
        const group = GroupStore.getGroupIfMember(this.group.id);
        if (!group) {
          showGroupPreviewDialog(this.dynamicDialogs, this.group, {
            isDiscoverPostInteraction: false,
            isGroupPreview: true,
          });
          return false;
        } else {
          this.router.transitionTo('app.group', this.group?.id);
        }
      }
    } else if (this.isEvent) {
      this.router.transitionTo('app.event', this.post?.event2?.id);
    } else if (this.isContacts) {
      this.router.transitionTo('app.myworld.index', { queryParams: { scope: 'pages' } });
    } else {
      this.router.transitionTo('app.group', this.group?.id);
    }
  }
}