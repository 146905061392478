import EmberObject from '@ember/object';
import { ActionHandler } from '@ember/-internals/runtime';
// Contact model has Profile model included
import Contact from 'mewe/stores/models/contact-model';
import { cond, isObject, identity, stubTrue, flip, conforms, isString } from 'lodash';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';

const serializer = JSONSerializer.create();

// { k: v }
const state = {};

// :: { uuid: String, groupId: String } -> Contact
const getGroupProfile = (params) =>
  returnOrCreate(state[`${params.uuid}-${params.groupId}`], `${params.uuid}-${params.groupId}`);

// :: { uuid: String, eventId: String } -> Contact
const getEventProfile = (params) =>
  returnOrCreate(state[`${params.uuid}-${params.eventId}`], `${params.uuid}-${params.eventId}`);

// :: { uuid: String } -> Contact
const getProfile = (params) => returnOrCreate(state[params.uuid], params.uuid);

// :: uuid: String -> Contact
const create = (uuid) => (state[uuid] = Contact.create());

// :: Contact -> uuid: String -> Contact
// :: nil -> uuid: String -> Contact
const returnOrCreate = cond([
  [isObject, identity],
  [stubTrue, flip(create)],
]);

// :: { uuid: String, groupId: String } -> Contact
// :: { uuid: String } -> Contact
const getState = cond([
  [conforms({ uuid: isString, eventId: isString }), getEventProfile],
  [conforms({ uuid: isString, groupId: isString }), getGroupProfile],
  [conforms({ uuid: isString }), getProfile],
  [stubTrue, () => state],
]);

const self = EmberObject.extend(ActionHandler, {
  getState: getState,

  actions: {
    handleRawData: (data, groupId, eventId) => {
      data = Object.assign(data, data.user);
      delete data.user;

      // hack for group profile until it will be adjusted on BE to have the same structure as general profile
      // ticket for BE to adjust fields: https://sgrouples.atlassian.net/browse/SG-38461
      if (groupId || eventId) {
        data.favorite = !!data.closeFriend;
        delete data.closeFriend;
      }

      // BE doesn't send some fields in case they're empty but we need it to
      // overwrite existing values if profile was fetched before and there were some changes in state
      if (!data.followRequestSent) {
        data.followRequestSent = null;
      }
      if (!data.following) {
        data.following = false;
      }
      if (!data.follower) {
        data.follower = false;
      }

      //BE isContact is deprecated
      data.isContact = data.following;

      return serializer.updateInstance(
        getState({ uuid: data.id, groupId: groupId, eventId: eventId }),
        Object.assign({ closeFriend: false }, data)
      );
    },

    failToFetchData: (data) =>
      getState({ uuid: data.uuid, groupId: data.groupId, eventId: data.eventId }).set('error', data.error),

    updateData: (data, groupId, eventId) =>
      getState({ uuid: data.id, groupId: groupId, eventId: eventId }).setProperties(data),
  },
});

export default self.create();
