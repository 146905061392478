/* eslint-disable ember/no-get */
/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import EmberObject, { action, set, get } from '@ember/object';
import { A } from '@ember/array';
import { addObserver, removeObserver } from '@ember/object/observers';
import { each, compact, find } from 'lodash';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import Scrolling from 'mewe/utils/scrolling-utils';
import ChatUtils from 'mewe/utils/chat-utils';
import GroupApi from 'mewe/api/group-api';
import EventsApi from 'mewe/api/events-api';
import PagesApi from 'mewe/api/pages-api';
import isUndefined from 'mewe/utils/isUndefined';
import FollowingApi from 'mewe/api/following-api';

export default class MwInviteUser extends Component {
  @service account;
  @service dynamicDialogs;

  maxResults = 30;
  maxSelectedContactsCount = 9;
  scrolling = Scrolling();

  @tracked isLoadingUsers;
  @tracked searchQuery = '';
  @tracked lastQuery = '';
  @tracked invitees = A();
  @tracked users = A();
  @tracked offset = 0;

  constructor() {
    super(...arguments);
    this.loadUsers();
  }

  @action
  onInsert(element) {
    this.element = element;
    addObserver(this, 'searchQuery', this.searchQueryChange);
    addObserver(this, 'users.length', this.selectedItemsChange);
    addObserver(this, 'invitees.length', this.selectedItemsChange);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('.contact-invitation_contacts_list'));
    removeObserver(this, 'searchQuery', this.searchQueryChange);
    removeObserver(this, 'users.length', this.selectedItemsChange);
    removeObserver(this, 'invitees.length', this.selectedItemsChange);
  }

  loadUsers() {
    if (this.args.eventData) {
      if (this.args.isEventAttendeePicker) {
        this.fetchAttendees();
      } else {
        this.fetchAttendeeSuggestions();
      }
    } else if (this.args.isGroupMembersPicker) {
      this.fetchGroupMembers();
    } else {
      this.fetchContacts();
    }
  }

  get headerText() {
    if (this.args.isGroupMembersPicker) {
      return __('All group members invited');
    }

    return __('All followers invited');
  }

  get showSearch() {
    if (this.args.isTransferOwnershipPicker) {
      return true;
    } else {
      return !this.args.isGroupMembersPicker && !this.args.isEventAttendeePicker;
    }
  }

  get isSendBtnDisabled() {
    return this.args.isSending || !this.invitees.length;
  }

  get searchPlaceholder() {
    if (this.args.isGroupMembersPicker) {
      return __('Search group members');
    } else if (this.args.isPageFollowerPicker) {
      return __('Search users');
    } else {
      return __('Search');
    }
  }

  get sendButtonText() {
    if (this.args.saveText) {
      return this.args.saveText;
    } else if (this.args.isSingleSelect) {
      return __('Select user');
    } else if (this.args.eventData?.eventInviteBtnText) {
      return this.args.eventData.eventInviteBtnText;
    } else {
      return __('Send Invitations');
    }
  }

  get filteredUsers() {
    return this.users.filter((user) => user.roleName !== 'Owner');
  }

  get showSelectedUsers() {
    return this.invitees.length && !this.args.isSingleSelect;
  }

  get singleUserSelect() {
    return this.args.isSingleSelect || this.args.isTransferOwnershipPicker;
  }

  selectedItemsChange() {
    let preSelectedUsersIds = A();
    let inviteesById = {};

    if (this.args.preSelectedUsers) {
      preSelectedUsersIds = this.args.preSelectedUsers.map((u) => u.id);
    }

    this.invitees.forEach((i) => (inviteesById[i.id] = i));
    this.users.forEach((c) => {
      if (preSelectedUsersIds.indexOf(c.user.id) > -1) {
        set(c, 'isDisabled', true);
        set(c, 'selected', true);
      } else {
        set(c, 'selected', !isUndefined(inviteesById[c.user.id]));
      }
    });
  }

  searchQueryChange() {
    clearTimeout(this.searchTimeout);

    let timeout = setTimeout(() => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.fetchSearch();
    }, 400);

    this.searchTimeout = timeout;
  }

  renderResults(data) {
    if (data.members) {
      data.list = data.members;
      data.number = data.members.length;
    }

    if (data && data.list) {
      data.list = this.processContacts(data.list); // contacts search
      this.users.pushObjects(data.list);
    } else {
      if (data && data.foundUsers) {
        data.foundUsers = this.processUsers(data.foundUsers);
      }
      // public search, users
      else {
        data.foundUsers = A();
      }

      if (data && data.foundGroups) {
        data.foundGroups = this.processGroups(data.foundGroups);
      }
      // public search, groups
      else {
        data.foundUsers = A();
      }

      // TODO should it be only bigger lenght included in offset?
      // if (data.foundUsers && data.foundGroups) {
      //   (data.foundUsers.length > data.foundGroups.length) ? this.incrementProperty('usersOffset', data.foundUsers.length) : this.incrementProperty('usersOffset', data.foundGroups.length);
      // }

      this.users.pushObjects(data.foundGroups);
      this.users.pushObjects(data.foundUsers);
    }
  }

  processGroups(groups) {
    each(groups, function (group) {
      group = EmberObject.create(group);

      group.isGroup = true;

      if (group._links) {
        if (group._links && group._links.publicAvatar) {
          group.avatar =
            EnvironmentUtils.getImgHost(true) + group._links.publicAvatar.href.replace('{imageSize}', '400x400');
        } else if (group._links.avatar) {
          group.avatar = EnvironmentUtils.getImgHost(true) + group._links.avatar.href.replace('{imageSize}', '400x400');
        }
      }
    });

    return groups;
  }

  processContacts(users) {
    const usersData = A();
    const existingUsers = this.users;

    each(users, (user) => {
      if (existingUsers.find((u) => u.user.id === user.user.id)) {
        return;
      }

      if (user.user._links && user.user._links.avatar) {
        user.user.avatar =
          EnvironmentUtils.getImgHost(true) + user.user._links.avatar.href.replace('{imageSize}', '150x150');
      }

      user.user.canSuggest = user.canSuggest;
      user.user.invited = user.invited || user.isFollower;
      user.user.inGroup = user.inGroup;

      user.invited = user.invited || user.isFollower || user.isGroupMember;
      user.isDisabled = user.invited || user.inGroup;

      const isTransfter = this.args.isTransferOwnershipPicker && this.args.isGroupMembersPicker;
      if (isTransfter) {
        // only owner is disabled for transfter
        // (current user has to be owner because otherwise couldn't open transfer dialog)
        user.isDisabled = user.user.id === this.account.activeUser.id || user.invitation;
      }

      usersData.push(EmberObject.create(user));
    });

    return usersData;
  }

  processUsers(users) {
    each(users, (user) => {
      user = EmberObject.create(user);

      user.visibleData = this.getUserData(user).slice(0, 2);
      if (user._links && user._links.avatar) {
        user.avatar = EnvironmentUtils.getImgHost(true) + user._links.avatar.href.replace('{imageSize}', '150x150');
      } else {
        user.avatar = EnvironmentUtils.getImgHost() + '/photo/profile/150x150/' + user.id + '?f=' + user.fprint;
      }
    });

    return users;
  }

  getUserData(user) {
    let userData = A();

    if (user.currentCity) {
      userData.push({
        label: __('City'),
        value: user.currentCity,
      });
    }
    if (user.college) {
      userData.push({
        label: __('College'),
        value: user.college,
      });
    }
    if (user.highSchool) {
      userData.push({
        label: __('High school'),
        value: user.highSchool,
      });
    }
    if (user.relationshipStatus) {
      userData.push({
        label: __('Relationship Status'),
        value: user.relationshipStatus,
      });
    }
    if (user.job) {
      userData.push({
        label: __('Job'),
        value: user.job,
      });
    }
    if (user.company) {
      userData.push({
        label: __('Company'),
        value: user.company,
      });
    }
    if (user.interests) {
      userData.push({
        label: __('Interests'),
        value: user.interests,
      });
    }

    return userData;
  }

  fetchSearch(nextPage) {
    const query = this.searchQuery.trim();
    const skipSearch = query === this.lastQuery;

    this.lastQuery = query;

    if (skipSearch) {
      return;
    }

    if (query === '') {
      this.users = A();
      this.offset = 0;
      this.loadUsers();
      return;
    }

    this.isLoadingUsers = true;

    if (!nextPage) {
      this.users = A();
      this.offset = 0;
    }

    const params = {
        search: query,
        maxResults: this.maxResults,
        offset: this.offset,
      },
      callback = (data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.renderResults(data);

        this.isLoadingUsers = false;

        if (!data.foundUsers) {
          data.foundUsers = A();
        }
        if (!data.foundGroups) {
          data.foundGroups = A();
        }

        this.offset = this.offset + this.maxResults;

        if (
          (data.list && data.list.length >= this.maxResults) ||
          data.foundUsers.length + data.foundGroups.length >= this.maxResults ||
          (data.members && data.members.length) >= this.maxResults
        ) {
          this.scrolling.bindScrollDownElement(this.element.querySelector('.contact-invitation_contacts_list'), () =>
            this.fetchSearch(true)
          );
        } else {
          this.scrolling.unbindScrollDown(this.element.querySelector('.contact-invitation_contacts_list'));
        }
      };

    if (this.args.group?.id) {
      params.markGroupMembersOf = this.args.group.id;
    }
    if (this.args.page?.id) {
      params.markPageFollowersOf = this.args.page.id;
    }

    if (!this.args.isTransferOwnershipPicker && this.args.eventData?.id) {
      params.query = params.search;
      delete params.search;

      EventsApi.getAttendeeSuggestions(this.args.eventData.id, params).then((data) => {
        data.list = data.suggestions;
        callback(data);
      });

      return;
    }

    if (this.args.isTransferOwnershipPicker && this.args.isEventAttendeePicker) {
      this.nextPage = null;
      this.users = A();
      this.fetchAttendees();
      return;
    }

    if (this.args.isTransferOwnershipPicker && this.args.isPageFollowerPicker) {
      this.nextPage = null;
      this.users = A();
      this.fetchContacts();
      return;
    }

    if (this.args.isTransferOwnershipPicker && this.args.isGroupMembersPicker) {
      params.str = params.search;
      delete params.search;

      GroupApi.membersSearch(this.args.group.id, params).then(callback);

      return;
    }

    if (this.args.isInvitationToChat) {
      FollowingApi.followers(params).then(callback);
    } else {
      FollowingApi.toInvite(params).then(callback);
    }
  }

  fetchContacts() {
    this.isLoadingUsers = true;

    const params = {};

    params['with-admins'] = true;
    params.sort = 'name';
    params.nextPage = this.nextPage;
    params.str = this.searchQuery.trim();
    params.maxResults = this.maxResults;

    if (this.args.group?.id) {
      params.markGroupMembersOf = this.args.group.id;
    }

    if (this.args.page?.id) {
      params.markPageFollowersOf = this.args.page.id;
    }

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (this.searchQuery.trim() && !params.str) {
        return;
      }

      const contacts = data.contacts || data.members || data.followers || data.list || A();

      this.users.pushObjects(
        compact(
          contacts.map((c) => {
            if (!this.users.find((u) => u.id === get(c, 'user.id'))) {
              c.invited = c.inGroup || c.isFollower || c.isGroupMember;
              c.isDisabled = c.invited;
              return EmberObject.create(c);
            }
          })
        )
      );

      this.isLoadingUsers = false;
      this.nextPage = data._links?.nextPage?.href;

      this.offset += contacts.length;

      if (this.nextPage) {
        this.scrolling.bindScrollDownElement(this.element.querySelector('.contact-invitation_contacts_list'), () => {
          this.fetchContacts();
        });
      } else {
        this.scrolling.unbindScrollDown(this.element.querySelector('.contact-invitation_contacts_list'));
      }
    };

    if (this.args.isPageFollowerPicker) {
      PagesApi.followers(this.args.page.id, params).then(callback);
    } else if (this.args.isInvitationToChat) {
      FollowingApi.followers(params).then(callback);
    } else {
      FollowingApi.toInvite(params).then(callback);
    }
  }

  fetchGroupMembers() {
    this.isLoadingUsers = true;

    const groupId = this.args.group.id;
    const params = {
      offset: this.users.length,
      maxResults: this.maxResults,
    };

    GroupApi.members(groupId, params).then((data) => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const contacts = data.members || A();

      each(contacts, (c) => {
        c = EmberObject.create({
          ...c,
          isDisabled: c.invited || c.isGroupMemeber,
        });

        if (this.args.isTransferOwnershipPicker) {
          c.isDisabled = c.role && c.role.name === 'Owner'; // only owner is disabled for transfter
        }
      });

      this.users.pushObjects(contacts);
      this.isLoadingUsers = false;

      if (contacts.length) {
        this.scrolling.bindScrollDownElement(this.element.querySelector('.contact-invitation_contacts_list'), () =>
          this.fetchGroupMembers()
        );
      } else {
        this.scrolling.unbindScrollDown(this.element.querySelector('.contact-invitation_contacts_list'));
      }
    });
  }

  fetchAttendees() {
    this.isLoadingUsers = true;

    const params = {
      offset: this.users.length,
      maxResults: this.maxResults,
      query: this.searchQuery.trim(),
      withOwner: false,
    };

    const doneCb = (data) => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const contacts = data.participants.map((p) => {
        return EmberObject.create({
          user: p.participant,
        });
      });

      this.users.pushObjects(contacts);
      this.isLoadingUsers = false;

      if (contacts.length >= this.maxResults) {
        this.scrolling.bindScrollDownElement(this.element.querySelector('.contact-invitation_contacts_list'), () =>
          this.fetchAttendees()
        );
      } else {
        this.scrolling.unbindScrollDown(this.element.querySelector('.contact-invitation_contacts_list'));
      }
    };

    const failCb = () => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.isLoadingUsers = false;
    };

    if (params.query) {
      EventsApi.searchParticipants(this.args.eventData.id, 'attending', params).then(doneCb).catch(failCb);
    } else {
      EventsApi.getParticipants(this.args.eventData.id, 'attending', params).then(doneCb).catch(failCb);
    }
  }

  fetchAttendeeSuggestions() {
    this.isLoadingUsers = true;

    EventsApi.getAttendeeSuggestions(this.args.eventData.id, {
      offset: this.users.length,
      maxResults: this.maxResults,
    })
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        const contacts = data.suggestions.map((s) => {
          return EmberObject.create({
            user: s.user,
            invited: s.invited,
            isDisabled: s.invited,
          });
        });

        this.users.pushObjects(contacts);

        if (data.suggestions.length >= this.maxResults) {
          this.scrolling.bindScrollDownElement(this.element.querySelector('.contact-invitation_contacts_list'), () =>
            this.fetchAttendeeSuggestions()
          );
        } else {
          this.scrolling.unbindScrollDown(this.element.querySelector('.contact-invitation_contacts_list'));
        }
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.isLoadingUsers = false;
      });
  }

  cleanSelectedUsers() {
    each(this.invitees, function (u) {
      set(u, 'selected', false);
    });

    this.invitees = A();
  }

  @action
  submitSelectedUsers() {
    this.args.submitSelectedUsers?.(this.invitees);
  }

  @action
  toggleContactSelected(contact, event) {
    if (event.target.nodeName === 'INPUT') {
      event.preventDefault();
      return; // event is doubled when click on input, filter one of them out
    }
    if (contact.isDisabled) {
      return;
    }

    if (contact.selected) {
      this.unSelectContact(contact.user);
    } else {
      this.selectUser(contact.user);
    }
  }

  selectUser(user) {
    if (this.args.isSingleSelect) {
      this.cleanSelectedUsers();
    }

    let invitees = this.invitees,
      isAlreadyAdded = user.inGroup || invitees.find((u) => u.id === user.id);

    if (this.args.selectedUsersLimit) {
      if (invitees.length + this.args.preSelectedUsers?.length === this.args.selectedUsersLimit) {
        ChatUtils.chatLimitExceeded(this.dynamicDialogs);
        return;
      }
    }

    if (!isAlreadyAdded) {
      invitees.pushObject(user);
    }
  }

  @action
  unSelectContact(contact) {
    let invitees = this.invitees;
    let cont = invitees.find((i) => i.id === contact.id);
    invitees.removeObject(cont);
  }

  @action
  clearSearch() {
    this.searchQuery = '';
    this.nextPage = null;
  }
}
