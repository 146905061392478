import { computed } from '@ember/object';
import Component from '@glimmer/component';
import PostApi from 'mewe/api/post-api';
import { inject as service } from '@ember/service';
export default class MwPostbar extends Component {
  @service account;

  api = PostApi;

  @computed('args.noComments', 'args.post.{isGroupPreview,options.noComments}')
  get showCommentsTable() {
    return !this.args.noComments && !this.args.post.options?.noComments && !this.args.post.isGroupPreview;
  }
}
