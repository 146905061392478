import Component from '@glimmer/component';
import { action } from '@ember/object';

import { tracked } from '@glimmer/tracking';
export default class NsfwBlur extends Component {
  imageSize = this.args.imageSize || '150x150';

  @tracked isBlurred = this.args.isBlurred;

  get isFeedSkin() {
    return this.args.skin === 'feed';
  }

  @action
  updateBlur() {
    this.isBlurred = this.args.isBlurred;
  }

  @action
  reveal(event) {
    event.stopPropagation();
    this.isBlurred = false;
    if (this.args.onReveal) {
      this.args.onReveal();
    }
  }
}
