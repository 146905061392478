import EmberObject from '@ember/object';
import { A } from '@ember/array';
import { compact } from 'lodash';
import { Target } from 'mewe/constants';
import ContactsApi from 'mewe/api/contacts-api';
import EventsApi from 'mewe/api/events-api';

import { ObjectPromiseProxy } from 'mewe/utils/ember-utils';

let searchTimeoutId = null;

import { contains } from 'mewe/utils/fp';

export const search = (str, target, selectedItems, administrated, groups) => {
  clearTimeout(searchTimeoutId);

  // TODO load more search

  let promise = new Promise((resolve) => {
    searchTimeoutId = setTimeout(() => {
      switch (target) {
        case Target.PAGE:
          resolve(processTargets(administrated.items, str, selectedItems));

          break;
        case Target.GROUP:
          resolve(processTargets(groups, str, selectedItems));

          break;
        case Target.EVENT:
          EventsApi.getUpcomingEvents('events', { maxResults: 999 }).then((data) => {
            resolve(processEvents(data.events, str, selectedItems));
          });

          break;
        case Target.CONTACTS_FRIENDS:
        case Target.PRIVATEPOST:
          const params = {
            search: str,
            maxResults: 20,
          };

          ContactsApi.search(params).then((data) => {
            resolve(processUsers(data.list, selectedItems));
          });

          break;
        default:
          return A();
      }
    }, 300);
  });

  return ObjectPromiseProxy.create({ promise });
};

export const processEvents = (items = [], searchStr, selectedItems) => {
  return compact(
    items.map((eventData) => {
      if (contains(eventData.event.name, searchStr)) {
        if (selectedItems.find((i) => i.id === eventData.event.id)) {
          eventData.event.isSelected = true;
        }
        return eventData.event;
      }
    })
  );
};

export const processTargets = (items, searchStr, selectedItems) => {
  const results = items.filter((item) => contains(item.name, searchStr));
  results.forEach((r) => {
    if (selectedItems.find((i) => i.id === r.id)) {
      r.isSelected = true;
    }
  });

  return results;
};

export const processContacts = (contacts = []) => {
  let processedContacts = A(),
    shared = A(),
    processUser = (hasUser) =>
      EmberObject.create({
        _links: hasUser.user._links,
        id: hasUser.user.id,
        name: hasUser.user.name,
        firstName: hasUser.user.firstName,
        lastName: hasUser.user.lastName,
        fingerprint: hasUser.user.fingerprint,
        isSelected: !!shared.find((s) => s.id === hasUser.user.id),
        closeFriend: !!hasUser.closeFriend,
        badges: hasUser.user.badges,
        publicLinkId: hasUser.user.publicLinkId,
      });

  contacts.forEach((contact) => {
    if (contact.isContact !== false) {
      processedContacts.push(processUser(contact));
    }
  });

  return processedContacts;
};

export const processUsers = (userData = [], shared) => {
  return userData.map((data) => {
    const user = data.user;
    const foundUser = shared.find((s) => s.id === user.id);
    const shouldSelect = foundUser?.isSelected;

    if (foundUser) {
      user.closeFriend = foundUser.closeFriend;
    }

    user.isSelected = shared && shouldSelect;
    user.isUser = true;

    return EmberObject.create(user);
  });
};
