import Component from '@glimmer/component';

export default class CancelledSubscriptionPromoCode extends Component {
  get subscriptionType() {
    if (this.args.notification?.promoCode?.productId === 'com.mewe.store.premiumpromotion.monthly') {
      return __('monthly premium subscription');
    } else if (this.args.notification?.promoCode?.productId === 'com.mewe.store.premiumpromotion.yearly') {
      return __('yearly premium subscription');
    }
  }
}
