/* eslint-disable lines-between-class-members */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class MwHandle extends Component {
  @service account;
  @service router;
  @service dynamicDialogs;

  @tracked handleError;
  @tracked newUserHandle = this.userHandle;
  @tracked handleHint;
  @tracked isEditing = false;
  @tracked isSaving = false;
  @tracked isDialogOpen = false;

  get registered() {
    return this.args.handle?.registered ?? true;
  }

  @computed('args.handle.{publicLinkId,profileId,contactInviteId}')
  get handle() {
    //TO DO: change all of profileId, contactInviteId, invited_id to publicLinkId;
    return this.args.handle?.publicLinkId || this.args.handle?.profileId || this.args.handle?.contactInviteId;
  }

  @computed('handle', 'account.activeUser.id', 'args.handle.id')
  get isCurrentUser() {
    return !this.handle && this.account.activeUser?.id === this.args.handle?.id;
  }

  @computed('{isCurrentUser,handle}', 'account.activeUser.publicLinkId')
  get userHandle() {
    return this.isCurrentUser ? this.account.activeUser?.publicLinkId : this.handle;
  }

  @computed('args.handle.profileId', 'account.activeUser.profileId', 'isCurrentUser')
  get userProfileLink() {
    return this.isCurrentUser ? this.account.activeUser?.profileId : this.args.handle?.profileId;
  }

  @action
  openProfile(event) {
    event.preventDefault();

    if (this.args.nonClickable) return;

    if (this.args.url && this.args.url() !== false) {
      this.args.url();
      return; //Do not remove details in SG-42258
    } else {
      this.router.transitionTo('app.publicid', this.userProfileLink);
    }

    next(() => this.dynamicDialogs.closeAll());
  }
}
