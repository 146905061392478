/*

 shortname pattern is like this :smile:
 unicodes will be one day deprecated
 other name - colon notation

 emoji json object looks like this:

  {
    "shortname": ":grinning:",
    "unicode": "😀",
    "unicodeAlternates": A(),
    "aliases": A(),
    "aliasesAscii": A(),
    "name": "grinning face",
    "category": "people",
    "keywords": ["happy", "smiley", "emotion", "emotion"],
    "svg": "/emoji/default/grinning.e38f.svg",
    "svgSpriteId": "emoji-grinning",
    "png": {
      "default": "/emoji/default/grinning.b90f.png",
      "sticker": "/emoji/default/grinning_sticker.2ae9.png"
    },
    "pngSpritePosition": {
      "default": [64, 0, 64, 64]
    }
  }

 */
import EmberObject, { observer } from '@ember/object';

import { A } from '@ember/array';
import { isEmpty, each, reduce } from 'lodash';
import { createDeferred } from 'mewe/shared/utils';
import StorageUtils from 'mewe/shared/storage';
import PS from 'mewe/utils/pubsub';
import config from 'mewe/config';
import axios from 'axios';
import configEnv from 'mewe/config/environment';

//https://blog.jonnew.com/posts/poo-dot-length-equals-two
export const isSticker = (shortname, text) => {
  // SG-29892: There's no unicode for :relaxed: emoji, so this is a workaround to display it as a sticker
  const isRelaxedEmojiSticker = shortname === '☺️' && text === '☺️';

  return shortname.length == text.split(/[\ufe00-\ufe0f]/).join('').length || isRelaxedEmojiSticker;
};

export const shortnameRegexp = new RegExp('\\:[\\w\\-\\/][^ \\r\\n:]*:', 'g'); // look for :shortname:

// jshint ignore: start

var rainbow_flag = /(?:\uD83C\uDFF3)\uFE0F?\u200D?(?:\uD83C\uDF08)/u;
var pirate_flag = /(?:\uD83C\uDFF4)\u200D?\u2620\uFE0F?/u;

var black_flag = /(?:\uD83D\uDC41)\uFE0F?\u200D?(?:\uD83D\uDDE8)\uFE0F?/u;
var keycaps = /[#-9]\uFE0F?\u20E3/u;
var flags = /(?:(?:\uD83C\uDFF4)(?:\uDB40[\uDC60-\uDCFF]){1,6})|(?:\uD83C[\uDDE0-\uDDFF]){2}/u;
var gendered_roles_with_objects =
  /(?:(?:\uD83D[\uDC66-\uDC69]|\uD83E[\uDDB5\uDDB6]))\uFE0F?(?:\uD83C[\uDFFA-\uDFFF])?\u200D?(?:[\u2695\u2696\u2708]|\uD83C[\uDF3E-\uDFFF]|\uD83D[\uDCBB\uDCBC\uDD27\uDD2C\uDE80\uDE92]|\uD83E[\uDDB0-\uDDB3])/u;
var unicode_10 =
  /(?:\uD83D[\uDC68\uDC69]|\uD83E[\uDDD0-\uDDDF])(?:\uD83C[\uDFFA-\uDFFF]|\uD83E\uDDB2)?\u200D?[\u2640\u2642\u2695\u2696\u2708]?\uFE0F?/u;
var families =
  /(?:(?:\u2764|\uD83D[\uDC66-\uDC69\uDC8B])[\u200D\uFE0F]{0,2})|(?:\u2764|\uD83D[\uDC66-\uDC69\uDC8B])|(?:(?:\u2764|\uD83D[\uDC66-\uDC69\uDC8B])\uFE0F?)/u;
var modifier_sequence =
  /(?:(?:[\u261D\u26F9\u270A-\u270D]|\uD83C[\uDF85-\uDFCC]|\uD83D[\uDC42-\uDCAA\uDD74-\uDD96\uDE45-\uDE4F\uDEA3-\uDECC]|\uD83E[\uDD18-\uDD3E])\uFE0F?(?:\uD83C[\uDFFA-\uDFFF]))/u;
var gendered_gestures =
  /(?:(?:\uD83D[\uDC68\uDC69\uDC6E\uDC71-\uDC87\uDD75\uDE45-\uDE4E]|\uD83E[\uDD26\uDD37\uDDB8\uDDB9])|(?:\u26F9|\uD83C[\uDFC3-\uDFCC]|\uD83D[\uDC60-\uDC82\uDC86\uDC87\uDEA3-\uDEB6]|\uD83E[\uDD38-\uDD3E])|(?:\uD83D\uDC6F))\uFE0F?(?:\uD83C[\uDFFA-\uDFFF])?\u200D?[\u2640\u2642]?\uFE0F?/u;
var other =
  /(?:[\u2194-\u2199\u21A9\u21AA]\uFE0F?|[#-\*]|[\u3030\u303D]\uFE0F?|(?:(?:\uD83C[\uDD70\uDD71])|(?:\uD83C[\uDD7E\uDD7F])|(?:\uD83C\uDD8E)|(?:\uD83C[\uDD91-\uDD9A])|(?:\uD83C[\uDDE6-\uDDFF]))\uFE0F?|\u24C2\uFE0F?|[\u3297\u3299]\uFE0F?|(?:(?:\uD83C[\uDE01\uDE02])|(?:\uD83C\uDE1A)|(?:\uD83C\uDE2F)|(?:\uD83C[\uDE32-\uDE3A])|(?:\uD83C[\uDE50\uDE51]))\uFE0F?|[\u203C\u2049]\uFE0F?|[\u25AA\u25AB\u25B6\u25C0\u25FB-\u25FE]\uFE0F?|[\xA9\xAE]\uFE0F?|[\u2122\u2139]\uFE0F?|(?:\uD83C\uDC04)\uFE0F?|[\u2B05-\u2B07\u2B1B\u2B1C\u2B50\u2B55]\uFE0F?|[\u231A\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA]\uFE0F?|(?:\uD83C\uDCCF)|[\u2934\u2935]\uFE0F?)/u;
var digits = /[0-9]\uFE0F/u;
var dingbats = /[\u2700-\u27BF]\uFE0F?/u;
//var emoticons = /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/u;
var emoticons = /(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF]|\uD83E[\uDD00-\uDDFF])\uFE0F?/u;
var symbols = /[\u2600-\u26FF]\uFE0F?/u;
var red_heart = /\u2764\uFE0F?/u;
var families_11 =
  '(?:(?:\\u2764|\\uD83D[\\uDC66-\\uDC69\\uDC8B])[\\u200D\\uFE0F]+){1,3}(?:\\u2764|\\uD83D[\\uDC66-\\uDC69\\uDC8B])|(?:(?:\\u2764|\\uD83D[\\uDC66-\\uDC69\\uDC8B])\\uFE0F?){2,4}';
var specials = /\uFFFC/u; // windows native picker leftover
// jshint ignore: end

export const regex =
  shortnameRegexp.source +
  '|' +
  red_heart.source +
  '|' +
  families_11 +
  '|' +
  rainbow_flag.source +
  '|' +
  pirate_flag.source +
  '|' +
  black_flag.source +
  '|' +
  keycaps.source +
  '|' +
  flags.source +
  '|' +
  gendered_roles_with_objects.source +
  '|' +
  unicode_10.source +
  '|' +
  families.source +
  '|' +
  gendered_gestures.source +
  '|' +
  modifier_sequence.source +
  '|' +
  other.source +
  '|' +
  dingbats.source +
  '|' +
  emoticons.source +
  '|' +
  symbols.source +
  '|' +
  digits.source +
  '|' +
  specials.source;

export const serverTo_server = (text) => {
  if (isEmpty(text)) return '';

  return text.replace(shortnameRegexp, function (shortname) {
    let emoji = getHashMap().shortnameDisplay[shortname];
    return emoji && emoji.unicode ? emoji.unicode : shortname;
  });
};

// PRIVATE

let svgSpritePaths = {};
let pngSpritePaths = {};
let searchCache = A();
let skins = {};
let hashMapReady = false;
let pngSpriteSizes = {};

/*
 store references to emoji objects
 speed up finding the right emoji with hash maps
 */
let hashMap = {
  unicode: {}, // all emoji
  shortnameDisplay: {}, // all emoji

  shortnameUse: {}, // only bought emoji
  keyword: {}, // only bought emoji
  category: {
    // only bought emoji
    purchased: A(),
    people: A(),
    nature: A(),
    food: A(),
    activity: A(),
    travel: A(),
    objects: A(),
    symbols: A(),
    custom: A(),
    flags: A(),
  },
};

let addedKeys = {};

let rawEmojis = {};

let stickersHashMap = {};

let stickersDeferred = createDeferred();
let emojisDeferred = createDeferred();

export let getStickersHashMap = () => {
  return stickersHashMap;
};

export let getStickersDeferred = () => {
  return stickersDeferred;
};

export let getEmojisPromise = () => {
  return emojisDeferred;
};

let prepareDisplayHashMaps = (emojis, key) => {
  each(emojis, function (emoji) {
    if (key) emoji.key = key;

    hashMap.unicode[emoji.unicode] = emoji;
    hashMap.shortnameDisplay[emoji.shortname] = emoji;

    each(emoji.aliases, function (alias) {
      hashMap.shortnameDisplay[alias] = emoji;
    });

    if (!pngSpriteSizes[key]) {
      pngSpriteSizes[key] = {
        x: 0,
        y: 0,
      };
    }

    if (emoji.pngSpritePosition.default[0] + emoji.pngSpritePosition.default[2] > pngSpriteSizes[key].x)
      pngSpriteSizes[key].x = emoji.pngSpritePosition.default[0] + emoji.pngSpritePosition.default[2];
    if (emoji.pngSpritePosition.default[1] + emoji.pngSpritePosition.default[3] > pngSpriteSizes[key].y)
      pngSpriteSizes[key].y = emoji.pngSpritePosition.default[1] + emoji.pngSpritePosition.default[3];
  });
};

export let prepareHashMaps = (emojis, key, purchased) => {
  addedKeys[key] = true;

  each(emojis, function (emoji) {
    if (key) emoji.key = key;

    if (emoji.shortname.match(/.*?_tone(.)/g)) {
      emoji.keywords.push('diversity');

      let baseName = emoji.shortname.replace(/_tone./, '');

      if (!skins[baseName]) skins[baseName] = A();
      skins[baseName].push(emoji);
    } else {
      if (hashMap.category[emoji.category]) {
        hashMap.category[emoji.category].push(emoji);
      }

      if (purchased) {
        hashMap.category.purchased.push(emoji);
      }

      each(emoji.keywords, function (keyword) {
        if (!hashMap.keyword[keyword]) hashMap.keyword[keyword] = A();
        hashMap.keyword[keyword].push(emoji);
      });

      let keywordFromName = emoji.shortname;

      if (!hashMap.keyword[keywordFromName]) hashMap.keyword[keywordFromName] = A();
      hashMap.keyword[keywordFromName].push(emoji);
    }

    hashMap.shortnameUse[emoji.shortname] = emoji;

    each(emoji.aliases, function (alias) {
      hashMap.shortnameUse[alias] = emoji;
    });
  });
};

// PUBLIC

export let getEditSVG = (emoji, options = {}) => {
  let svgSpriteId = getEmojiUrlCdn() + emoji.svg;
  let name = emoji.shortname.replace(/:/g, '');
  let sticker = options.isSticker ? ' sticker' : '';
  let size = options.width ? `width="${options.width}" height="${options.height}"` : '';

  return `<img data-name="${name}" src="${svgSpriteId}" class="emoji${sticker}${size ? ' custom-size' : ''}" ${size}/>`;
};

export let getPNGSprite = (emoji, emojiSize) => {
  let emojiUrl = getEmojiUrlCdn() + getPngSpritePath(emoji);
  let name = emoji.shortname.replace(/:/g, '');

  let position =
    emoji.key == 'default' && emoji.spriteChunk ? emoji.spriteChunk.position : emoji.pngSpritePosition.default;

  let sizeX = (getPngSpriteSize(emoji).x / position[2]) * emojiSize;
  let sizeY = (getPngSpriteSize(emoji).y / position[3]) * emojiSize;

  let left = (position[0] / position[2]) * emojiSize;
  let top = (position[1] / position[3]) * emojiSize;

  let css = `background-image: url(${emojiUrl}); background-position: -${left}px -${top}px; background-size: ${sizeX}px ${sizeY}px;`;

  return `<span data-name="${name}" class="emoji" style="${css}" >${emoji.unicode || ''}</span>`;
};

export let getPNG = (emoji, options = {}) => {
  let path = getEmojiUrlCdn() + emoji.png.default;
  let name = emoji.shortname.replace(/:/g, '');

  let sticker = options.isSticker ? ' sticker' : '';
  let size = options.width ? `width="${options.width}" height="${options.height}"` : '';

  return `<img data-name="${name}" src="${path}" class="emoji${sticker}${size ? ' custom-size' : ''}" ${size}/>`;
};

export let getDisplaySVG = (emoji, options = {}) => {
  if (options.isSticker) {
    return getEditSVG(emoji, options);
  } else {
    return getPNG(emoji, options);
  }
};

export let getEmojiNode = (emoji) => {
  let emojiNode = new Image();

  emojiNode.src = getEmojiUrlCdn() + (emoji.png ? emoji.png.default : emoji.svg);
  emojiNode.className = 'emoji';
  emojiNode.setAttribute('data-name', emoji.shortname.replace(/:/g, ''));

  return emojiNode;
};

export let getEmojiUrl = () => {
  if (config.environment == 'local') return '/cdn';
  else return '';
};

export let getEmojiUrlCdn = () => {
  if (config.environment == 'local') {
    return `/cdn`;
  } else return config.cdnEmojiHost || `${window.location.protocol}//${window.location.host}`;
};

export let getSvgSpritePath = (key) => {
  return svgSpritePaths[key];
};
export let getPngSpritePath = (emoji) => {
  if (emoji.key == 'default' && emoji.spriteChunk) return emoji.spriteChunk.path;

  return pngSpritePaths[emoji.key];
};
export let getPngSpriteSize = (emoji) => {
  if (emoji.key == 'default' && emoji.spriteChunk) {
    return emoji.spriteChunk.spriteSize;
  }

  return pngSpriteSizes[emoji.key];
};
export let getHashMap = () => {
  return hashMap;
};

let requests = A();

let stickersRequests = A();

const sortByShortness = (arr) =>
  A(arr).sort((a, b) => {
    if (a.length == b.length) {
      if (a > b) return 1;
      else if (a < b) return -1;
      else return 0;
    } else return a.length - b.length;
  });

export default EmberObject.extend({
  // 0 - default
  selectedSkinTone: 0,

  allEmojiDeferred: null,

  init() {
    this.allEmojiDeferred = createDeferred();

    this.set('selectedSkinTone', parseInt(StorageUtils.get(StorageUtils.keys.selectedSkinTone), 10) || 0);

    if (configEnv.environment == 'test') return;

    axios({
      method: 'GET',
      url: getEmojiUrlCdn() + '/emoji/build-info.json?v=1-0-13',
      headers: { 'content-type': 'application/json; charset=UTF-8' },
    }).then(({ data }) => {
      each(data.packs, (el, key) => {
        let request = axios.get(getEmojiUrlCdn() + data.packs[key]).then((response) => {
          let emojiJson = response.data;

          prepareDisplayHashMaps(emojiJson.emoji, key);

          if (key == 'default') {
            prepareHashMaps(emojiJson.emoji, key, key !== 'default');
          }

          rawEmojis[key] = emojiJson.emoji;

          if (emojiJson.svgSpriteSheet) svgSpritePaths[key] = emojiJson.svgSpriteSheet;
          if (emojiJson.pngSpriteSheet) pngSpritePaths[key] = emojiJson.pngSpriteSheet.default;
        });

        requests.push(request);
      });

      Promise.all(requests).then(() => {
        this.allEmojiDeferred.resolve();
      });

      each(data.stickers, (el, key) => {
        let request = axios.get(getEmojiUrlCdn() + data.stickers[key]).then((response) => {
          stickersHashMap[key] = response.data;
        });

        stickersRequests.push(request);
      });

      Promise.all(stickersRequests).then(() => {
        stickersDeferred.resolve(stickersHashMap);
      });
    });

    PS.Sub('item.purchased', this.wsPurchasedItem.bind(this));
  },

  preparePurchasedHashMaps(purchaseService) {
    Promise.all([purchaseService.purchasedEmojis(), this.allEmojiDeferred.promise]).then((responses) => {
      this.addPurchasedItems(responses[0], true);

      PS.Pub('emoji.hashmap.ready');
      getEmojisPromise().resolve();

      hashMapReady = true;
    });
  },

  // emoji strategy for text completion, can be used and accesed anywhere on site - chat, postbox, comments, new messages...
  // can be extended vie new array as set in param extended
  textCompleteStrategy: function (extended) {
    var basics = {
      id: 'emoji',
      search: function (_term, callback, offset = 0) {
        if (!_term) {
          let emos;

          const maxEmosDisplayed = offset + 10;
          const purchased = hashMap.category.purchased;

          // if there aren't enough purchased, pad to 10 from category.people, otherwise display smileys from start of category.people
          if (purchased && purchased.length > 0) {
            emos = purchased.slice(offset, maxEmosDisplayed);

            if (emos.length < maxEmosDisplayed) {
              emos = emos.concat(hashMap.category.people.slice(offset, maxEmosDisplayed - emos.length));
            }
          } else {
            emos = hashMap.category.people.slice(offset, maxEmosDisplayed);
          }

          return callback(sortByShortness(emos.map((e) => e.shortname)));
        }

        const term = ':' + _term;

        callback(
          sortByShortness(
            reduce(
              hashMap.shortnameUse,
              (res, a) => {
                if (a.shortname.indexOf(term) === 0) res.push(a.shortname);

                return res;
              },
              []
            )
          )
        );
      },
      template: function (value) {
        return getDisplaySVG(hashMap.shortnameUse[value]) + ' ' + value.replace(/:/g, '');
      },
    };

    if (extended) {
      return Object.assign({}, basics, extended);
    } else {
      return basics;
    }
  },

  emoji(name) {
    return hashMap.shortnameDisplay[name] || hashMap.unicode[name];
  },

  emojiByTerm(_term) {
    let term = _term.toLowerCase();

    const skin = this.get('selectedSkinTone'),
      cacheTerm = term + '-skin-' + skin; // cache per skin tone

    if (searchCache[cacheTerm])
      return {
        result: searchCache[cacheTerm],
      };

    let matches = A(),
      foundIds = {};

    if (_term === 'diversity') {
      each(skins, (emojis) => {
        each(emojis, (e) => {
          if (e.shortname.indexOf('_tone' + skin) > 0 && !foundIds[e.shortname]) {
            if (e.duplicate) {
              const dup = hashMap.shortnameDisplay[e.duplicate];

              if (dup && !foundIds[dup.shortname]) {
                foundIds[dup.shortname] = true;
                matches.push(dup);
              }
            } else {
              foundIds[e.shortname] = true;
              matches.push(e);
            }
          }
        });
      });
    } else {
      each(hashMap.keyword, (emojis, key) => {
        if (key.indexOf(term) > -1) {
          each(emojis, (e) => {
            if (!foundIds[e.shortname]) {
              foundIds[e.shortname] = true;

              if (skin > 0) {
                const skinned = skins[e.shortname];

                if (skinned) {
                  e = skinned[skin - 1];
                  if (foundIds[e.shortname]) return;

                  foundIds[e.shortname] = true;
                }
              }

              if (e.duplicate) {
                const dup = hashMap.shortnameDisplay[e.duplicate];

                if (dup && !foundIds[dup.shortname]) {
                  foundIds[dup.shortname] = true;
                  matches.push(dup);
                }
              } else matches.push(e);
            }
          });
        }
      });
    }

    searchCache[cacheTerm] = matches;

    // returning object instead of just an array, way for improvement to add categories (MW)
    return {
      result: matches,
    };
  },

  emojiByCategory() {
    return hashMap.category;
  },

  wsPurchasedItem(item) {
    this.addPurchasedItems([item.itemId]);
  },

  addPurchasedItems(items, isAfterFetching) {
    let wasHashmapChanged = false;

    each(items, (i) => {
      if (typeof i !== 'string') return;

      const key = i.replace('emoji-', '');

      if (addedKeys[key]) {
        return; // already added
      } else {
        const emojis = rawEmojis[key];

        if (emojis) {
          prepareHashMaps(emojis, key, true);
        }

        wasHashmapChanged = true;
      }
    });

    if (!isAfterFetching && wasHashmapChanged) {
      PS.Pub('emoji.hashmap.change');
    }
  },

  saveSkinTone: observer('selectedSkinTone', function () {
    StorageUtils.set(StorageUtils.keys.selectedSkinTone, this.selectedSkinTone);
  }),

  isValidSticker(text) {
    return text.indexOf('class="emoji sticker"') > -1;
  },

  isHashMapReady() {
    return hashMapReady;
  },

  skins: function () {
    return skins;
  },
}).create();

const asciiEmoji = {
  '<3': ':heart:',
  '</3': ':broken_heart:',
  ":')": ':joy:',
  ":'-)": ':joy:',
  ':D': ':smiley:',
  ':-D': ':smiley:',
  '=D': ':smiley:',
  ':)': ':slight_smile:',
  ':-)': ':slight_smile:',
  '=]': ':slight_smile:',
  '=)': ':slight_smile:',
  ':]': ':slight_smile:',
  "':)": ':sweat_smile:',
  "':-)": ':sweat_smile:',
  "'=)": ':sweat_smile:',
  "':D": ':sweat_smile:',
  "':-D": ':sweat_smile:',
  "'=D": ':sweat_smile:',
  '>:)': ':laughing:',
  '>;)': ':laughing:',
  '>:-)': ':laughing:',
  '>=)': ':laughing:',
  ';)': ':wink:',
  ';-)': ':wink:',
  '*-)': ':wink:',
  '*)': ':wink:',
  ';-]': ':wink:',
  ';]': ':wink:',
  ';D': ':wink:',
  ';^)': ':wink:',
  "':(": ':sweat:',
  "':-(": ':sweat:',
  "'=(": ':sweat:',
  ':*': ':kissing_heart:',
  ':-*': ':kissing_heart:',
  '=*': ':kissing_heart:',
  ':^*': ':kissing_heart:',
  '>:P': ':stuck_out_tongue_winking_eye:',
  'X-P': ':stuck_out_tongue_winking_eye:',
  'x-p': ':stuck_out_tongue_winking_eye:',
  '>:[': ':disappointed:',
  ':-(': ':disappointed:',
  ':(': ':disappointed:',
  ':-[': ':disappointed:',
  ':[': ':disappointed:',
  '=(': ':disappointed:',
  '>:(': ':angry:',
  '>:-(': ':angry:',
  ':@': ':angry:',
  ":'(": ':cry:',
  ":'-(": ':cry:',
  ';(': ':cry:',
  ';-(': ':cry:',
  '>.<': ':persevere:',
  'D:': ':fearful:',
  ':$': ':flushed:',
  '=$': ':flushed:',
  '#-)': ':dizzy_face:',
  '#)': ':dizzy_face:',
  '%-)': ':dizzy_face:',
  '%)': ':dizzy_face:',
  'X)': ':dizzy_face:',
  'X-)': ':dizzy_face:',
  '*\\0/*': ':ok_woman:',
  '\\0/': ':ok_woman:',
  '*\\O/*': ':ok_woman:',
  '\\O/': ':ok_woman:',
  'O:-)': ':innocent:',
  '0:-3': ':innocent:',
  '0:3': ':innocent:',
  '0:-)': ':innocent:',
  '0:)': ':innocent:',
  '0;^)': ':innocent:',
  'O:)': ':innocent:',
  'O;-)': ':innocent:',
  'O=)': ':innocent:',
  '0;-)': ':innocent:',
  'O:-3': ':innocent:',
  'O:3': ':innocent:',
  'B-)': ':sunglasses:',
  'B)': ':sunglasses:',
  '8)': ':sunglasses:',
  '8-)': ':sunglasses:',
  'B-D': ':sunglasses:',
  '8-D': ':sunglasses:',
  '-_-': ':expressionless:',
  '-__-': ':expressionless:',
  '-___-': ':expressionless:',
  '>:\\': ':confused:',
  '>:/': ':confused:',
  ':-/': ':confused:',
  ':-.': ':confused:',
  ':/': ':confused:',
  ':\\': ':confused:',
  '=/': ':confused:',
  '=\\': ':confused:',
  ':L': ':confused:',
  '=L': ':confused:',
  ':P': ':stuck_out_tongue:',
  ':-P': ':stuck_out_tongue:',
  '=P': ':stuck_out_tongue:',
  ':-p': ':stuck_out_tongue:',
  ':p': ':stuck_out_tongue:',
  '=p': ':stuck_out_tongue:',
  ':-Þ': ':stuck_out_tongue:',
  ':Þ': ':stuck_out_tongue:',
  ':þ': ':stuck_out_tongue:',
  ':-þ': ':stuck_out_tongue:',
  ':-b': ':stuck_out_tongue:',
  ':b': ':stuck_out_tongue:',
  'd:': ':stuck_out_tongue:',
  ':-O': ':open_mouth:',
  ':O': ':open_mouth:',
  ':-o': ':open_mouth:',
  ':o': ':open_mouth:',
  O_O: ':open_mouth:',
  '>:O': ':open_mouth:',
  ':-X': ':no_mouth:',
  ':X': ':no_mouth:',
  ':-#': ':no_mouth:',
  ':#': ':no_mouth:',
  '=X': ':no_mouth:',
  '=x': ':no_mouth:',
  ':x': ':no_mouth:',
  ':-x': ':no_mouth:',
  '=#': ':no_mouth:',
};

export const regAscii = new RegExp(
  "(\\<3|&lt;3|\\<\\/3|&lt;\\/3|\\:'\\)|\\:'\\-\\)|\\:D|\\:\\-D|\\=D|\\:\\)|\\:\\-\\)|\\=\\]|\\=\\)|\\:\\]|'\\:\\)|'\\:\\-\\)|'\\=\\)|'\\:D|'\\:\\-D|'\\=D|\\>\\:\\)|&gt;\\:\\)|\\>;\\)|&gt;;\\)|\\>\\:\\-\\)|&gt;\\:\\-\\)|\\>\\=\\)|&gt;\\=\\)|;\\)|;\\-\\)|\\*\\-\\)|\\*\\)|;\\-\\]|;\\]|;D|;\\^\\)|'\\:\\(|'\\:\\-\\(|'\\=\\(|\\:\\*|\\:\\-\\*|\\=\\*|\\:\\^\\*|\\>\\:P|&gt;\\:P|X\\-P|x\\-p|\\>\\:\\[|&gt;\\:\\[|\\:\\-\\(|\\:\\(|\\:\\-\\[|\\:\\[|\\=\\(|\\>\\:\\(|&gt;\\:\\(|\\>\\:\\-\\(|&gt;\\:\\-\\(|\\:@|\\:'\\(|\\:'\\-\\(|;\\(|;\\-\\(|\\>\\.\\<|&gt;\\.&lt;|D\\:|\\:\\$|\\=\\$|#\\-\\)|#\\)|%\\-\\)|%\\)|X\\)|X\\-\\)|\\*\\\\0\\/\\*|\\\\0\\/|\\*\\\\O\\/\\*|\\\\O\\/|O\\:\\-\\)|0\\:\\-3|0\\:3|0\\:\\-\\)|0\\:\\)|0;\\^\\)|O\\:\\-\\)|O\\:\\)|O;\\-\\)|O\\=\\)|0;\\-\\)|O\\:\\-3|O\\:3|B\\-\\)|B\\)|8\\)|8\\-\\)|B\\-D|8\\-D|\\-_\\-|\\-__\\-|\\-___\\-|\\>\\:\\\\|&gt;\\:\\\\|\\>\\:\\/|&gt;\\:\\/|\\:\\-\\/|\\:\\-\\.|\\:\\/|\\:\\\\|\\=\\/|\\=\\\\|\\:L|\\=L|\\:P|\\:\\-P|\\=P|\\:\\-p|\\:p|\\=p|\\:\\-Þ|\\:\\-&THORN;|\\:Þ|\\:&THORN;|\\:þ|\\:&thorn;|\\:\\-þ|\\:\\-&thorn;|\\:\\-b|\\:b|d\\:|\\:\\-O|\\:O|\\:\\-o|\\:o|O_O|\\>\\:O|&gt;\\:O|\\:\\-X|\\:X|\\:\\-#|\\:#|\\=X|\\=x|\\:x|\\:\\-x|\\=#)"
);

/**
 * replacement pattern: (beginning of input or empty space) followed by (ascii emoji)
 * followed by (empty space or new line (html representation) or end of input or [!,.,?])
 * $              => end of text
 */
export const asciiRegexp = new RegExp('(\\s|^)' + regAscii.source + '((\\s)|$|[!,.?])', 'g');

/**
 * checkSpaces - params which is used for decision which regexp to use
 * asciiRegexp - is checking for surrouding spaces or white characters - needs to be used when called from parser
 * regAscii - there is raw check for ascii emoji, because checks for spaces is inside autoformat regexp while typing
 */
export const replaceAsciiEmoji = (str, checkSpaces) => {
  const replace = (s) => {
    return s.replace(regAscii, function (txt) {
      return asciiEmoji[txt] ? ' ' + asciiEmoji[txt] + ' ' : txt;
    });
  };

  if (checkSpaces) {
    return str.replace(asciiRegexp, replace);
  } else {
    return replace(str);
  }
};

export const stripModifiers = function (emoji = '') {
  if (emoji === '❤️') return '❤'; // if (&#x2764;&#xFE0F;) return &#x2764; because of emojione
  if (emoji === String.fromCharCode(65532)) return ''; // remove windows native picker separator
  if (emoji.length <= 2) return emoji; // don't remove modifiers, it is needed, it creates emoji - SG-19926
  return emoji.replace(/[\u200B-\u200D\uFEFF\uFE0F]/g, ''); // remove emoji modifiers, they are not needed
};

export const getEmojisWithIndices = (text = '') => {
  let emojis = [];
  text.replace(new RegExp(regex, 'g'), (symbol, i) => {
    if (symbol == '#' && text.substr(i + 1, 1) != ' ' && text.substr(i + 1, 1) != '<') return symbol;

    const symbolFiltered = stripModifiers(symbol);

    const emoji = getHashMap().unicode[symbolFiltered] || getHashMap().shortnameDisplay[symbolFiltered];

    if (emoji)
      emojis.push({
        emoji: emoji,
        indices: [i, i + symbol.length],
      });
  });
  return emojis;
};
