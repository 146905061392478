import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';
import Scrolling from 'mewe/utils/scrolling-utils';

export default class MwPostPollVoters extends Component {
  @service router;

  @reads('args.post') post;
  @reads('args.option') option;

  @tracked voters = A();
  @tracked isLoadingVoters;
  @tracked votesDropdownOpened;

  maxResults = 20;
  scrolling = Scrolling();

  @action
  onVotersInsert(element) {
    this.scrolling.unbindScrollDown(element);
    this.scrolling.bindScrollDownElement(element, () => this.loadVoters(), 500);
  }

  @action
  onVotersDestroy(el) {
    if (el) this.scrolling.unbindScrollDown(el);
  }

  @computed('option')
  get rightTranslateMenu() {
    if (window.innerWidth > 620) {
      return this.option ? '-30px' : '-220px';
    } else {
      return this.option ? '-60px' : '-130px';
    }
  }

  @computed('option.votes', 'post.poll.votesCount')
  get votesCount() {
    return this.option ? this.option.votes : this.post.poll?.votesCount;
  }

  loadVoters(clear) {
    if (this.isDestroyed || this.isDestroying) return;
    if (this.isLoadingVoters) return;

    if (clear) {
      this.voters.clear();
    }

    const params = {
      maxResults: this.maxResults,
      offset: this.voters.length,
    };

    if (this.option) {
      params.option = this.option.index;
    }

    this.isLoadingVoters = true;

    dispatcher.dispatch('poll', 'getVoters', this.post.postItemId, params, (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      if (data.voters.length) {
        this.voters.pushObjects(data.voters);
      } else {
        this.scrolling.unbindScrollDown();
      }

      this.isLoadingVoters = false;
    });
  }

  @action
  openVotesDropdown() {
    if (this.args.isSharedPost) return false;

    // SG-20869 contact request profile page, canEmojify: false indicates this state, private posts can be viewed but can't do actions on them
    if (!this.post.canEmojify) return;

    //only open when there are votes on option or in poll
    if (this.option?.votes || (!this.option && this.post.poll?.votesCount)) {
      this.loadVoters(true);
      this.votesDropdownOpened = true;
    }
  }

  @action
  closeDropdown() {
    this.votesDropdownOpened = false;
  }
}
