import Component from '@glimmer/component';
import { escape } from 'lodash';
import './styles.scss';

import GroupApi from 'mewe/api/group-api';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import PS from 'mewe/utils/pubsub';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class GroupAdminConflictDialog extends Component {
  @service dynamicDialogs;

  @tracked blockedCount = 0;

  constructor() {
    super(...arguments);
    this.loadConflictCount();
  }

  loadConflictCount() {
    GroupApi.getBlockedUsersCount(this.args.groupId, this.args.member.get('id')).then((data) => {
      this.blockedCount = data.blockedCount;
    });
  }

  @action
  close() {
    this.dynamicDialogs.close('group-admin-conflict-dialog');
    this.args.onClose?.();
  }

  @action
  remove() {
    GroupApi.removeBlockedUsers(this.args.groupId, this.args.member.get('id')).then(() => {
      FunctionalUtils.info(
        __('User {userName} is now admin', {
          userName: escape(this.args.member.get('name')),
        })
      );
      this.close();
      PS.Pub('group.members.refresh');
    });
  }
}
