import Model from 'mewe/utils/store-utils/model/model';
import { attr, belongsTo, hasMany } from 'mewe/utils/store-utils/model/attribute';
import SimpleUser from 'mewe/stores/models/simple-user-model';
import Entry from 'mewe/stores/models/entry-model';

const model = Model.extend({
  id: attr('string'),
  tellerType: attr('string'),
  hasNewStories: attr('boolean'),
  details: belongsTo(SimpleUser),
  stories: hasMany(Entry),
  page: attr('object'),
});

model.reopenClass({
  resourceName: 'teller',
  aliases: {
    storytellerId: 'id',
    storytellerDetails: 'details',
    storytellerType: 'tellerType',
    userStoryteller: 'details',
    pageStoryteller: 'page',
  },
});

export default model;
