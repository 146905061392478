/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import FollowingApi from 'mewe/api/following-api';
import dispatcher from 'mewe/dispatcher';
import { maxFavorites } from 'mewe/constants';
import CurrentUserStore from 'mewe/stores/current-user-store';
export default class FollowButton extends Component {
  @service account;

  @tracked showFollowingDropdown;
  @tracked isFavoriteLimitReached;

  constructor() {
    super(...arguments);
    this.loadCounter();
  }

  get user() {
    return this.args.user;
  }

  get isUserFavorite() {
    return this.account.favorites?.includes(this.user.id);
  }

  get showFollowButton() {
    return !this.user.isMe && this.user.publicLinkId; // checking publicLinkId to know if profile was fetched
  }

  get isFollowingMe() {
    return this.user.follower && !this.isFollowRequestSent;
  }

  get isUserFollowedByMe() {
    return this.user.following;
  }

  get isFollowRequestSent() {
    return !isEmpty(this.user.followRequestSent);
  }

  get followOptions() {
    return {
      user: this.user,
      groupId: this.args.group?.id,
      eventId: this.args.event?.id,
    };
  }

  get isNsfwBlurred() {
    if (!this.user) {
      return;
    }
    if (this.isUserFollowedByMe) {
      return false;
    }
    if (this.user.isContact) {
      return false;
    }
    if (this.user.id == this.account.activeUser.id) {
      return false;
    }

    return this.user._links.avatar.nsfw;
  }

  @action
  toggleFollowingDropdown() {
    this.showFollowingDropdown = !this.showFollowingDropdown;
  }

  async loadCounter() {
    let counters = await FollowingApi.counters();
    this.isFavoriteLimitReached = (counters?.favorites || 0) >= maxFavorites;
  }

  @task
  *toggleFavoriteeUser() {
    if (this.isUserFavorite) {
      yield FollowingApi.removeFromFavorite(this.user.id);
      this.args.user.set('favorite', false);
      CurrentUserStore.send('handle', {
        favorites: this.account.favorites.filter((f) => f !== this.user.id),
      });
    } else if (this.isFavoriteLimitReached) {
      this.isUserFavoriteByMe = false;
      this.account.favoriteLimitPopup();
    } else {
      if (this.user.muted) {
        yield FollowingApi.removeMute(this.user.id);
        this.args.user.set('muted', false);
      }
      yield FollowingApi.addToFavorite(this.user.id);
      this.args.user.set('favorite', true);
      CurrentUserStore.send('handle', { favorites: [...this.account.favorites, this.user.id] });
    }
  }

  @task
  *toggleMutedFeed() {
    if (this.user.muted) {
      yield FollowingApi.removeMute(this.user.id);
    } else {
      yield FollowingApi.makeMute(this.user.id);
    }
  }

  @action
  followUser() {
    const onLimitReached = () => this.account.followLimitPopup();
    dispatcher.dispatch('contact', 'followUser', this.followOptions, onLimitReached);
  }

  @action
  unfollowUser() {
    dispatcher.dispatch('contact', 'unfollowUser', this.followOptions);
    this.toggleFollowingDropdown();
  }

  @action
  cancelRequestSent() {
    dispatcher.dispatch('contact', 'cancelRequestSent', this.followOptions);
  }
}
