/* eslint-disable lines-between-class-members */
import Service from '@ember/service';
import { setComponentTemplate } from '@ember/component';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import ScrollingUtils from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';

export default class DynamicDialogsService extends Service {
  @tracked components = A(); // used to store actual dialog components
  @tracked _registered = A(); // used to track opened dialogs and prevent multiple instances

  dialogLoadingClass = 'app-routable--dialog-loader';

  getWormhole() {
    return document.getElementById('mw-wormhole-dialog');
  }

  constructor() {
    super(...arguments);

    PS.Sub('open.generic.dialog', (params) => {
      if (!params?.message && !params.title) return;
      if (!params.okButtonText) params.okButtonText = __('OK');
      this.openDialog('simple-dialog-new', params);
    });
  }

  async openDialog(dialogName, args = {}) {
    // in case of some routes outside the app, wormhole is not created in app.js route
    this.setupDialogWormhole();

    if (!args.allowMultipleInstances) {
      const comp = this._registered.find((el) => el.name === dialogName);
      if (comp) {
        return;
      }
    }

    this.loadingDialog();

    const wormhole = this.getWormhole();

    if (args.useDarkTheme) {
      wormhole.classList.add('app', 'theme-dark');
    } else {
      wormhole.classList.remove('app', 'theme-dark');
    }

    // register dialog even before importing/injecting it to
    // prevent multiple instances when it's needed
    this.register(dialogName);

    const owner = getOwner(this);
    const factory = owner.factoryFor(`component:${dialogName}`);
    if (!factory) {
      let [template, module] = await Promise.all([
        import(`../dialogs/${dialogName}/template.hbs`),
        import(`../dialogs/${dialogName}`),
      ]);
      setComponentTemplate(template.default, module.default);
      owner.register(`component:${dialogName}`, module.default);
    }

    this.components.pushObject({
      componentName: dialogName,
      componentArgs: args,
    });

    this.notLoadingDialog();

    ScrollingUtils().disableWindowScroll();
  }

  close(dialogPath) {
    const dialogName = dialogPath.indexOf('/') !== -1 ? dialogPath.split('/').pop() : dialogPath;
    const comp = this.components
      ?.slice()
      .reverse()
      .find((c) => c.componentName === dialogPath); // find last occurence of this dialog

    if (comp) {
      const wormhole = this.getWormhole();
      const dialogs = document.querySelectorAll(`#c-${dialogName}`);

      if (dialogs.length === 1) {
        wormhole.removeChild(document.getElementById(`c-${dialogName}`));
      } else {
        //In case of many dialogs of the same type, we need to remove the last one
        dialogs[dialogs.length - 1]?.remove();
      }

      this.components.removeObject(comp);
      this.unregister(dialogPath); // dialogPath is passed to `register` method

      ScrollingUtils().enableWindowScroll();
    }
  }

  closeAll() {
    const wormhole = document.querySelector('#mw-wormhole-dialog');
    if (wormhole) {
      while (wormhole.firstChild) {
        wormhole.removeChild(wormhole.firstChild);
      }
    }
    this.components = A();
    this._registered = A();

    ScrollingUtils().enableWindowScroll();

    PS.Pub('close.dropdowns'); // not sure if this is needed here but it was in old dialog-utils
    PS.Pub('close.smart.search'); // not sure if this is needed here but it was in old dialog-utils
  }

  setupDialogWormhole() {
    if (!this.getWormhole()) {
      const dialogWormhole = document.createElement('div');
      dialogWormhole.id = 'mw-wormhole-dialog';
      document.body.append(dialogWormhole);
    }
  }

  register(name) {
    const found = this._registered.find((el) => el.name === name);
    if (!found) this._registered.pushObject({ name });
  }

  unregister(name) {
    const found = this._registered.find((el) => el.name === name);
    if (found) this._registered.removeObject(found);
  }

  loadingDialog() {
    const viewEl = document.querySelector('body > .c-app-route');
    if (viewEl?.classList && !viewEl.classList.contains(this.dialogLoadingClass)) {
      viewEl.classList.add(this.dialogLoadingClass);
    }
  }

  notLoadingDialog() {
    const viewEl = document.querySelector('body > .c-app-route');
    if (viewEl?.classList?.contains(this.dialogLoadingClass)) {
      viewEl.classList.remove(this.dialogLoadingClass);
    }
  }
}
