import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'MwAuthor',
  component: 'MwAuthor',
  excludeStories: /.*Data$/,
};

const Template = (args) => ({
  template: hbs`<BasicUi::MwAuthor
  @post={{this.post}}
  @owner={{this.owner}}
/>`,
  context: args,
});

export const NotFound = Template.bind({});

NotFound.args = {
  owner: {
    id: '6064cdbe60e3b24b2bfa49e3',
    name: 'Yuliya Chyrva',
    _links: {
      avatar: {
        href: '/api/v2/photo/profile/{imageSize}/6064cdbe60e3b24b2bfa49e3?group=&f=F460778204541ITIC2',
        templated: true,
      },
    },
    fingerprint: 'F460778204541ITIC2',
    firstName: 'Yuliya',
    lastName: 'Chyrva',
    contactInviteId: 'yuliya',
    badges: {
      verified: true,
    },
  },
  post: {
    postItemId: '62c5159ec77ab31c4348c1a2',
    userId: '6064cdbe60e3b24b2bfa49e3',
    textServer: '<blink>Hello there</blink>',
    emojis: {
      _isReady: true,
      userEmojis: [],
      emojiCounts: [
        {
          ':cat_loudly-crying-face:': 1,
        },
        {
          ':cat_frowning-face:': 1,
        },
        {
          ':poop_face-with-stuck-out-tongue-and-winking-eye:': 1,
        },
      ],
    },
    groupId: '606580e860e3b24b2bfa4c8d',
    permissions: {
      comment: true,
      remove: false,
      canEmojify: true,
      reshare: true,
      follow: true,
    },
    createdAt: '1657083294',
    updatedAt: '1657083294',
    follows: false,
    placeholder: false,
    discoverScope: '',
    group: {
      __data: {
        id: '606580e860e3b24b2bfa4c8d',
        isFetching: false,
        isConfirmed: true,
        ownerId: '6064cdbe60e3b24b2bfa49e3',
        adminIds: ['60b23acea083ac1a4a2db8e2'],
        name: 'SELECTIVE GROUP AUTO',
        isPublic: true,
        descriptionPlain: '',
        membersCount: 229,
        publicRole: 'Contributor',
        publicUrlId: 'selectivegroupauto',
        isPublicApply: true,
        publicUrl: 'https://master.ci.k8s.sgr-labs.com/join/selectivegroupauto',
        discoveryOptIn: true,
        permissions: ['post', 'comment', 'invite'],
        groupThematicType: 'Sports Teams',
        role: 'Contributor',
        applyQuestions: [],
        mandatoryQuestions: false,
        rolesPermissions: [
          {
            name: 'Contributor',
            permissions: ['post', 'comment', 'invite'],
          },
          {
            name: 'Limited',
            permissions: ['post', 'comment', 'invite', 'req_moderation'],
          },
          {
            name: 'Viewer',
            permissions: ['comment', 'invite'],
          },
        ],
        isMuted: false,
        newPosts: 40,
        chatMode: 'on',
        hiddenChat: false,
        _links: {
          self: {
            href: '/api/v2/group/606580e860e3b24b2bfa4c8d',
          },
          groupAvatar: {
            href: '/api/v2/group/606580e860e3b24b2bfa4c8d/public-image/60f1351cb36f9611b937e09a/{imageSize}/img',
            templated: true,
          },
          publicPage: {
            href: 'https://master.ci.k8s.sgr-labs.com/join/selectivegroupauto',
          },
          userAvatar: {
            href: '/api/v2/photo/profile/{imageSize}/6065c9d7366d0820c8e649be?group=606580e860e3b24b2bfa4c8d',
            templated: true,
          },
        },
        canReshare: true,
        hideAnswers: true,
        groupMuted: false,
        lastVisited: 1657101337327,
      },
      _isReady: true,
      isDirty: true,
      publicPermissions: ['post', 'comment', 'invite'],
      showInPublicDirectory: true,
      emojisReady: true,
    },
    id: '62c5159ec77ab31c4348c1a2',
    owner: {
      id: '6064cdbe60e3b24b2bfa49e3',
      name: 'Yuliya Chyrva',
      _links: {
        avatar: {
          href: '/api/v2/photo/profile/{imageSize}/6064cdbe60e3b24b2bfa49e3?group=&f=F460778204541ITIC2',
          templated: true,
        },
      },
      fingerprint: 'F460778204541ITIC2',
      firstName: 'Yuliya',
      lastName: 'Chyrva',
      contactInviteId: 'yuliya',
      badges: {
        verified: true,
      },
    },
    refRemoved: false,
    closeFriends: false,
    files: [],
    public: false,
    sharesCount: 0,
    canEmojify: true,
    comments: {
      nextPage: null,
      canBeMoreBefore: false,
      feed: [],
      canBeMoreAfter: false,
      newCommentsCount: 0,
    },
  },
};
