import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import Storage from 'mewe/shared/storage';
import Verbose from 'mewe/utils/verbose';
import config from 'mewe/config';

const verbose = Verbose({ prefix: '[Signup/config]', color: 'orange', enabled: true }).log;

export default class MwHomeRegistrationPageComponent extends Component {
  @service router;
  @service analytics;

  @tracked authMode = null; // "mewe" / "dsnp_amplica"
  @tracked configLoaded = false;

  constructor() {
    super(...arguments);

    PublicPagesApi.getRegistrationInfo()
      .then((res) => {
        this.setAuthMode(res.defaultRegistrationMode);
      })
      .catch(() => {
        this.setAuthMode('mewe'); // fallback to mewe just in case
      })
      .finally(() => {
        this.configLoaded = true;
        verbose(`authMode=${this.authMode}`);
      });
  }

  setAuthMode(valueFromBe) {
    // registration mode can be forced by passing hash "authMode" in url
    if (~window.location.hash.indexOf('authMode=')) {
      const mode = window.location.hash.slice('authMode='.length + 1);
      if (mode) {
        return (this.authMode = mode);
      }
    }

    if (config.enviroment !== 'prod') {
      const authMode = Storage.get('authMode');
      if (authMode) {
        return (this.authMode = authMode);
      }
    }

    // only if there is no value from parent and no forced mode from url, then use BE value
    return (this.authMode = valueFromBe || 'mewe'); // fallback to mewe just in case
  }
}
