import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { isMobile } from 'mewe/shared/utils';

import { dateStringLongMonth, dateStringLongMonthDayYear } from 'mewe/utils/datetime-utils';
import dispatcher from 'mewe/dispatcher';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import FollowingApi from 'mewe/api/following-api';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';

export default class ProfileMain extends Component {
  @service chat;
  @service tasks;
  @service account;
  @service router;
  @service dynamicDialogs;

  @tracked showSettingsDropdown;
  @tracked showOptionsDropdown;
  @tracked dropdownMenuOpenedAvatar;
  @tracked task;
  @tracked dialogTab;
  @tracked counters;

  get contact() {
    return this.args.contact;
  }

  get badges() {
    return this.args.contact.badges;
  }

  get isPrivate() {
    return this.contact.public === false;
  }

  isMobile = isMobile();

  photoDialogTitles = {
    choosePhoto: __('Choose Profile Photo'),
    cropPhoto: __('Edit Profile Photo'),
  };

  constructor() {
    super(...arguments);
    this.task = this.tasks.getTask('photo.avatar.contacts');
    this.selectPhotoBind = this.selectPhoto.bind(this);
  }

  get memberSinceText() {
    if (this.contact.registeredAt) {
      return __('Member since {date}', {
        date: dateStringLongMonth(this.contact.registeredAt, this.account.activeUser.locale),
      });
    } else {
      return '';
    }
  }

  @computed('counters.requestsReceived')
  get requestsReceivedOverOneHundred() {
    if (this.counters?.requestsReceived >= 100) {
      return true;
    } else {
      return false;
    }
  }

  @computed('account.activeUser.locale', 'contact.registeredAt')
  get memberSinceTitleText() {
    if (this.contact.registeredAt) {
      return __('Joined {date}', {
        date: dateStringLongMonthDayYear(this.contact.registeredAt, this.account.activeUser.locale),
      });
    } else {
      return '';
    }
  }

  @computed(
    'account.activeUser.id',
    'contact._links.avatar.nsfw',
    'contact.{id,isContact},isUserFollowedByMe',
    'args.uuid'
  )
  get isNsfwBlurred() {
    if (!this.contact) {
      return;
    }
    if (this.isUserFollowedByMe) {
      return false;
    }
    if (this.contact.isContact) {
      return false;
    }
    if (this.contact.id == this.account.activeUser.id) {
      return false;
    }

    return this.contact._links.avatar.nsfw;
  }

  @computed('account.activeUser.id', 'contact.id', 'args.uuid')
  get isMyProfile() {
    return this.contact && this.contact.id === this.account.activeUser.id;
  }

  @computed('args.entries.items.length')
  get hasEntries() {
    return this.args.entries?.items?.length > 0;
  }

  @computed('args.entries.items.length')
  get hasNewEntries() {
    return this.args.entries?.items?.filterBy('isNew', true).length > 0;
  }

  get canShowInfo() {
    return this.contact.isMe || this.contact.public || this.contact.following;
  }

  get canShowOptions() {
    return !this.contact.isMe;
  }

  get canShowSettings() {
    return this.contact.isMe;
  }

  get canChat() {
    return this.contact.canChat && !this.contact.isMe;
  }

  get isFollowingMe() {
    return this.contact.follower && !this.isFollowRequestSent;
  }

  get isUserFollowedByMe() {
    return this.contact.following;
  }

  get isFollowRequestSent() {
    return !isEmpty(this.contact.followRequestSent);
  }

  get canSeeFollowers() {
    return (this.contact.following && !this.contact.hideFollowers) || this.contact.public || this.isMyProfile;
  }

  openJournalsDialog() {
    const params = {
      tellerId: this.contact.id,
      tellerName: this.contact.name,
      tellerType: 'User', // for now only User type exist
      avatarHref: this.contact._links.avatar.href,
      context: 'profile_feed',
    };

    this.dynamicDialogs.openDialog('journals-dialog', params);
  }

  @action
  removeFollower() {
    dispatcher.dispatch('contact', 'removeFollower', this.contact);
    this.showOptionsDropdown = false;
  }

  @action
  shareProfile() {
    MiscellaneousUtils.copyToClipboard(`${EnvironmentUtils.getShareHost()}/${this.contact.publicLinkId}`);
    this.showOptionsDropdown = false;
  }

  @action
  inviteUserToGroup() {
    dispatcher.dispatch('contact', 'addUserToGroup', this.contact);
  }

  @action
  blockUser() {
    dispatcher.dispatch('contact', 'blockUser', this.contact, () => (location.href = '/'));
  }

  @action
  openFollowingDialog(tab) {
    if (!this.args.isPublicProfilePage) {
      if (this.canSeeFollowers) {
        this.dialogTab = tab;
        this.openFollowerDialog();
      }
    } else {
      showNewToMeWePopup(this.dynamicDialogs);
    }
  }

  @action
  displayopenFollowerDialogPopup() {
    if (this.args.isPublicProfilePage === undefined || !this.args.isPublicProfilePage) {
      this.openFollowerDialog();
    } else {
      showNewToMeWePopup(this.dynamicDialogs);
    }
  }

  @action
  showNewToMeWePopup() {
    this.args.isPublicProfilePage && showNewToMeWePopup(this.dynamicDialogs);
  }

  @action
  reportUser() {
    dispatcher.dispatch('contact', 'reportUser', this.contact);
  }

  @action
  openProfilePhoto() {
    if (this.hasEntries) {
      this.openJournalsDialog();
    } else {
      let photoUrl = this.contact._links.avatar.href;

      this.dynamicDialogs.openDialog('media-dialog', {
        photoUrl: photoUrl,
        mediaType: 'photoUrl',
        allowMultipleInstances: true,
      });
    }
  }

  selectPhoto(blob, params) {
    dispatcher.dispatch('contact', 'setAvatar', blob, params, this.task);
  }

  @action
  opendEditDropdownAvatar() {
    if (this.hasNewEntries) {
      this.openJournalsDialog();
    } else {
      this.dropdownMenuOpenedAvatar = true;
    }
  }

  @action
  closeEditDropdownAvatar() {
    this.dropdownMenuOpenedAvatar = false;
  }

  @action
  openChat() {
    this.chat.openThreadByParticipants([this.contact], { doNotClose: true });
  }

  @action
  showInfo() {
    this.showAboutDialog();
  }

  @action
  showOptions(value) {
    this.showOptionsDropdown = typeof value === 'boolean' ? !value : !this.showOptionsDropdown;
  }

  @action
  showSettings() {
    if (this.isMyProfile) {
      this.router.transitionTo('app.settings.privacy');
      return;
    }
  }

  @action
  showAboutDialog() {
    this.dynamicDialogs.openDialog('about-dialog', {
      uuid: this.args.uuid,
      memberSinceText: this.memberSinceText,
    });
  }

  @action
  openFollowerDialog() {
    this.dynamicDialogs.openDialog('followers-dialog', {
      uuid: this.args.uuid,
      currentTab: this.dialogTab,
      title: this.contact.name,
      isPublicProfile: this.contact.public,
    });
  }

  @action
  onInsert() {
    if (this.args.isPublicProfilePage) return;
    FollowingApi.counters().then((response) => {
      this.counters = response;
    });
  }
}
