// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { set } from '@ember/object';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import dispatcher from 'mewe/dispatcher';
import { sortMembersOptions } from 'mewe/utils/options';

export default class AppGroupMembers extends Component {
  @service router;

  maxResults = 30;

  sortMembersOptions = sortMembersOptions();

  @computed('args.model.group.id')
  get downloadUrl() {
    return EnvironmentUtils.getShareHost() + '/api/v2/group/' + this.args.model.group.id + '/answers';
  }

  @computed('args.model.membersSearch', 'router.currentRouteName')
  get showSorting() {
    const currentRoute = this.router.currentRouteName;

    return (
      this.args.model.membersSearch ||
      ~currentRoute.indexOf('app.group.members.all') ||
      ~currentRoute.indexOf('app.group.members.invited')
    );
  }

  @action
  invite() {
    dispatcher.dispatch('group-member', 'invite', this.args.model.group);
  }

  @action
  sortMembers(sort) {
    set(this, 'args.model.membersSearchSort', sort.order);
  }

  @action
  clearSearch() {
    set(this, 'args.model.membersSearch', null);
  }
}
