import fuHelper from 'mewe/utils/fileupload-utils';
import { loadImage } from 'mewe/utils/fileupload-utils';

class baseController {
  confirmExit() {
    return __("You haven't finished your post. Do you really want to leave without finishing?");
  }

  clearRetries() {
    let timeouts = this.retryTimeouts;
    this.retries = {};

    for (var key in timeouts) {
      if (key) clearTimeout(timeouts[key]);
    }

    this.retryTimeouts = {};
  }

  cleanAfter() {
    this.abortPendingUploads();
    this.clearRetries();
    this.setInitialAttributes();
  }

  previewSubmitData(originalFile) {
    const params = {
      maxWidth: 140,
      maxHeight: 140,
    };

    loadImage(originalFile, params)
      .then((canvas) => {
        this.renderPhoto(canvas.toDataURL(), originalFile);
      })
      .catch((error) => {
        if (error) {
          fuHelper.wrongFormatMsg();
        }
        return false;
      });
  }

  renderPhoto(blob, file) {
    const photo = {
      blob: blob,
      orientation: 'normal',
      tempId: file.uploadId,
      url: blob,
      isUrl: true,
      loading: true,
      failedToLoad: false,
    };

    this.afterRenderPhoto(photo);
  }

  afterRenderPhoto() {
    //overwrite this if needed
  }
}

export default baseController;
