import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

import { setTheme } from 'mewe/utils/miscellaneous-utils';

export default class MwHomeLayout extends Component {
  @tracked contentLoaded = false;
  @tracked contentVisible = false;

  promisesLoaded = true;
  animationTime = 800;
  fadeInTime = 300;

  constructor() {
    super(...arguments);

    if (this.args.promisesToWaitFor) {
      this.promisesLoaded = false;

      Promise.all(this.args.promisesToWaitFor).then(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.promisesLoaded = true;

        if (this.themeLoaded && this.timeoutFinished) {
          this.showContent();
        }
      });
    }

    setTheme('dark', false).then(() => {
      if (this.isDestroyed || this.isDestroying) return;

      this.themeLoaded = true;

      if (this.timeoutFinished && this.promisesLoaded) {
        this.showContent();
      }
    });

    // first page load is longer for better UX, following loads can be faster
    // because they happen on route transitions so it might be better to transition faster
    let animationTime = this.animationTime;
    if (window.initialLoadDone) {
      animationTime = this.animationTime / 2;
    }

    // delayed change for nicely looking loading animation,
    // sometimes loading is too short (like 0.2s) and it's not pleasant blink
    later(
      this,
      () => {
        if (this.isDestroyed || this.isDestroying) return;

        this.timeoutFinished = true;

        if (this.themeLoaded && this.promisesLoaded) {
          this.showContent();
        }
      },
      animationTime
    );
  }

  showContent() {
    this.contentVisible = true;

    // get rid of loader from DOM after fade-in is finished
    later(
      this,
      () => {
        if (this.isDestroyed || this.isDestroying) return;
        this.contentLoaded = true;
        window.initialLoadDone = true;
      },
      this.fadeInTime
    );
  }
}
