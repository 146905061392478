import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ShareToMeweLoggedOut extends Component {
  @service router;

  @action
  goToLogin() {
    this.router.transitionTo('login');
  }

  @action
  goToSignup() {
    this.router.transitionTo('register');
  }
}
