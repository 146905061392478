import { includes } from 'lodash';
import './styles.scss';
import { photoMaxWeightToResizeInBrowser } from 'mewe/constants';
import { sortMediaOptions } from 'mewe/utils/options';
import fuHelper from 'mewe/utils/fileupload-utils';
import { loadImage } from 'mewe/utils/fileupload-utils';
import { blobToFile } from 'mewe/utils/fileupload-utils';
import Session from 'mewe/shared/session';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ImageUpload from 'mewe/utils/image-upload';
import { errorDialog } from 'mewe/utils/fileupload-utils';
import { photoMaxWeightToUploadToServer, prettyWeight as weight } from 'mewe/constants';

import './styles.scss';
// these orientations swap places of width and height
// 3 = 180° rotate left
// 5 = vertical flip + 90 rotate right
// 6 = 90° rotate right
// 7 = horizontal flip + 90 rotate right
// 8 = 90° rotate left

const getSizeObj = (w, h) => ({ canvasWidth: w, canvasHeight: h });
const canvasSize = (orientation, width, height) =>
  getSizeObj(includes([3, 5, 6, 7, 8], orientation) ? (height, width) : (width, height));

export default class ChoosePhoto extends Component {
  @service dynamicDialogs;

  /**
   * @private
   */
  @tracked imageBase64 = null;

  @tracked selectedTab = 0;

  @tracked albumView = false;

  @tracked albumName;

  @tracked sortMediaOptions = sortMediaOptions();

  @tracked openCropDialog;

  @tracked imgUrl;

  @tracked cropContext;

  @tracked canvasSize;

  @tracked photoId;

  @tracked order;

  @tracked albumsInitialized;

  @tracked hidden;

  @tracked showChoosePhoto;

  constructor() {
    super(...arguments);
    this.hidden = this.args.instantUpload;
    this.showChoosePhoto = !this.args.instantUpload;
  }

  // TODO rewrite to task uploader as it is done in simple-upload component
  @action
  uploadPhoto(blob, cropParams) {
    // reupload cropped photo and pass through reqular selectPhoto queue
    const formData = new FormData();
    const photoFile = blobToFile(blob, cropParams.id);

    formData.append('files', photoFile, cropParams.id);

    fetch(this.url || '/api/v2/photo/uc', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': Session.getCsrfToken(),
      },
      body: formData,
    }).then(async (response) => {
      const body = await response.json();
      this.args.selectPhoto(blob, {
        id: body.id,
      });
      this.args.onClose?.();
    });
  }

  @action
  close() {
    this.args.onClose?.();
  }

  @action
  async setCropImage(image, file) {
    if (!file) {
      return;
    }

    if (file.size >= photoMaxWeightToUploadToServer) {
      return errorDialog(
        this,
        __('Photo should not exceed {limit}', { limit: weight(photoMaxWeightToUploadToServer) }),
        __('Photo upload error')
      );
    }
    this.imageBase64 = image;
    let imageObject = await ImageUpload.uploadImage(blobToFile(image, file.name));
    this.updateLocalPictureComponents(imageObject);
    this.openCropDialog = false;
    this.args.onClose?.();
    this.dynamicDialogs.close('choose-photo');
  }

  @action
  cropImage(file) {
    return new Promise((resolve, reject) => {
      if (!fuHelper.isImageFileTypeSupported(file.type)) {
        file.error = 'Unsupported file type';
        reject(file);
      }

      if (file.size > photoMaxWeightToResizeInBrowser) {
        if (this.args.instantUpload) {
          this.close();
        }
        reject(file);
      }

      // getting rotated and resized canvas, default max width and height is 1200
      loadImage(file)
        .then((canvas) => {
          this.imageBase64 = canvas.toDataURL();
          this.hidden = true;
          this.cropContext = 'crop-image';
          this.openCropPhotoDialog(canvas);
        })
        .catch((error) => {
          if (error) {
            fuHelper.wrongFormatMsg();
          }

          reject(file);
        });
    });
  }

  @action
  openCropPhotoDialog(canvas) {
    this.imgUrl = canvas.toDataURL();
    this.canvasSize = canvasSize({
      width: canvas.height,
      height: canvas.width,
    });
    this.openCropDialog = true;
  }

  @action
  updateLocalPictureComponents(data) {
    this.args.selectPhoto(this.imageBase64, { id: data.id });
  }

  @action
  selectProfilePhoto(loader, post) {
    const hasPhotoField = !!post.get('photo._links');
    const imgUrl = hasPhotoField
      ? post.get('photo._links.img.href').replace('{imageSize}', 'full')
      : post.get('image._links.img.href').replace('{imageSize}', 'full');

    this.hidden = true;
    this.imgUrl = imgUrl;
    this.photoId = hasPhotoField ? post.get('photo.id') : post.get('image.id');
    this.openCropDialog = true;
  }

  @action
  show() {
    this.hidden = false;
  }

  @action
  orderMedia(option) {
    this.order = option.order;
  }

  @action
  switchTab(tab) {
    this.selectedTab = tab;
    this.albumName = '';
    this.albumView = false;

    if (tab === 1) {
      this.albumsInitialized = true;
    }
  }

  @action
  openAlbumStream(album) {
    this.albumName = album.name;
    this.albumView = true;
  }

  @action
  resetTabs() {
    this.switchTab(1);
  }

  @action
  onCropClose(closeAll = false) {
    if (this.args.instantUpload && !closeAll) {
      this.showChoosePhoto = true;
    }
    this.openCropDialog = false;
  }
}
