import EnvironmentUtils from 'mewe/utils/environment-utils';
import Hls from 'hls.js/dist/hls.light.js';
import { sortBy, compact } from 'lodash';
import { default as meweConfig } from 'mewe/config';
import { get } from '@ember/object';

//commercial keys in order: mewe.com
var embeddedPlayer = null;

/* embeddedPlayer is a singleton object representing the currently played native video
 * embeddedPlayer.id should be unique among players/iframes on the page
 * embeddedPlayer.pause should be a function that implements the actual iframe video pausing
 */
export const setupEmbeddedVideoPlayer = (videoEl) => {
  const playerId = videoEl.getAttribute('id');

  videoEl.playerId = playerId;

  videoEl.addEventListener('play', setupEmbeddedPlayer);
  videoEl.addEventListener('pause', unsetEmbeddedPlayer);
  videoEl.addEventListener('ended', unsetEmbeddedPlayer);
};

export const teardownEmbeddedVideoPlayer = (videoEl) => {
  videoEl.removeEventListener('play', setupEmbeddedPlayer);
  videoEl.removeEventListener('pause', unsetEmbeddedPlayer);
  videoEl.removeEventListener('ended', unsetEmbeddedPlayer);
};

const setupEmbeddedPlayer = (e) => {
  const playerId = e.target.playerId;
  const newPlayer = {
    id: playerId,
    pause: () => {
      try {
        const playPromise = e.target?.play();

        if (playPromise !== undefined) {
          playPromise.then(() => {
            e.target.pause();
          });
        }
      } catch (err) {
        unsetEmbeddedPlayer(e);
      }
    },
  };

  if (embeddedPlayer?.id !== newPlayer?.id) {
    if (embeddedPlayer) {
      const playPromise = embeddedPlayer.play?.();

      if (playPromise !== undefined) {
        playPromise.then(() => {
          embeddedPlayer.pause();
        });
      }
    }
    embeddedPlayer = newPlayer;
  }
};

// should be called when the video gets paused/stopped
const unsetEmbeddedPlayer = (e) => {
  const playerId = e.target.playerId;

  if (embeddedPlayer && embeddedPlayer.id === playerId) {
    embeddedPlayer = null;
  }
};

export function getVideoSourcesFromPost(post) {
  const mediaHost = EnvironmentUtils.getMediaHost(true);
  const template = get(post, 'video._links.linkTemplate.href');
  const getVideoType = (res) => 'video/' + (res.indexOf('hls') === -1 ? 'mp4' : 'hls');

  let availableResolutions;

  if (template) {
    availableResolutions = get(post, 'video.availableResolutions') || ['original'];

    // make sure original is last, it doesn't work well on Safari
    availableResolutions = sortBy(availableResolutions, function (res) {
      return res === 'original' ? 1 : 0;
    });

    return availableResolutions.map((res) => {
      return {
        type: getVideoType(res),
        src: mediaHost + template.replace('{resolution}', res),
      };
    });
  } else {
    availableResolutions = ['res720pMp4', 'res480pMp4', 'original', 'hls2m']; // hls has issues with playback, so original tried first

    return compact(
      availableResolutions.map((res) => {
        const link = get(post, `_links.${res}.href`) || get(post, `video._links.${res}.href`);

        return (
          link && {
            type: getVideoType(res),
            src: mediaHost + link,
          }
        );
      })
    );
  }
}

export function hlsPlaylistLoader(config) {
  config.xhrSetup = function (xhr, url) {
    if (~url.indexOf('v2/proxy/video')) {
      xhr.withCredentials = true;
    }
  };

  Hls.DefaultConfig.loader.apply(this, arguments);
  return this;
}

hlsPlaylistLoader.prototype = Object.create(Hls.DefaultConfig.loader.prototype);

hlsPlaylistLoader.prototype.load = function load(context, config, callbacks) {
  var onSuccess = callbacks.onSuccess;
  if (context.type == 'manifest') {
    callbacks.onSuccess = function (response, stats, context) {
      let data;

      try {
        data = JSON.parse(response.data);
      } catch (e) {
        callbacks.onError(`Failed to parse data`);
        return Promise.reject(Error(`[Video Utils] Failed to parse data`, { cause: e }));
      }

      if (!data) {
        callbacks.onError(`Failed to load data`);
        return Promise.reject(Error(`[Video Utils] Failed to load data`));
      }

      let source = `${data.playlistUrl}?${data.params}`;

      // if is dev
      if (meweConfig.environment == 'local') {
        source = source.replace('https://media.master.ci.k8s.sgr-labs.com', '/media');
      }

      fetch(source)
        .then((res) => {
          if (res.ok) {
            return res.text();
          } else {
            callbacks.onError(`Failed to fetch playlist: ${res.status}`);
            return Promise.reject();
          }
        })
        .then((body) => {
          // append CORS params to each level or fragment
          body = body.replace(/\.ts$/gm, `.ts?${data.params}`);
          body = body.replace(/\.m3u8$/gm, `.m3u8?${data.params}`);
          context.url = source || data.playlistUrl;
          onSuccess({ data: body }, stats, context);
        });
    };
  }

  Hls.DefaultConfig.loader.prototype.load.apply(this, arguments);
};

export function hlsFragmentLoader() {
  Hls.DefaultConfig.loader.apply(this, arguments);
  return this;
}

hlsFragmentLoader.prototype = Object.create(Hls.DefaultConfig.loader.prototype);

hlsFragmentLoader.prototype.load = function load(context, config, callbacks) {
  const params = context.frag.baseurl.split('?')[1];

  // add params if were not added previously
  if (params && !~context.url.indexOf('?')) {
    context.frag.relurl += '?' + params;
    context.frag._url += '?' + params;
    context.url += '?' + params;
  }

  Hls.DefaultConfig.loader.prototype.load.apply(this, [context, config, callbacks]);
};
