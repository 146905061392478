/* eslint-disable ember/no-get */
/* eslint-disable ember/classic-decorator-no-classic-methods */
import ApiUtil from './api';

class Api extends ApiUtil {
  counters() {
    return this.get('/following/counters');
  }

  toInvite(params) {
    return this.getNextPageOr('/following/followers/to-invite', params);
  }

  followers(params, id) {
    let url = id ? `/following/${id}/followers` : '/following/followers';
    return this.getNextPageOr(url, params);
  }

  followersFavorite(params, id) {
    let url = id ? `/following/${id}/followers/favorite` : '/following/followers/favorite';
    return this.getNextPageOr(url, params);
  }

  favoriteCandidates(params, id) {
    let url = id ? `/following/${id}/favorite/candidates` : '/following/favorite/candidates';
    return this.getNextPageOr(url, params);
  }

  following(params, id) {
    let url = id ? `/following/${id}/followed` : '/following/followed';
    return this.getNextPageOr(url, params);
  }

  followUser(userId) {
    return this.post(`/following/${userId}/follow`);
  }

  followingFavorite(params, id) {
    let url = id ? `/following/${id}/followed/favorite` : '/following/followed/favorite';
    return this.getNextPageOr(url, params);
  }

  blocked(params) {
    return this.getNextPageOr('/following/blocked', params);
  }

  requests(params) {
    return this.getNextPageOr('/following/requests', params);
  }

  cancelRequestSent(requestId) {
    return this.del(`/following/request/${requestId}/remove`);
  }

  acceptRequestSent(requestId) {
    return this.post(`/following/request/${requestId}/accept`);
  }

  removeFollower(userId) {
    return this.del(`/following/${userId}/follow/remove`);
  }

  requestsSent(params) {
    return this.getNextPageOr('/following/requests/sent', params);
  }

  requestsReceived(params) {
    return this.getNextPageOr('/following/requests/received', params);
  }

  acceptRequest(reqId) {
    return this.post(`/following/request/${reqId}/accept`);
  }

  declineRequest(reqId) {
    return this.del(`/following/request/${reqId}/remove`);
  }

  doFollow(id) {
    return this.post(`/following/${id}/follow`);
  }

  unFollow(id) {
    return this.del(`/following/${id}/follow`);
  }

  addToFavorite(id) {
    return this.put(`/api/v2/following/${id}/favorite`);
  }

  removeFromFavorite(id) {
    return this.del(`/api/v2/following/${id}/favorite`);
  }

  makeMute(id) {
    return this.put(`/api/v2/following/${id}/mute`);
  }

  removeMute(id) {
    return this.del(`/api/v2/following/${id}/mute`);
  }

  user(id) {
    return this.get(`/following/${id}`);
  }

  getFavorites() {
    return this.get('/api/v2/following/followed/favorite/ids');
  }
}

export default new Api();
