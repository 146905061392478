import createDisplayEditServerModel from 'mewe/utils/store-utils/model/display-edit-server-model.js';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import Status from './status-model';
import Group from './group-model';

var model = createDisplayEditServerModel([
  'text',
  'currentCity',
  'job',
  'company',
  'college',
  'highSchool',
  'relationshipStatus',
  'interests',
  'email',
  'phone',
]).extend({
  id: attr('string'),
  name: attr('string', { defaultValue: '' }),
  fingerprint: attr('string', { defaultValue: '' }),
  active: attr('boolean'),
  locale: attr('string'),

  status: belongsTo(Status, {
    defaultValue: () => Status.create(),
  }),

  favoritePublicGroup: belongsTo(Group, {
    defaultValue: () => Group.create(),
  }),
});

model.reopenClass({
  resourceName: 'profile',
});

export default model;
