import Route from 'mewe/routes/route';
import { inject as service } from '@ember/service';
import ContactsApi from 'mewe/api/contacts-api';
import ProfileStore from 'mewe/stores/profile-store';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { fetchFeedProfileGroup, fetchFeedProfileEvent, getGroupProfileFeed } from 'mewe/fetchers/fetch-feed';

export let ProfileRouteBase = Route.extend({
  queryParams: {
    tag: {
      refreshModel: true,
    },
  },

  dynamicDialogs: service(),

  activate: function () {
    this._super();

    this.dynamicDialogs.closeAll();
    window.scrollTo(0, 0);
  },

  model: function (params) {
    const groupId = this.paramsFor('app.group').group_id;
    const eventId = this.paramsFor('app.event').event_id;

    CurrentUserStore.getState().deferred.promise.then(function () {
      ContactsApi.getUserDetails(params.uuid, groupId, eventId)
        .then((data) => {
          ProfileStore.send('handleRawData', data, groupId, eventId);
        })
        .catch((err) => {
          if (err)
            ProfileStore.send('failToFetchData', {
              uuid: params.uuid,
              groupId: groupId,
              eventId: eventId,
              error: err.data,
            });
        });

      if (groupId) {
        fetchFeedProfileGroup(params.uuid, groupId, { hashtag: params.tag });
      } else {
        fetchFeedProfileEvent(params.uuid, eventId, { hashtag: params.tag });
      }
    });

    return {
      contact: ProfileStore.getState({ uuid: params.uuid, groupId: groupId, eventId: eventId }),
      feed: getGroupProfileFeed(params.uuid, groupId ? groupId : eventId),
      groupId: groupId,
      eventId: eventId,
      group: groupId ? this.modelFor('app.group').group : null,
      eventState: eventId ? this.modelFor('app.event').eventState : null,
      theme: this.get('theme'),
      tag: params.tag,
    };
  },

  actions: {
    willTransition() {
      if (this.controller.get('tag')) {
        this.controller.set('tag', null);
        // prevent loading feed for tag on back navigation
        history.replaceState('', document.title, window.location.pathname);
      }
    },
  },
});

export default ProfileRouteBase;
