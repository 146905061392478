import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import FeedApi from 'mewe/api/feed-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import MarkdownParser from 'mewe/stores/text-parsers/markdown-parser';
import Storage from 'mewe/shared/storage';
import * as LottiePlayer from '@lottiefiles/lottie-player';

export default class MwCampaignPost extends Component {
  @service analytics;

  @tracked selectedOption;
  @tracked voteInProgress;
  @tracked showVoteSuccess;

  get textDisplay() {
    return MarkdownParser.toDisplay(this.args.campaignPost?.text);
  }

  @action
  dismiss() {
    // commented out for UI testing phase, to avoid dimissing post completely
    FeedApi.dismissCampaignPost(this.args.campaignPost.campaignId)
      .then(() => {
        this.args.removeCampaignPost?.();
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      });
  }

  @action
  selectPollOption(option) {
    this.selectedOption = option;
  }

  @action
  submitPoll() {
    if (this.voteInProgress || this.showVoteSuccess) {
      return;
    }

    this.voteInProgress = true;

    FeedApi.voteCampaignPost(this.args.campaignPost.campaignId, {
      option: this.selectedOption,
    })
      .then(() => {
        this.showVoteSuccess = true;
        this.voteInProgress = false;

        later(() => {
          if (this.isDestroyed || this.isDestroying) return;
          this.args.removeCampaignPost?.(); // don't call dismiss API, voting dismisses post automatically on BE side
        }, 2000);
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
        this.voteInProgress = false;
      });
  }

  @action
  imageClick() {
    if (this.args.campaignPost.image?.imageLink) {
      window.open(this.args.campaignPost.image.imageLink, '_blank');
    }

    this.linkClick('image_link');
  }

  @action
  linkClick(cta) {
    let clickedCampaigns = Storage.get(Storage.keys.clickedCampaigns);
    clickedCampaigns = clickedCampaigns ? JSON.parse(clickedCampaigns) : [];

    const isAlreadyClicked = clickedCampaigns.includes(this.args.campaignPost.campaignId);

    if (!isAlreadyClicked) {
      clickedCampaigns.push(this.args.campaignPost.campaignId);
      Storage.set(Storage.keys.clickedCampaigns, JSON.stringify(clickedCampaigns));

      this.analytics.sendEvent('campaignSucceeded', {
        campaign_id: this.args.campaignPost.campaignId,
        campaign_name: this.args.campaignPost.campaignName,
        campaign_type: 'generic_post',
        cta: cta,
      });
    }
  }
}
