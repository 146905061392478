import GroupUtils from 'mewe/utils/group-utils-light';
import GroupApi from 'mewe/api/group-api';

import { maxResultsMembersList } from 'mewe/constants';
import MemberBase from '../app-group/member-base';
import { next } from '@ember/runloop';

//TODO: Need to refactor the code
export default class AppGroupMembersAll extends MemberBase {
  loadMembers(loadMore) {
    if (this.loading) {
      return;
    }
    this.scrolling.unbindScrollDown();
    const membersLen = this.members.length || 0,
      params = {
        offset: this.offset,
        maxResults: maxResultsMembersList,
        sort: this.args.model.membersSearchSort,
        byJoin: true,
        // role: {admin|user} - new arguments added on BE, might be useful one day
      };
    next(() => {
      this.loading = true;
    });

    GroupApi.members(this.group.id, params).then((data) => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      if (this.isSearching) {
        return;
      }

      if (!loadMore) {
        this.members.clear();
      }
      // add current user which is not included in response but only while first fetching
      if (!membersLen) {
        data.members.unshift(GroupUtils.getCurrentUserGroupMember(this.group));
      }
      this.members.pushObjects(this.deserializeMembers(data.members));
      this.offset = this.offset + maxResultsMembersList;

      if (data.members.length) {
        this.scrolling.bindScrollDown(() => this.loadMembers(true));
      }

      this.loading = false;
    });
  }
}
