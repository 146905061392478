import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { openPostDetails } from 'mewe/utils/open-post-details';
import { action } from '@ember/object';

// Single Post Dialog should be opened when clicked a link to post from MeWe
export default class PostOpenerModifier extends Modifier {
  modify(element) {
    this.element = element;
    this.onClickAction = this.onClick.bind(this);

    this.removeListener();
    this.addListener();

    registerDestructor(this, this.removeListener.bind(this));
  }

  addListener() {
    this.element.querySelectorAll('a')?.forEach((el) => {
      el.addEventListener('click', this.onClickAction);
    });
  }

  removeListener() {
    this.element.querySelectorAll('a')?.forEach((el) => {
      el.removeEventListener('click', this.onClickAction);
    });
  }

  @action
  onClick(e) {
    const targetEl = e && e.target;

    if (!targetEl) return;
    if (targetEl.nodeName !== 'A') return;

    const href = targetEl.href,
      mewePostClicked = openPostDetails(href ? href : targetEl);

    if (mewePostClicked) {
      e.preventDefault();
      return false;
    }
  }
}
