import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { Theme } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import PS from 'mewe/utils/pubsub';

export default class MwChatSearchResult extends Component {
  @service chat;

  @action
  onClick() {
    // if user is selecting some text in element (want to copy it) then prevent default click behaviour
    const sel = getSelection().toString();
    if (!sel) this.openItem();
  }

  @action
  openItem() {
    const model = this.args.model;

    if (model.chatThread) {
      this.chat.transitionToThreadByIdOrOpenParticipants(model.chatThread.id);
    } else if (model.chatMessage) {
      this.chat.transitionToThreadByIdOrOpenParticipants(model.chatMessage.threadId, model.chatMessage.id);
    } else if (model.comment) {
      dispatcher.dispatch('feed', 'openPostDetails', {
        postItemId: model.comment.postItemId,
        scope: Theme.PRIVATEPOSTS,
        threadId: model.comment.threadId,
      });
    } else if (model.post) {
      dispatcher.dispatch('feed', 'openPostDetails', {
        postItemId: model.post.postItemId,
        scope: Theme.PRIVATEPOSTS,
        threadId: model.post.threadId,
      });
    }

    PS.Pub('click.sidebar.search.result');
  }
}
