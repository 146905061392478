import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class MwPhotoPickerDropdown extends Component {
  @tracked openChoosePhoto;

  @tracked instantUpload;

  openPhotoPickerDialog(instantUpload) {
    this.openChoosePhoto = true;
    this.instantUpload = instantUpload;
  }

  @action
  addFile() {
    this.openPhotoPickerDialog(true);
  }

  @action
  openCloudPicker() {
    this.openPhotoPickerDialog(false);
  }

  @action
  openOptionsDropdown() {
    if (!this.args.preventDefault && this.args.setDropdownMenuOpened) {
      this.args.setDropdownMenuOpened(true);
    }
  }

  @action
  closeDropdownMenu() {
    this.args.setDropdownMenuOpened(false);
  }
}
