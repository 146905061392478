import { escape, each, compact } from 'lodash';
import { next, later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

import ChatStore from 'mewe/stores/chat-store';
import PS from 'mewe/utils/pubsub';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import InvitationsUtils from 'mewe/utils/invitations-utils';
import ChatUtils from 'mewe/utils/chat-utils';
import CurrentUserStore from 'mewe/stores/current-user-store';
import GroupStore from 'mewe/stores/group-store';
import ChatApi from 'mewe/api/chat-api';
import dispatcher from 'mewe/dispatcher';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import fetchThreads from 'mewe/fetchers/fetch-threads';
import PostApi from 'mewe/api/post-api';
import storage from 'mewe/shared/storage';
import { createDeferred } from 'mewe/shared/utils';
import { Theme, ChatEventTypes, highlightTime, ChatFilter } from 'mewe/constants';
import { getPrivatePostFeed, handleOtherStores } from 'mewe/fetchers/fetch-feed';
import { A } from '@ember/array';
import { get, set } from '@ember/object';
let { isDefined } = MiscellaneousUtils;

export default Service.extend({
  routing: service('-routing'),
  dynamicDialogs: service(),
  account: service(),

  init: function () {
    this.set('participantsOpeningFor', A());
    this._super(...arguments);

    window.addEventListener('resize', this.calculateMaxAvailableChats.bind(this));

    this.calculateMaxAvailableChats();

    PS.Sub('contact.removed', this.removeThreadWithContact.bind(this));
    PS.Sub('post.remove', this.removePmFromChat.bind(this));
    PS.Sub('user.blocked', this.userBlockedHandler.bind(this));
    PS.Sub('group.member.remove', this.removeUserFromChat.bind(this));

    // one common subscribe for all types of chat messages: team chat, group chat, event chat and normal user chat
    PS.Sub('chat.message.add', (data) => {
      if (!data) return;

      let threadId = data.threadId;

      if (!threadId) {
        if (data.group && data.group.id) {
          threadId = data.group.id;
        } else if (data.event && data.event.id) {
          threadId = data.event.id;
        }

        data.threadId = threadId;
      }

      if (data.eventType) {
        const doneCb = () => PS.Pub('chat.participants.changed', data.threadId);

        if (data.eventType === ChatEventTypes.USERJOINED) {
          if (this.isThreadOpened(data.threadId)) {
            dispatcher.dispatch('chat', 'getChatThread', { threadId: data.threadId, doneCb: doneCb });
          }
        } else if (data.eventType === ChatEventTypes.USERLEFT) {
          if (data.participation && data.participation.removed) {
            if (data.participation.removed.id === CurrentUserStore.getState().id) {
              ChatStore.send('removeThreadById', data.threadId);
            } else {
              if (this.isThreadOpened(data.threadId)) {
                dispatcher.dispatch('chat', 'getChatThread', { threadId: data.threadId, doneCb: doneCb });
              }
            }
          }
        } else if (data.eventType && data.eventType === ChatEventTypes.CHATRENAMED) {
          let actionThread = ChatStore.getThreadById(data.threadId);
          if (actionThread) actionThread.set('name', data.text);
        }
      }

      const isPostOwner = this.account.activeUser.id === data.authorId;
      if (data.privacyMailId && data.postItemId) {
        PostApi.getPostDetails({
          scope: Theme.PRIVATEPOSTS,
          postItemId: data.postItemId,
          threadId: data.threadId,
        }).then((responsePost) => {
          handleOtherStores(responsePost);

          responsePost.post.threadId = data.threadId;
          data.privatePost = responsePost.post;

          this.addMessageToThread(threadId, data, {});

          dispatcher.dispatch('feed', 'addPost', responsePost.post);
        });
      } else {
        const options = {
          doNotOpenSmallChatIfIAmTheAuthor: true,
          noFocusAfterOpen: true,
        };

        this.addMessageToThread(threadId, data, options);
      }

      const hasPhotoAtt = data.attachments && data.attachments.find((a) => a.aType === 'photo');
      if (hasPhotoAtt) {
        PS.Pub('photoStream.update', { post: data, scope: threadId, type: 'add' });
      }
    });

    PS.Sub('chat.typing', (data) => {
      this.setIsTyping(data);
    });

    PS.Sub('groupchat.typing', (data) => {
      this.setIsTyping(data);
    });

    PS.Sub('groupchat.mode.changed', (data) => {
      this.groupChatModeChanged(data);
    });

    PS.Sub('chat.message.del', (data) => {
      this.deleteOrMarkMessageAsDeleted(data.disappearing, data.threadId, data.id, data.authorId);
    });

    PS.Sub('groupchat.message.remove', (data) => {
      this.deleteOrMarkMessageAsDeleted(data.disappearing, data.groupId, data.messageId, data.authorId);
    });

    PS.Sub('eventchat.message.remove', (data) => {
      this.deleteOrMarkMessageAsDeleted(data.disappearing, data.eventId, data.messageId, data.authorId);
    });

    PS.Sub('chat.remove.all.messages', (data) => {
      // threadId only - clean group chat history
      //threadId and userId - user left group (also separate WS are sent for every message so usecase not processed here)
      if (data.threadId) {
        let thread = ChatStore.getState().threads.find((t) => t.id === data.threadId);

        if (thread) {
          if (data.userId) {
            ChatStore.send('deleteAllUserChatMessages', thread, data.userId);
          } else {
            ChatStore.send('deleteAllChatMessages', thread);
          }
        }
      }
    });

    PS.Sub('call.started', (data) => {
      if (data.userId === CurrentUserStore.getState().id) return;

      const callback = (thread) => {
        let caller = thread.getUserChatParticipant;

        if (caller && caller[0]) {
          let iconUrl =
            EnvironmentUtils.getImgHost(true) +
            '/api/v2/photo/profile/150x150/' +
            caller[0].id +
            '?f=' +
            caller[0].fingerprint;
          dispatcher.dispatch('notification', 'notify', {
            title: __('Incoming call'),
            body: __('{userName} is calling you', { userName: escape(caller[0].name) }),
            icon: iconUrl,
            tag: 'call-' + thread.id,
            sticky: true,
            onClick: () => {
              //dummy function to trigger focus action from notify
            },
          });
        }

        this.dynamicDialogs.openDialog('incoming-call-dialog', {
          thread: thread,
          video: data.video,
        });
      };

      let thread = ChatStore.getThreadById(data.threadId);

      if (thread) {
        callback(thread);
      } else {
        ChatApi.getChatThread(data.threadId)
          .then((data) => {
            ChatStore.send('handleThread', data, { addTemporarilyToStore: true });

            const thread = ChatStore.getThreadById(data.id);
            callback(thread);
          })
          .catch(() => {
            console.error('Incoming call error - thread not found');
          });
      }
    });

    PS.Sub('chat.message.emoji.add', (data) => {
      this.messageEmojiAdd(data);
    });

    PS.Sub('chat.message.emoji.remove', (data) => {
      this.messageEmojiRemove(data);
    });

    PS.Sub('chat.message.edit', (data) => {
      this.editChatMessage(data);
    });

    PS.Sub('chat.link.updated', (data) => {
      this.updateChatLink(data);
    });
  },

  isThreadOpened(threadId) {
    const chatState = ChatStore.getState();
    return chatState.openedThreads.find((t) => t.id === threadId) || chatState.get('selectedThread.id') === threadId;
  },

  removeThreadWithContact(options) {
    let thread = ChatStore.getState().threads.find((t) => {
      if (t.get('observableOthers.length') === 1 && options) {
        return t.get('observableOthers')[0].id === options.userId;
      }
    });

    if (thread) dispatcher.dispatch('chat', 'removeThread', thread);
  },

  userBlockedHandler() {
    fetchThreads({ selectLastThread: true });
  },

  removeUserFromChat(data = {}) {
    if (!data.groupId || !data.userId) return;

    const thread = ChatStore.getState().threads.find((t) => t.id === data.groupId);

    if (thread?.messages?.length) {
      // Find a message from the removed user
      const userMessage = thread.messages.find((m) => m.authorId === data.userId);
      // If the user has messages, remove them from the thread
      if (userMessage) {
        thread.messages = thread.messages.filter((m) => m.authorId !== data.userId);
      }
    }
  },

  removePmFromChat(post) {
    if (!post.threadId || !post.postItemId) return;

    // remove from chat media feed
    let feed = getPrivatePostFeed(post.threadId).posts;
    if (feed && feed.length) {
      let postInFeed = feed.find((f) => f.id === post.postItemId);
      if (postInFeed) feed.removeObject(postInFeed);
    }

    // for user chat threads posts are also mixed with messages
    let userChat = ChatStore.getState().userChats.find((c) => c.id === post.threadId);
    if (userChat && userChat.messages) {
      let msgWithPm = userChat.messages.find((m) => m.get('privatePost.postItemId') === post.postItemId);
      if (msgWithPm) {
        msgWithPm.setProperties({
          deleted: true,
          privatePost: null,
        });
      }
    }
  },

  setIsTyping: function (data) {
    let user;
    let thread = ChatStore.getState().threads.find(function (thread) {
      return thread.id == data.threadId;
    });

    if (thread && (thread.open || thread.selected)) {
      user = data.user;
      if (typeof user === 'undefined' || user === null) {
        user = data.author;
      }

      ChatStore.send('isTyping', thread, user);
    }
  },

  groupChatModeChanged: function (data = {}) {
    if (data.mode === 'on') {
      let groupFromStore = GroupStore.getGroupIfMember(data.groupId);
      if (groupFromStore) {
        groupFromStore.set('chatMode', 'on');
      }
      return;
    }

    let thread = ChatStore.getState().threads.find((thread) => {
      return thread.id == data.groupId;
    });

    if (thread) {
      thread.set('closed', true);
    }
  },

  editChatMessage({ id, threadId, date, editedAt, text }) {
    if (!threadId) return;

    const thread = ChatStore.getThreadById(threadId);

    if (!thread) return;

    let foundMsg = thread.messages.find((m) => m.id === id);

    if (foundMsg) {
      foundMsg.setProperties({
        editedAt: editedAt,
        date: date,
        text: text,
      });
    }

    let lastMsg = thread.lastMessage;

    if (lastMsg.id === id) {
      lastMsg.set('editedAt', editedAt); // needs to be here, otherwise ember will throw an error if editedAt has not been set (and is thus a ComputerProperty)
      lastMsg.setProperties({
        date: date,
        text: text,
      });
    }
  },

  updateChatLink({ threadId, msgIds, photoUrl, size }) {
    if (!threadId || !msgIds) return;

    const thread = ChatStore.getThreadById(threadId);

    if (!thread) return;

    let foundMsg = thread.messages.find((m) => m.id === msgIds[0]);

    // we don't update link thumbnail in case of multiple links in one message
    if (foundMsg && foundMsg.get('links.length') === 1) {
      let link = foundMsg.get('links')[0];

      link._links.thumbnail = { href: photoUrl };
      link.thumbnailSize = size;

      // links are not based on model and also notifyPropertyChange doesn't work
      // this is solution to make ember notice the change
      foundMsg.set('links', A());
      next(() => foundMsg.set('links', A([link])));
    }
  },

  addMessageToThread: function (threadId, message, optionsArg = {}) {
    message.isWs = true; // comes from WS
    message.cameFromWs = true;

    let chatState = ChatStore.getState(),
      isChatPage = chatState.isChatPage,
      thread = chatState.threads.find((t) => t.id === threadId),
      currentUserId = CurrentUserStore.getState().id;

    if (message.author) {
      message.authorId = message.author.id || message.author.userId; // TODO - <--- it's a computed prop
      message.originalAuthor = Object.assign({}, message.author);
    }

    let openedThreads = chatState.get('openedThreads.length');
    let expandedOpenedThreads = chatState.openedThreads.filter((c) => c.expandedChatSize === true).length;
    let computedOpenedThreads = openedThreads + expandedOpenedThreads;

    let blockOpenThread = false;
    if (computedOpenedThreads >= chatState.maxAvailableChats) {
      blockOpenThread = true;
      //don't know how but it's somehow focused
      if (thread) thread.set('chatFocused', false);
    }

    let shouldSmallChatBeOpened = ChatUtils.canOpenSmallChat(
      CurrentUserStore,
      message.authorId,
      isChatPage,
      optionsArg?.doNotOpenSmallChatIfIAmTheAuthor,
      message
    );
    shouldSmallChatBeOpened = shouldSmallChatBeOpened && !message.isRequest && !blockOpenThread;
    let authorId = get(message, 'authorId') || get(message, 'author.userId');

    if (message.isRequest) {
      if (authorId !== currentUserId) {
        ChatStore.send('updateChatRequestsFromWs', message);
      }

      ChatStore.send('moveReceivedChatRequestToThreads', threadId, authorId);
    }

    if (thread) {
      const options = {
        // jshint ignore:start
        ...optionsArg,
        // jshint ignore:end
        doNotChangeThread: true, // <----- it has a meaning only in Chat window, not to tackle the thread
        blockOpenThread: blockOpenThread,
      };

      ChatStore.send('addMessage', thread, message, null, options);

      if (currentUserId !== authorId) {
        // if typing indicator was active --> deactivate it
        const typingUsers = thread.typingUsers;
        if (typeof typingUsers !== 'undefined' && typingUsers !== null && typingUsers.length === 1) {
          thread.set('typingUsers', A());
        }
      }

      if (!thread.messagesInitied && !thread.messagesBeingFetched) {
        thread.set('messagesBeingFetched', true);
        let cb = () => thread.set('messagesBeingFetched', false);

        dispatcher.dispatch('chat', 'getChatThread', {
          threadId: thread.id,
          noFocusAfterOpen: optionsArg.noFocusAfterOpen,
          offset: 0,
          doneCb: cb,
          failCb: cb,
          open: !isChatPage && shouldSmallChatBeOpened,
        });
      }
    } else {
      // user left chat and thread was removed from chats list, don't create it again because of this incoming message about leaving chat
      if (message.eventType && message.eventType === ChatEventTypes.USERLEFT) return;

      const shouldOpenWhileCreatingANewThread = !isChatPage && shouldSmallChatBeOpened;
      // note - if AppStore.getState().isChat is opened, we do not want to toggle the thread

      //this.get('routing').transitionTo('app.chat.thread', [threadId]);

      this.createThreadById(threadId, {
        open: shouldOpenWhileCreatingANewThread,
        doneCb: false,
        failCb: () => {
          this.findThreadById(threadId).then((thread) => {
            if (thread) {
              ChatStore.send('doNotification', {
                thread: thread,
                shouldSmallChatBeOpened: shouldOpenWhileCreatingANewThread,
                newThreadLoaded: true,
              });
            }
          });
        },
      });
    }
  },

  deleteOrMarkMessageAsDeleted(isDisappearing, threadGroupOrEventId, messageId, authorId) {
    if (isDisappearing) {
      dispatcher.dispatch('chat', 'deleteMessage', threadGroupOrEventId, messageId);
    } else if (!authorId || authorId !== CurrentUserStore.getState().id) {
      ChatStore.send('markAsDeleted', threadGroupOrEventId, messageId);
    }
  },

  selectThreadById(threadId, messageId) {
    ChatStore.getState().set('threadSelectionInProgress', true);

    const alwaysCb = () => {
      ChatStore.getState().set('threadSelectionInProgress', false);
    };

    this.openThreadById(threadId, {
      doneCb: alwaysCb,
      failCb: alwaysCb,
      aroundMessageId: messageId,
    });
  },

  openThreadById(threadId, options = {}) {
    this.findThreadById(threadId).then((thread) => {
      if (thread) {
        if (!options.aroundMessageId) {
          thread.setProperties({
            scrollTo: null,
            expandedChatSize: options.expandedChatSize,
          });
        }

        ChatStore.restoreNewMsgFromLS(thread);

        dispatcher.dispatch('chat', 'openThread', {
          thread: thread,
          aroundId: options.aroundMessageId,
          expandedChatSize: options.expandedChatSize,
          setAsRead: true,
          doneCb: options.doneCb,
          failCb: options.failCb,
        });
      } else {
        this.createThreadById(threadId, {
          open: true,
          addTemporarilyToStore: options.addTemporarilyToStore,
          aroundMessageId: options.aroundMessageId,
          expandedChatSize: options.expandedChatSize,
          doneCb: options.doneCb,
          failCb: options.failCb,
        });
      }
    });
  },

  openThreadByParticipants(users, options = {}) {
    let thread = this.findThreadByParticipants(users);
    if (thread && !thread.closed && thread.id) {
      if (options.isNewChat) {
        dispatcher.dispatch('chat', 'closeSmallChat', thread);
        dispatcher.dispatch('chat', 'getChatThread', {
          threadId: thread.id,
          isNewChat: true,
          expandedChatSize: options.expandedChatSize,
          open: true,
        });
      } else {
        if (ChatStore.getState().isChatPage) {
          this.routing.transitionTo('app.chat.thread', [thread.id]);
        } else {
          dispatcher.dispatch('chat', 'openThread', {
            // jshint ignore: start
            ...options,
            thread: thread,
            setAsRead: true,
            // jshint ignore: end
          });
        }
      }
    } else {
      let receivers = users
          .map((u) => u.id)
          .sort()
          .join(','),
        participantsOpeningFor = this.participantsOpeningFor;

      if (!participantsOpeningFor.includes(receivers)) {
        participantsOpeningFor.pushObject(receivers);

        ChatApi.getThreads({
          receivers: users.map((u) => u.userId || u.id),
          maxResults: 50,
        }).then((data) => {
          thread = data.threads && data.threads.find((t) => !t.closed);

          if (thread) {
            if (ChatStore.getState().isChatPage) {
              if (options.isNewChat) {
                thread.isNewChat = true;
                ChatStore.send('handleThread', thread, options);
              } else {
                this.routing.transitionTo('app.chat.thread', [thread.id]);
              }
            } else {
              dispatcher.dispatch('chat', 'getChatThread', {
                // jshint ignore: start
                threadId: thread.id,
                open: true,
                ...options,
                // jshint ignore: end
              });
            }
          } else {
            if (ChatStore.getState().isChatPage && users[0] && (options.isSuggestion || options.isChatOptions)) {
              this.routing.transitionTo('app.chat.create-thread', [], { userId: get(users[0], 'id') });
            } else if (this.isChatPageCreationMode) {
              this.routing.transitionTo('app.chat.create-thread', [], { userId: null });
            } else {
              ChatStore.send('createThreadForParticipants', users, options);
            }
          }

          participantsOpeningFor.removeObject(receivers);
        });
      }
    }
  },

  createThreadById(threadId, options = {}) {
    dispatcher.dispatch('chat', 'getChatThread', {
      setAsRead: false,
      threadId: threadId,
      aroundId: options.aroundMessageId,
      addTemporarilyToStore: options.addTemporarilyToStore,
      expandedChatSize: options.expandedChatSize,
      doneCb: options.doneCb,
      failCb: options.failCb,
      open: options.open,
    });
  },

  findThreadById(threadId, aroundMessageId) {
    let defer = createDeferred();

    ChatStore.fetchingThreadsDeffered.promise.then(() => {
      let found = ChatStore.getState()
        .get('threads')
        .find((t) => t.id === threadId);

      if (found) {
        if (aroundMessageId) {
          if (!found.get('messages').find((m) => m.id === aroundMessageId)) found = null;
        }
      }

      defer.resolve(found);
    });

    return defer.promise;
  },

  findThreadByParticipants(participants = []) {
    let currentUserId = CurrentUserStore.getState().get('id'),
      participantsIds = compact(
        participants.map((p) => {
          let id = p.userId || p.id;
          if (id !== currentUserId) {
            return id;
          }
        })
      )
        .sort()
        .join(',');

    return ChatStore.getState().threads.find((thread) => {
      if (thread.chatType != 'UserChat') {
        return false;
      }
      let usersIds = thread.observableOthers
        .map((u) => u.id)
        .sort()
        .join(',');

      return usersIds == participantsIds;
    });
  },

  calculateMaxAvailableChats() {
    const chatSpace = 300;
    ChatStore.send('maxAvailableChats', Math.floor(window.innerWidth / chatSpace));
  },

  // triggered for chat-requests
  blockUser(thread) {
    const threadId = thread.id,
      participant = thread.getUserChatParticipant,
      params = {
        userId: participant.id,
        userName: participant.name,
        callback: () => {
          ChatApi.removeThread(threadId).then(() => {
            FunctionalUtils.info(__('User blocked'));
            this.deleteChatCallback({ thread: thread });
          });
        },
      };

    InvitationsUtils.showBlockUserConfirm(this.dynamicDialogs, params);
  },

  // chat request; common code for chatWindow & mw-chat
  doFollow(participant, inviteCallbackBind) {
    dispatcher.dispatch('contact', 'doFollow', participant, inviteCallbackBind);
  },

  hideChatConfirm(options) {
    if (!options || !options.thread) return;

    const thread = options.thread;

    const params = {
      cancelButtonText: __('Cancel'),
      okButtonText: __('Hide'),
      onConfirm: () => {
        ChatApi.hideGroupChat(thread.id)
          .then(() => {
            dispatcher.dispatch('chat', 'hideGroupChat', thread);
            if (options.isChatWindow) {
              this.transitionToFirstThreadOrIndex(options.isChatRequestsPage);
            }
          })
          .catch(() => {
            FunctionalUtils.showDefaultErrorMessage();
          });
      },
    };

    if (thread.isEventChat) {
      params.title = __('Hide Event Chat');
      params.message = __(
        'Event chat will not pop up or be in My Chats. It is always in your event and unhides when you open or post to it.'
      );
    } else {
      params.title = __('Hide Group Chat');
      params.message = __(
        'Group chat will not pop up or be in My Chats. It is always in your group and unhides when you open or post to it.'
      );
    }

    this.dynamicDialogs.openDialog('simple-dialog-new', params);
  },

  leaveChatConfirm(options) {
    if (!options || !options.thread) return;

    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Leave this Chat?'),
      message: __('Are you sure you want to leave this chat? You will no longer receive new messages.'),
      cancelButtonText: __('Cancel'),
      okButtonText: __('Leave'),
      onConfirm: () => {
        ChatApi.leaveChatThread(options.thread.id)
          .then(() => {
            FunctionalUtils.info(__('You have left this chat.'));
            this.deleteChatCallback(options);
          })
          .catch(() => FunctionalUtils.showDefaultErrorMessage());
      },
    });
  },

  deleteChatConfirm(options) {
    if (!options || !options.thread) return;

    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Delete Thread'),
      message: __('Do you really want to delete this chat thread?'),
      cancelButtonText: __('Cancel'),
      okButtonText: __('Delete Thread'),
      onConfirm: () => {
        ChatApi.removeThread(options.thread.id)
          .then(() => {
            FunctionalUtils.info(__('Chat has been successfully deleted.'));
            this.deleteChatCallback(options);
          })
          .catch(() => FunctionalUtils.showDefaultErrorMessage());
      },
    });
  },

  deleteChatRequestConfirm(options) {
    if (!options || !options.thread) return;

    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Delete chat request'),
      message: __('Are you sure you want to delete this chat request?'),
      cancelButtonText: __('Cancel'),
      okButtonText: __('Delete chat request'),
      onConfirm: () => {
        ChatApi.removeThread(options.thread.id)
          .then(() => {
            if (options.thread && options.thread.isReceivedChatRequest) {
              dispatcher.dispatch('app', 'showDYKpopup', 'chatRequest');
            }
            FunctionalUtils.info(__('Chat has been successfully deleted.'));
            this.deleteChatCallback(options);
          })
          .catch(() => FunctionalUtils.showDefaultErrorMessage());
      },
    });
  },

  deleteChatCallback(options) {
    dispatcher.dispatch('chat', 'removeThread', options.thread);
  },

  markMessageAsSeen(messageId, threadId) {
    dispatcher.dispatch('chat', 'markAsSeen', messageId);
    if (threadId) {
      dispatcher.dispatch('chat', 'deleteMessage', threadId, messageId);
    }
  },

  pushIsTyping(chat, newMsg) {
    if (newMsg === '') {
      return;
    }

    if (chat.lastNewMsg && chat.lastNewMsg === newMsg) {
      return;
    }
    chat.lastNewMsg = newMsg;

    const threadId = chat.thread.id;
    const timer = new Date().getTime();
    const typingTimer = chat.typingTimer;

    if (isDefined(threadId)) {
      if (!typingTimer || timer - typingTimer > 3000) {
        chat.typingTimer = new Date().getTime();
        ChatApi.chatTyping(threadId);
      }
    }
  },

  selectFirstThreadIfNeeded(isChatRequestsPage) {
    if (ChatStore.getState().get('threadSelectionInProgress')) return;

    const selected = ChatStore.getState().get('selectedThread');
    let isProperChatSelected;

    if (selected) {
      // user clicked on a thread that is waiting to be created
      if (!selected.get('id') && selected.get('waitingForCreation')) {
        if (selected.get('selectedItems.length') === 1 || selected.get('observableOthers.length') === 1) {
          ChatStore.send(
            'openNewChatMode',
            selected.get('selectedItems')[0].user || selected.get('observableOthers')[0]
          );
        } else {
          ChatStore.send('deselectCurrentThread');
        }

        return;
      }
      // included checking if request is in receivedChatRequests as we don't show CR for created CR (onlt received)
      const isReqOnReqPage =
        isChatRequestsPage &&
        selected.get('isChatRequest') &&
        ChatStore.getState()
          .get('receivedChatRequests')
          .find((r) => r.id === selected.get('id'));
      const isChatOnChatPage = !isChatRequestsPage && !selected.get('isChatRequest');
      isProperChatSelected = isReqOnReqPage || isChatOnChatPage;
    }

    if (!selected || !isProperChatSelected) {
      if (selected) ChatStore.send('deselectCurrentThread');
      this.transitionToFirstThreadOrIndex(isChatRequestsPage);
    }
  },

  transitionToFirstThreadOrIndex(isChatRequestsPage) {
    let thread = ChatStore.findNotHiddenAndNotDisabledClosedThread(isChatRequestsPage);

    if (thread) {
      this.transitionToThread(thread);
    } else if (isChatRequestsPage) {
      this.get('routing').transitionTo('app.chat.requests', []);
    } else {
      this.get('routing').transitionTo('app.chat.index', []);
    }
  },

  showUserProfile(thread = {}) {
    if (thread.isNewChat) {
      // use case: SG-40963
      if (thread.selectedItems?.length && thread.selectedItems[0].isSuggestion) {
        this.routing.transitionTo('app.publicid', [thread.selectedItems[0].user.publicLinkId]);
      }
    } else if (thread.isUserChat || thread.isChatRequest) {
      let user = thread.getUserChatParticipant;
      if (user?.publicLinkId) {
        this.routing.transitionTo('app.publicid', [user.publicLinkId]);
      }
    }
  },

  transitionToThread(thread, messageId) {
    // this flow is for already existing thread
    ChatStore.send('closeNewChatMode');

    // not yet created thread was clicked
    if (!thread.get('id')) {
      if (!thread.get('selectedItems.length') && thread.get('observableOthers.length') === 1) {
        // new chat opened from e.g. group members list don't have selectedItems as it's not chat creation thread but also there is no thread.id SG-18087
        this.get('routing').transitionTo('app.chat.create-thread', [], {
          userId: thread.get('observableOthers')[0].id,
          newMessage: thread.get('newMessage'),
        });
      } else {
        // for multiple participants just do transition
        this.get('routing').transitionTo('app.chat.create-thread', []);
      }
    } else {
      let transitionTo = thread.isReceivedChatRequest ? 'app.chat.requests-thread' : 'app.chat.thread';

      if (messageId) this.get('routing').transitionTo(transitionTo + '.message', [thread.id, messageId]);
      else this.get('routing').transitionTo(transitionTo, [thread.id]);

      dispatcher.dispatch('chat', 'unhideGroupChat', thread);
    }
  },

  transitionToThreadByIdOrOpenParticipants(threadId, messageId, addTemporarilyToStore) {
    ChatStore.getState().set('threadSelectionInProgress', true);

    this.findThreadById(threadId, messageId).then((thread) => {
      if (thread) {
        this.doTransition(thread, messageId);
      } else {
        this.createThreadById(threadId, {
          open: true,
          addTemporarilyToStore: addTemporarilyToStore,
          aroundMessageId: messageId,
          doneCb: () => {
            this.findThreadById(threadId, messageId).then((thread) => {
              this.doTransition(thread, messageId);
            });
          },
          failCb: () => {
            ChatStore.getState().set('threadSelectionInProgress', false);
          },
        });
      }
    });
  },

  doTransition(thread, messageId) {
    if (!thread) return;

    const message = A(thread.messages).find((m) => m.id === messageId);

    if (!message) {
      this.transitionToThread(thread);
    } else if (message && message.deleted) {
      FunctionalUtils.error(__('Sorry, this chat message has been deleted.'));
      this.transitionToThread(thread);
    } else if (thread) this.transitionToThread(thread, messageId);
    else {
      ChatStore.send('removeThreadById', thread.id);
      FunctionalUtils.error(__('Sorry, this chat has been deleted.'));
    }

    ChatStore.getState().set('threadSelectionInProgress', false);
  },

  getMessageFromStore(threadId, messageId) {
    const thread = ChatStore.getThreadById(threadId);
    return thread ? A(thread.messages).find((m) => m.id === messageId) : false;
  },

  messageEmojiAdd(data) {
    const message = this.getMessageFromStore(data.threadId, data.messageId);

    if (message) {
      each(data.emojis, (e) => {
        dispatcher.dispatch('emojify', 'addEmoji', message, e, data.userId);
      });
    }
  },

  messageEmojiRemove(data) {
    const message = this.getMessageFromStore(data.threadId, data.messageId);

    if (message) {
      each(data.emojis, (e) => {
        dispatcher.dispatch('emojify', 'removeEmoji', message, e, data.userId);
      });
    }
  },

  scheduleStopHighlight(threadId, messageId, scheduleTime = highlightTime) {
    later(() => {
      const message = this.getMessageFromStore(threadId, messageId);

      if (message && get(message, 'highlight')) {
        set(message, 'highlight', false);
      }
    }, scheduleTime);
  },

  writeChatFilter(filter) {
    storage.set(storage.keys.chatFilter, filter);
    this.filter = filter;
  },

  readChatFilter() {
    this.filter = storage.get(storage.keys.chatFilter);
    return this.filter || ChatFilter.ALL;
  },
});
