import { helper as buildHelper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

// template helper expects:
// A: { core (required), suffix (optional) } object as input
//  - if A is a string we uses convertHandleToObject
// OR
// B: null, core (required), suffix (optional)

// function expects:
// handle object containing at least the 'core' property

const getHandleHtml = (value) => {
  // array when used as helper in template, object when used as function
  const handle = typeof value === 'string' ? convertHandleToObject(value) : value;
  let handleObj = handle.core ? handle : handle[0];
  let handleCore = handleObj?.core || handle[1] || '';
  let handleSuffix = handleObj?.suffix || handle[2] || ''; // suffix might be undefined for non-DSNP users

  // core is required to display handle for both DSNP and non-DSNP users
  if (!handleCore) return '';

  // each part is wrapped in a span to allow for global styling
  return htmlSafe(
    `<span class="h-handle-value"><span class="core">${handleCore}</span><span class="suffix">${
      handleSuffix ? `.` + handleSuffix : ''
    }</span></span>`
  );
};

export const convertHandleToObject = (stringHandle) => {
  return {
    core: stringHandle.split('.')[0],
    suffix: stringHandle.split('.')[1] || '',
  };
};

export function getHandleHtmlString(value) {
  return getHandleHtml(value)?.string;
}

export default buildHelper(getHandleHtml);
