import { hbs } from 'ember-cli-htmlbars';

export default {
  title: 'Home/MwHomeWhitebox',
  component: 'MwHomeWhitebox',
};

const Template = () => ({
  template: hbs`<Home::MwHomeWhitebox/>`,
  context: {},
});

export const Default = Template.bind({});
