import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ContactsApi from 'mewe/api/contacts-api';

// "class modifier" has to be used to use service, functional modifiers doesn't allow that
export default class MentionOpenerModifier extends Modifier {
  @service router;
  @service dynamicDialogs;

  modify(element, positionalArgs, namedArgs) {
    this.element = element;
    this.options = namedArgs;
    this.onClickAction = this.onClick.bind(this);

    this.removeListener();
    this.addListener();

    registerDestructor(this, this.removeListener.bind(this));
  }

  addListener() {
    this.element.querySelectorAll('.h-mention')?.forEach((el) => {
      el.addEventListener('click', this.onClickAction);
    });
  }

  removeListener() {
    this.element.querySelectorAll('.h-mention')?.forEach((el) => {
      el.removeEventListener('click', this.onClickAction);
    });
  }

  onClick(e) {
    // always prevent default href redirection to avoid page reloading
    e.preventDefault();

    // opened in new tab
    if (e.ctrlKey) return;
    if (this.options.disabled) return;

    let targetEl = e.target;

    // mention clicked inside shared post should open post dialog with that post instead of user profile
    if (targetEl.closest('.shared-post')) return;

    if (this.options.discoverScope === 'group') return;
    if (this.options.isGroupPreview) return;

    // mention clicked in search results
    if (targetEl.classList.contains('word-highlight')) {
      targetEl = targetEl.parentNode;
    }

    let userId = targetEl.getAttribute('data-userid'),
      groupId = this.options.groupId,
      eventId = this.options.eventId;

    if (userId?.indexOf('id=') === 0) {
      userId = userId.slice(3);
    }

    if (userId) {
      this.dynamicDialogs.closeAll();

      if (eventId) {
        this.router.transitionTo('app.event.attendees.profile', eventId, userId);
      } else if (groupId && groupId != 'contacts' && groupId != 'mycloud' && groupId != 'privacymail') {
        ContactsApi.getUserDetails(userId, groupId)
          .then((data) => {
            if (data.groupRole) this.router.transitionTo('app.group.members.profile', groupId, userId);
            else this.router.transitionTo('app.profileid', userId);
          })
          .catch(() => {
            // if fetching group profile failed, then transition to main profile
            this.router.transitionTo('app.profileid', userId);
          });
      } else {
        this.router.transitionTo('app.profileid', userId);
      }
    }
  }
}
