import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';

import { emailRegex } from 'mewe/shared/utils';
import { trimAndLower } from 'mewe/utils/miscellaneous-utils';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import Utils from 'mewe/utils/function-utils.js';
import tokenManager from 'mewe/shared/token-manager';
import AccountApi from 'mewe/api/account-api';
import config from 'mewe/config';

export default class MwHomeResetPasswordComponent extends Component {
  challengeOrigin = 'login';
  arkoseKey = config.arkoseKeyLog;
  hcaptchaSiteKey = config.hcaptcha.login;
  hcaptchaElemClass = 'h-captcha_login';

  @tracked afterChallengeCallback;
  @tracked challengeReady;

  @tracked invalidEmail;
  @tracked invalidPhone;

  @tracked forgotPasswordSent;
  @tracked forgotPasswordPhoneCodeBlocked;
  @tracked forgotPasswordPhoneCodeSent;
  @tracked forgotPasswordError;
  @tracked sendingResetRequest;
  @tracked sendingVerifyRequest;

  resetIdentificationIncorrectText = __(
    `This doesn't look a like a number we recognize. Please check and try again. Make sure to add your country code first.`
  );

  constructor() {
    super(...arguments);

    if (this.args.parent.isEmailFlow) {
      this.email = this.args.parent.emailValue;
    } else {
      this.phoneNumber = this.args.parent.phoneCode + this.args.parent.phoneNumber;
    }
  }

  @computed('email')
  get emailValue() {
    return trimAndLower(this.email);
  }

  @action
  emailUpdated() {
    const isValid = emailRegex.test(this.emailValue);
    this.invalidEmail = !isValid;
    this.forgotPasswordError = isValid ? '' : __('Invalid Email Address');
  }

  @computed('phoneCode', 'phoneNumber')
  get phoneValue() {
    return this.phoneCode + this.phoneNumber;
  }

  @action
  phoneUpdated(number, params) {
    // hack for testers to be able to use +89 as a country code
    if (number.length === 12 && number.slice(0, 3) === '+89') {
      params.selectedCountryData.dialCode = '89';
      params.isValidNumber = true;
    }

    this.phoneCode = `+${params.selectedCountryData?.dialCode}`;
    this.phoneNumber = number.replace(this.phoneCode, '');
    this.invalidPhone = !params.isValidNumber;
    this.forgotPasswordError = this.invalidPhone ? __('Invalid Phone Number') : '';
  }

  @computed('invalidEmail', 'invalidPhone', 'sendingResetRequest')
  get isSubmitDisabled() {
    return this.invalidEmail || this.invalidPhone || this.sendingResetRequest;
  }

  @action
  resetPassword(e) {
    e.preventDefault();
    this.afterChallengeCallback = this.reset.bind(this);
  }

  reset(challengeToken, provider, onChallengeFailed) {
    if ((this.args.parent.isEmailFlow && this.invalidEmail) || this.invalidPhone) {
      return;
    }

    this.forgotPasswordError = null;
    this.sendingResetRequest = true;

    const failback = (res) => {
      if (res.status === 404) {
        this.forgotPasswordError = __("We couldn't find your account with that information");
      } else if (res.status === 400 && res.data.errorCode == 117) {
        onChallengeFailed();
      } else if (res.data && res.data.blocked) {
        this.forgotPasswordError = __("You've exceeded the number of attempts. Please try again later");
      } else if (res.data?.errorCode === 100) {
        this.forgotPasswordError = this.args.parent.isEmailFlow
          ? __('Invalid Email Address')
          : __('Invalid Phone Number');
      } else if (res.status === 400 && res.data.message === 'Invalid phone number') {
        FunctionalUtils.error(this.resetIdentificationIncorrectText);
      } else {
        FunctionalUtils.showDefaultErrorMessage();
      }
    };

    const finalCb = () => {
      if (!this.isDestroying && !this.isDestroyed) {
        this.sendingResetRequest = false;
      }
    };

    if (this.args.parent.isEmailFlow) {
      AccountApi.sendPasswordResetEmail({
        email: this.emailValue,
        session_token: challengeToken,
        challenge_provider: provider,
      })
        .then(() => {
          this.forgotPasswordSent = __(
            `Thanks! If an account exists with this email address, we'll send you an email message with instructions to reset your password`
          );
        })
        .catch(failback)
        .finally(finalCb);
    } else {
      AccountApi.sendPasswordResetCode({
        phoneNumber: this.phoneValue,
        session_token: challengeToken,
        challenge_provider: provider,
      })
        .then((res) => {
          let response = res.data;
          if (response && response.limitReached === true) {
            this.forgotPasswordPhoneCodeBlocked = true;
          } else {
            this.forgotPasswordPhoneCodeSent = true;
          }
        })
        .catch(failback)
        .finally(finalCb);
    }
  }

  @action
  verifyCode(e) {
    // prevent default password submiting
    e.preventDefault();

    this.sendingVerifyRequest = true;

    AccountApi.login({
      username: this.phoneValue,
      code: this.code,
    })
      .then((response) => {
        tokenManager.set(response);
        Utils.windowRedirect('/account/set-new-password/sms-code#', this.code);
      })
      .catch((response) => {
        if (response.data?.block) {
          this.forgotPasswordError = __('To control abuse, we limit the number of attempted logins per hour');
        } else if (response.status === 401) {
          if (response.data?.message === 'Incorrect reset password code') {
            this.forgotPasswordError = __('Incorrect reset password code');
          } else if (response.data?.message === 'Limit reached') {
            this.forgotPasswordError = __(
              'Please try again later. To control abuse, we limit the number of attempted logins per hour'
            );
          } else {
            this.forgotPasswordError = __('Error. Please try again later.');
          }
        } else if (response.status === 400) {
          FunctionalUtils.showDefaultErrorMessage();
          window.location.reload();
        }
      })
      .finally(() => (this.sendingVerifyRequest = false));
  }

  @action
  challengeReadyCallback() {
    this.challengeReady = true;
  }
}
