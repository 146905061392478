import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import FollowingApi from 'mewe/api/following-api';
import ContactsApi from 'mewe/api/contacts-api';
import dispatcher from 'mewe/dispatcher';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

// Follower tab can have 2 different contexts:
// - Following
// - Follow back
// - Request sent

// Following tab can have buttons:
// - Following (only until page refresh, then user is hidden from this tab)
// - Follow (only until page refresh, then user is hidden from this tab)
// - Request sent (only until page refresh, then user is hidden from this tab)

export default class UserItem extends Component {
  @service router;
  @service account;

  @tracked showFollowingDropdown;
  @tracked context;

  // Temp variable for keep the requestSendId, if the user decide to cancel without refresh the tab
  requestSendId;

  constructor() {
    super(...arguments);
    this.setContext();
  }

  get isFollowingMe() {
    return this.args.user.follower && !this.isFollowRequestSent;
  }

  get isUserFollowedByMe() {
    return this.args.user.following && !this.isFollowRequestSent;
  }

  get isFollowRequestSent() {
    return !isEmpty(this.args.user.followRequestId);
  }

  get isMyProfile() {
    return this.args.user.id === this.account.activeUser?.id;
  }

  get isRequestSent() {
    return this.args.filter === 'sent' && this.args.tabContext === 'request';
  }

  get isRequestReceived() {
    return this.args.filter === 'received' && this.args.tabContext === 'request';
  }

  get isNsfwBlurred() {
    if ((this.args.inCurrentUserContext && this.args.tabContext === 'followed') || this.isMyProfile) {
      return false;
    }
    return this.args.user?._links.avatar.nsfw;
  }

  get isFavContext() {
    return this.args.tabContext === 'remove-favorite' || this.args.tabContext === 'favorite';
  }

  setContext() {
    if (this.isUserFollowedByMe && !this.isFavContext) {
      this.context = 'followed';
    } else if (this.isFollowingMe && !this.isFavContext) {
      this.context = 'followback';
    } else if (this.isFollowRequestSent && !this.isFavContext) {
      this.context = 'requestSent';
    } else if (this.args.tabContext === 'blocked') {
      this.context = 'blocked';
    } else if (this.args.tabContext === 'favorite') {
      this.context = 'favorite';
    } else if (this.args.tabContext === 'remove-favorite') {
      this.context = 'remove-favorite';
    } else {
      this.context = 'unfollowed';
    }
  }

  @task
  *unblockUser() {
    yield ContactsApi.unblockUser(this.args.user.id);
    this.args.onUserUpdate?.(this.args.user);
  }

  @task
  *doFollow() {
    let request = yield FollowingApi.doFollow(this.args.user.id);
    if (request?.limitReached) {
      this.account.followLimitPopup();
      return;
    }

    if (request?.follow?.followRequestId) {
      this.requestSendId = request.follow.followRequestId;
      this.context = 'requestSent';
    } else {
      this.context = this.context === 'followback' ? 'followers' : 'followed';
    }
  }

  @task
  *unFollowUser() {
    yield FollowingApi.unFollow(this.args.user.id);
    this.context = (['followers'].includes(this.context) || this.isFollowingMe) && this.args.tabContext !== 'followed' ? 'followback' : 'unfollowed';
  }

  @task
  *declineUser() {
    yield FollowingApi.declineRequest(this.args.user.followRequestId);
    this.args.onUserUpdate?.(this.args.user);
  }

  @task
  *acceptUser() {
    const request = yield FollowingApi.acceptRequest(this.args.user.followRequestId);
    if (request?.limitReached) {
      this.account.followLimitPopup();
      return;
    }
    this.args.onUserUpdate?.(this.args.user);
  }

  @action
  toggleFollowingDropdown() {
    this.showFollowingDropdown = !this.showFollowingDropdown;
  }

  @task
  *addFavorite() {
    if (this.args.isFavoriteLimitReached) {
      this.account.favoriteLimitPopup();
      return;
    }
    yield FollowingApi.addToFavorite(this.args.user.id);
    this.args.addToFavorite?.(this.args.user);
  }

  @task
  *removeFavorite() {
    yield FollowingApi.removeFromFavorite(this.args.user.id);
    this.args.removeFavorite?.(this.args.user);
  }

  @action
  openProfile() {
    if (this.args.user?.blockedAt) return;

    this.args.closeDialog?.();
    this.router.transitionTo('app.publicid', this.args.user.publicLinkId);
  }

  @action
  cancelRequestSent() {
    let contact = { ...this.args.user.user };
    contact.followRequestSent = this.args.user.followRequestId || this.requestSendId;
    const callback = () => {
      if (this.context === 'blocked' || (this.context === 'requestSent' && this.args.tabContext === 'request')) {
        this.args.onUserUpdate?.(this.args.user);
      } else if (this.context === 'requestSent' && this.isFollowingMe) {
        this.context = 'followback';
      } else {
        this.setContext();
      }
    };
    dispatcher.dispatch('contact', 'cancelRequestSent', { user: contact }, callback);
  }
}
