import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { postCustomInteraction } from 'mewe/utils/post-utils';
import { FeedTypes } from 'mewe/constants';
import PostUtils from 'mewe/utils/post-utils';
import { Theme } from 'mewe/constants';
import { inject as service } from '@ember/service';
import GroupStore from 'mewe/stores/group-store';
import { tracked } from '@glimmer/tracking';

export default class MwPostPhoto extends Component {
  @service dynamicDialogs;
  @service storage;

  @reads('args.post') post;

  @tracked gifModelLocal;

  gifImageSize = '800x800';

  @computed('args.fixedMaxWidth')
  get fixedMaxWidth() {
    return this.args.fixedMaxWidth ?? this.media.photo?.size?.width;
  }

  @computed('post.photo.animated')
  get gifModel() {
    return this.gifModelLocal ? this.gifModelLocal : this.post.photo?.animated ? this.post.photo : null;
  }

  @action
  openMediaDialog(post, media) {
    if (this.post?.isPublicContent && this.post?.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, this.post);
      return;
    }
    // - if sharing post preview in postbox then don't open photos in MD
    // - if shared post inside post then don't open photo in MD, SPV will be opened instead
    if (this.args.postInPostbox || this.args.isSharedPost) return;

    const options = {
      postId: media.postItemId,
      fromPost: true,
      groupId: post.groupId || post.group?.id,
      pageId: post.pageId,
      page: post.page,
      post,
      isSharedPost: this.args.isSharedPost,
      isRefPost: this.args.isRefPost,
      discoverScope: post.discoverScope,
      theme: this.scope,
      isGroupPreview: post.isGroupPreview,
    };

    if (post.isScheduled) {
      options.isScheduled = true;

      if (!this.scope) {
        options.scope = post.groupId ? 'group' : post.pageId ? 'page' : 'contacts';
      }
    }

    if (post.eventId) {
      options.eventId = post.eventId;
    } else if (post.event2?.id) {
      options.eventId = post.event2?.id;
    }

    // for group events we can't fetch media data for single photo posts, if user is not a group member,
    // so in this case we open MD with just media URL for image preview. For multiphoto posts it will work fine (SG-44642)
    if (options.eventId && post.groupId && post.mediasCount === 1) {
      if (!GroupStore.getGroupIfMember(post.groupId)) {
        options.photoUrl = media.photo._links.img.href;
      }
    }

    if (post.pending) {
      options.pendingPostId = post.postItemId;
      options.post = post;
    } else if (post.medias?.length > 1) {
      options.multiPostId = post.postItemId;
      options.imgId = media.postItemId;
    } else {
      options.post = post;
    }

    if (post.hashTags?.length === 1 && !post.album) options.hashtag = post.hashTags[0];

    if (this.args.openHashTagFromPost) {
      options.openHashTagFromPost = this.args.openHashTagFromPost;
    }

    if (this.args.scopeId) options.scopeId = this.args.scopeId;

    if (this.args.threadId) {
      // no need to pass threadId when group photo is opened from chat info media
      if (this.args.scope === Theme.PRIVATEPOSTS) {
        options.threadId = this.args.threadId;
      }
    } else if (post.threadId) {
      options.threadId = post.threadId;
    }

    if (this.args.isSharedPost) {
      options.userId = post.userId;
    } else if (this.args.scope) {
      options.scope = this.args.scope;
      options.theme = this.args.scope;

      // profile feed opened when photo clicked in profile feed post, or in SPV
      if (this.args.scope === Theme.PROFILE || this.args.isPostDialog) {
        options.userId = post.userId;
      }
    }

    if (post.album && !options.multiPostId && !options.pendingPostId) {
      options.mediaType = 'album';
      options.album = post.album;
      options.userId = post.owner?.id;
    }

    options.imageSize = post.media?.photo?.size;

    if (options.theme === Theme.MYCLOUD) options.groupId = Theme.MYCLOUD;

    if (options.scope == FeedTypes.ALL) {
      options.scope = PostUtils.getPostScopeAndId(post).scope;
    }

    options.allowMultipleInstances = true;

    this.dynamicDialogs.openDialog('media-dialog', options);
  }

  @action
  setGifModel(gifModel) {
    this.gifModelLocal = gifModel;
  }
}
