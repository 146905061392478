// Copyright 2018 Twitter, Inc.
// Licensed under the Apache License, Version 2.0
// http://www.apache.org/licenses/LICENSE-2.0
import extractMentions from './extractMentions';
export default function (username) {
  if (!username) {
    return false;
  }

  var extracted = extractMentions(username); // Should extract the username minus the @ sign, hence the .slice(1)

  return extracted.length === 1 && extracted[0] === username.slice(1);
}