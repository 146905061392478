import Component from '@glimmer/component';
import Scrolling from 'mewe/utils/scrolling-utils';
import dispatcher from 'mewe/dispatcher';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';

export default class MwFollowRequests extends Component {
  @tracked scrollEl;

  scrolling = Scrolling();

  constructor() {
    super(...arguments);

    if (this.args.requests?.items?.length) {
      this.setScrolling();
    }

    // eslint-disable-next-line ember/no-observers
    addObserver(this, 'args.requests.items.length', this, this.setScrolling);
  }

  setScrolling() {
    setTimeout(() => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.scrolling.bindScrollDownElement(this.scrollEl, () => this.loadMorePending(), 500);
    }, 0);
  }

  willDestroy() {
    removeObserver(this, 'args.requests.items.length', this, this.setScrolling);
    this.scrolling.unbindScrollDown(this.scrollEl);
    super.willDestroy();
  }

  loadMorePending() {
    dispatcher.dispatch('contacts', 'loadMoreContacts', this.args.requests);
  }

  @action
  setScrollEl(el) {
    this.scrollEl = el;
  }
}
