import { assert } from '@ember/debug';
import ApiUtil from './api';

/**
 * USAGE EXAMPLE:
 *
 * import AccountApi from 'mewe/api/account-api';
 */
class Api extends ApiUtil {
  login(params, options = {}) {
    return this.post('/api/v3/auth/login', {
      data: params,
      contentType: 'application/x-www-form-urlencoded',
      non401: true,
      traceId: options.traceId,
      xDsnp: options.xDsnp,
    });
  }

  checkoutJail() {
    return this.post('/account/jail-checkout', {}, 'apiRoot3');
  }

  approveNewToS(params) {
    return this.post('/auth/tos/accept', {
      data: JSON.stringify(params),
    });
  }

  getData() {
    return this.get('/account?details=true');
  }

  getCurrentUser() {
    return this.get('/me/info', {
      // sometimes getCurrentUser is used to check if user exists on public sites, non401 is to not redirect to logout
      non401: true,
    });
  }

  setData(params) {
    return this.post('/account', {
      data: JSON.stringify(params),
    });
  }

  setPassword(params, revokeSessions) {
    return this.post(`/account/password?revoke-all-session=${revokeSessions}`, {
      data: JSON.stringify(params),
    });
  }

  setPasswordEmail(params, revokeSessions) {
    return this.post(`/auth/reset-email-pass?revoke-all-session=${revokeSessions}`, {
      data: JSON.stringify(params),
    });
  }

  setEmails(params) {
    return this.post('/account/emails', {
      data: JSON.stringify(params),
    });
  }

  // when user is locked out and needs to add a new email to unlock, pw not required
  setEmailToVerify(params) {
    return this.post('/account/verified/emails', {
      data: JSON.stringify(params),
    });
  }

  setDsnpHandle(params) {
    return this.post(`/account/invitelinkid/dsnp`, {
      data: JSON.stringify(params),
    });
  }

  getSpaceUsedStatus(details) {
    if (details) {
      return this.get('/quota/space?details=true');
    } else {
      return this.get('/quota/space');
    }
  }

  confirmAccount(id) {
    return this.post(`/api/v3/auth/confirm-account/${id}`);
  }

  deleteAccount(params) {
    return this.del(
      '/account',
      {
        data: JSON.stringify(params),
      },
      'apiRoot3'
    );
  }

  sendValidationEmail(email) {
    return this.post(`/account/email/check?email=${encodeURIComponent(email.toLowerCase())}`);
  }

  getOtpDestinations() {
    return this.get('/otp/destinations');
  }

  sendOtpCode(destination = false) {
    let query = '';

    if (destination) {
      query = ~destination.indexOf('@')
        ? `?useEmail=${encodeURIComponent(destination.toLowerCase())}`
        : `?usePhone=${destination.replace('+', '%2B')}`;
    }

    return this.post(`/otp/send${query}`);
  }

  checkEmailAvailability(email) {
    return this.get(`/auth/checkEmail?email=${encodeURIComponent(email.toLowerCase())}`);
  }

  resendConfirmationLink(email, traceId = '') {
    return this.post('/me/sendvalidation', {
      data: JSON.stringify({ email: email }),
      traceId,
    });
  }

  checkHint(params) {
    return this.post('/account/check-hint', {
      data: JSON.stringify(params),
    });
  }

  useHint(params) {
    return this.post('/account/use-hint', {
      data: JSON.stringify(params),
    });
  }

  hint(name) {
    return this.get(`/me/hints/views/${name}`);
  }

  setLocale(loc) {
    // iw is legacy java hebrew
    return this.post('/account/locale/set?locale=' + loc.replace('-', '_'));
  }

  setTimezone(tzId) {
    return this.post(`/account/timezone?zoneId=${encodeURIComponent(tzId)}`, { non401: true });
  }

  gstasks() {
    return this.get('/account/gstasks');
  }

  validateEmail(id) {
    return this.post(`/account/validate-email/${id}`);
  }

  getCustomData(key) {
    assert('account-api - key is missing', key);
    return this.get(`/customdata/${key}`, { non401: true });
  }

  setCustomData(key, value) {
    assert('account-api - key is missing', key);
    assert('account-api - value is missing', value);
    return this.post('/customdata', { data: JSON.stringify({ key: key, value: value }) });
  }

  getBadges() {
    return this.get(`/api/v2/account/badges`);
  }

  setBadges(params) {
    return this.post('/api/v2/account/badges', {
      data: JSON.stringify(params),
    });
  }

  getSessions() {
    return this.get('/api/v3/user/sessions');
  }

  revokeSession(id) {
    return this.del(`/api/v2/user/session/${id}`);
  }

  revokeAllSessions(includeCurrent) {
    return this.del(`/api/v3/user/sessions?include-current-session=${!!includeCurrent}`);
  }

  verifyLockedAccount(token, provider) {
    return this.post('/api/v2/account/locked/verify', {
      data: JSON.stringify({
        session_token: token,
        challenge_provider: provider,
      }),
    });
  }

  updateLockStatus() {
    return this.post('/api/v3/account/update-lock-status');
  }

  // user has to reconfirm their primary phone when they have phone & email and have been locked out
  reconfirmPhone(params) {
    return this.post('/api/v3/validation/phone/confirm', {
      data: JSON.stringify(params),
    });
  }

  sendPasswordResetEmail(params) {
    return this.post('/api/v2/auth/send-reset-email', {
      data: JSON.stringify(params),
      non401: true,
    });
  }

  sendPasswordResetCode(params) {
    return this.post('/api/v2/auth/reset-pass', {
      data: JSON.stringify(params),
      non401: true,
    });
  }

  getCampaign(campaignName) {
    return this.get(`/api/v2/campaigns?campaign=${campaignName}`);
  }

  getPreferences() {
    return this.get('/api/v2/account/preferences');
  }

  setPreferences(params) {
    return this.put('/api/v2/account/preferences', {
      data: JSON.stringify(params),
    });
  }

  getIdentifiers() {
    return this.get('/api/v2/account/identifiers');
  }
}

export default new Api();
