import emojisParser from 'mewe/stores/text-parsers/emojis-parser';
import gifUrlsParser from 'mewe/stores/text-parsers/gif-urls-parser';
import markdownParser from 'mewe/stores/text-parsers/markdown-parser';
import Hashtags from 'mewe/utils/hashtags-utils';

import Verbose from 'mewe/utils/verbose';
const v = Verbose({ prefix: '{Text Parsers - toServer}', color: 'red', enabled: false });
const log = v.log;

// textEdit => textServer
export default function (textEdit, { mentionsStrategy, gifUrls, parseNativeMarkdown } = {}) {
  textEdit = textEdit || '';
  let text = textEdit.trim();

  log(`text: ${text}`);

  text = Hashtags.stripHashtags(text);

  log(`stripHashtags text: ${text}`);

  if (parseNativeMarkdown) {
    text = markdownParser.toServer(text);
    log(`markdownParser text: ${text}`);
  }

  if (gifUrls) {
    text = gifUrlsParser.toServer(text, gifUrls);
    log(`girUrlsParser text: ${text}`);
  }

  if (mentionsStrategy) {
    // TODO plain make getEncodedText(text) => toServer(text, strategy) to keep it clean
    text = mentionsStrategy.getEncodedText(text);
    if (v.isEnabled)
      log(`mentionsStrategy text: ${text}, mentions: ${mentionsStrategy.mentions.map((el) => el.name).join(', ')}`);
  }

  text = emojisParser.toServer(text);

  log(`emojisParser text: ${text}`);

  return text;
}
