import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { getQueryStringParams, addSignupUrlParams } from 'mewe/shared/utils';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import jstz from 'jstimezonedetect';
import MathUtils from 'mewe/utils/math-utils';
import * as Sentry from '@sentry/ember';
import { isMobile } from 'mewe/shared/utils';

export default class MwDsnpSignupConfirmComponent extends Component {
  @service router;
  @service analytics;
  @service authentication;
  @service dynamicDialogs;

  @tracked promisesToWaitFor = [];
  @tracked requestFailed = false;

  constructor() {
    super(...arguments);

    const signupPromise = new Promise((resolve) => (this.resolvePromise = resolve));
    this.promisesToWaitFor.push(signupPromise);

    this.urlParams = getQueryStringParams();

    const platform = this.urlParams.platform;

    if ((platform === 'ios' || platform === 'android') && isMobile()) {
      this.showAppDialog();

      // we want to trigger this only once, that's why it's done
      // here in constructor instead of showAppDialog function
      this.analytics.sendEvent('popupViewed', 'Confirmation Link Redirect');
    } else {
      this.doRegister();
    }
  }

  showAppDialog() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      closeable: false, // can be closed by "cancel" button only
      okButtonText: __('Open MeWe app'),
      cancelButtonText: __('Use browser'),
      message: __(
        `We recommend using the MeWe app on your mobile device. If you have it installed the button below will open it for you.`
      ),
      onConfirm: () => {
        window.location = `mewe://${window.location.host}${window.location.pathname}${window.location.search}`;
        // confirm button closes the popup. In case the app is not installed we show the popup again so that user can try browser option
        setTimeout(() => this.showAppDialog(), 2000);

        if (!this.redirectAppTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - app');
          this.redirectAppTracked = true; // we want to track this only once
        }
      },
      onClose: () => {
        if (!this.redirectBrowserTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - browser');
          this.redirectAppTracked = true; // we want to track this only once
        }

        this.doRegister();
      },
    });
  }

  doRegister() {
    let { token, sessionId, email, handle, fname, lname, trc, t } = this.urlParams;

    if (!email || !handle || !fname || !lname) {
      const missingParams = `${!email ? 'email, ' : ''}${!handle ? 'handle, ' : ''}${!fname ? 'fname, ' : ''}${
        !lname ? 'lname, ' : ''
      }`;
      Sentry.captureException(new Error(`Web3 signup email confirmation - missing params: ${missingParams}`));
      return;
    }

    if (token && sessionId) {
      let params = {
        password: MathUtils.generateId(),
        email: email,
        firstName: fname,
        lastName: lname,
        imOver16: true,
        agreeWithTOS: true,
        publicProfile: true,
        timeZone: jstz.determine().name(),
        validationCode: token,
        sessionId: sessionId,
        t: t || Date.now(),
      };
      const reqOptions = {
        traceId: trc,
        xDsnp: true,
      };

      params = addSignupUrlParams(params, this.urlParams);

      PublicPagesApi.registerAccount(params, reqOptions)
        .then((data) => {
          this.authentication.registrationCallback(params, data, this.urlParams);
        })
        .catch((error) => {
          if (error.data?.errorCode === 124) {
            this.requestFailed = 'expired';
          } else {
            this.requestFailed = true;
          }
          Sentry.captureException(
            new Error(
              `Web3 signup email confirmation - registration failed. Status: ${error.status}, ${error.data?.message}`
            )
          );

          // this will finish the loading animation and show error screen
          this.resolvePromise();
        });
    }
  }

  @action
  retryRedirect() {
    // params passed to be pre-filled in the signup form
    this.router.transitionTo('register', { queryParams: this.urlParams });
  }
}
