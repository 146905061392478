import ApiUtil from './api';

/**
 * import GroupApi from 'mewe/api/group-api';
 */
class Api extends ApiUtil {
  fetch() {
    return this.get('/groups');
  }

  getPredefinedPhotos(params) {
    return this.get('/photo/predefined?' + this.toUrlParams(params));
  }

  setPredefinedPhoto(params) {
    return this.post('/photo/predefined', {
      data: JSON.stringify(params),
    });
  }

  create(params) {
    return this.post('/groups/add', {
      data: JSON.stringify(params),
    });
  }

  getCommonGroupsWithUser(userId, params) {
    return this.get(`/groups/common/${userId}?` + this.toUrlParams(params));
  }

  fetchGroupData(groupId) {
    return this.get('/group/' + groupId, { non401: true });
  }

  getSettings(groupId) {
    return this.get('/group/' + groupId + '/settings');
  }

  putSettings(groupId, params) {
    return this.put('/group/' + groupId + '/settings', {
      data: JSON.stringify(params),
    });
  }

  inviteUsers(params) {
    if (
      params &&
      params.groupId &&
      (params.emailInvitees || params.userInvitees || params.smsInvitees || params.wholeAddressBook)
    ) {
      return this.post('/group/' + params.groupId + '/members', {
        data: JSON.stringify(params),
      });
    }
  }

  blockUserFromGroup(userId, groupId) {
    return this.put(`/group-blocking/${groupId}/block/${userId}`);
  }

  unBlockUserFromGroup(userId, groupId) {
    return this.put(`/group-blocking/${groupId}/unblock/${userId}`);
  }

  blockUserFromAllGroup(userId) {
    return this.put(`/group-blocking/all/block/${userId}`);
  }

  unBlockUserFromAllGroup(userId) {
    return this.put(`/group-blocking/all/unblock/${userId}`);
  }

  previewAllGroupBlocking(userId) {
    return this.get(`/group-blocking/all/preview/${userId}`);
  }

  blockedUserSearch(groupId, params) {
    return this.get(`/group-blocking/${groupId}/list`, {
      data: params,
    });
  }

  checkPublicUrl(url) {
    return this.get('/groups/public-url/check', {
      data: {
        publicUrlId: url,
      },
    });
  }

  membersSearch(groupId, params) {
    return this.get('/group/' + groupId + '/members/search', {
      data: params,
    });
  }

  postsSearch(params) {
    params.nm = 1;
    return this.get('/search/group/posts' + '?' + this.serialize(params));
  }

  /**
   * Used in group searching in old front-end
   */
  membersSearchSolr(params) {
    return this.get('/search/group/members' + '?' + this.serialize(params), params);
  }

  members(groupId, params) {
    return this.get(`/group/${groupId}/members`, {
      data: params,
    });
  }

  admins(groupId) {
    return this.get(`/group/${groupId}/admins`);
  }

  supermembers(groupId) {
    return this.get(`/group/${groupId}/supermembers`);
  }

  invited(groupId, params) {
    return this.get('/group/' + groupId + '/members/invited', {
      data: params,
    });
  }

  setGroupData(groupId, params) {
    return this.put(`/group/${groupId}`, {
      data: JSON.stringify(params),
    });
  }

  transferOwnership(groupId, params) {
    return this.post('/group/' + groupId + '/ownership/transfer', {
      data: JSON.stringify(params),
    });
  }

  removeMember(groupId, userId) {
    return this.del('/group/' + groupId + '/member/' + userId + '/remove');
  }

  deleteGroup(groupId, password) {
    return this.del(`/group/${groupId}`, {
      data: { password: password },
      contentType: 'application/x-www-form-urlencoded',
    });
  }

  sendReminder(groupId, inviteeId) {
    return this.post('/group/' + groupId + '/invitation/' + inviteeId + '/reminder');
  }

  // to be used to fetch full list and do pagination
  // for fetching only first few contacts + total counter please use '/groups/mycontacts'
  myContacts(groupId, nextPageUrl) {
    if (nextPageUrl) {
      return this.getNextPageOr(nextPageUrl);
    } else {
      return this.get('/group/' + groupId + '/mycontacts');
    }
  }

  // to be used to fetch first few contacts + counter in specific group(s)
  // to fetch full list and do pagination please use 'group/id/mycontacts'
  myContactsInGroups(params) {
    return this.get('/groups/mycontacts', {
      data: params,
    });
  }

  contactsSearch(groupId, params) {
    return this.get('/group/' + groupId + '/contacts/search', {
      data: params,
    });
  }

  removeUserFromMyGroups(userId) {
    return this.del('/groups/my/' + userId + '/remove');
  }

  declineGroupInvitation(groupId) {
    return this.post('/group/' + groupId + '/invite/decline');
  }

  declineAndBlockGroupInvitation(groupId) {
    return this.post('/group/' + groupId + '/invite/block');
  }

  confirmGroupInvitation(groupId, answers) {
    return this.post('/group/' + groupId + '/invite/confirm', {
      data: JSON.stringify(answers),
    });
  }

  confirmGroupEmailInvitation(invitationId, noConfirmation = false) {
    // noConfirmation param => get invitation data but don't merge invtation (join group), because there can be grp questions
    return this.post(`/groups/mail-invite-confirm/${invitationId}?noConfirmation=${noConfirmation}`, { non401: true });
  }

  applicantsList(groupId, params) {
    return this.get(`/group/${groupId}/applications/list`, {
      data: params,
    });
  }

  blockedList(groupId, params) {
    return this.get(`/group-blocking/${groupId}/list`, {
      data: params,
    });
  }

  approveApplication(applicationId) {
    return this.post('/group/application/' + applicationId + '/approve');
  }

  approveAllApplications(groupId) {
    return this.post('/group/' + groupId + '/applications/approve-all');
  }

  rejectApplication(applicationId) {
    return this.post('/group/application/' + applicationId + '/reject');
  }

  rejectAllApplications(groupId) {
    return this.post('/group/' + groupId + '/applications/reject-all');
  }

  categoryNames() {
    return this.get('/groups/public/categories/all');
  }

  applyToGroup(groupId, answers) {
    return this.post('/group/public/' + encodeURIComponent(groupId) + '/apply', {
      data: JSON.stringify(answers),
    });
  }

  getGroupTopics(params) {
    return this.get('/groups/public/categories', {
      data: params,
    });
  }

  getGroupsInTopic(params) {
    if (params && params.topic) {
      return this.get(`/groups/public/${encodeURIComponent(params.topic)}`, {
        data: params,
      });
    }
  }

  getPermissions(groupId, params) {
    return this.get('/group/' + groupId + '/members/permissions', {
      data: params,
    });
  }

  getBlockedUsersCount(groupId, userId) {
    return this.get('/group/' + groupId + '/member/' + userId + '/admin');
  }

  removeBlockedUsers(groupId, userId) {
    return this.put('/group/' + groupId + '/member/' + userId + '/admin');
  }

  getOwnerBlockedUsersCount(groupId, userId) {
    return this.get('/group/' + groupId + '/member/' + userId + '/owner');
  }

  removeOwnerBlockedUsers(groupId, userId) {
    return this.put('/group/' + groupId + '/member/' + userId + '/owner');
  }

  getCounters(groupId) {
    return this.get('/group/' + groupId + '/members/count', { non401: true });
  }

  groupVisited(groupId) {
    // moving group to top of groups list on backend side
    return this.put(`/group/${groupId}/visited`, {}, 'apiRoot3');
  }

  isDiscoverHintValid(groupId) {
    return this.get(`/discover/hint/${groupId}`);
  }

  markDiscoverHintAsSeen(groupId) {
    return this.put(`/discover/hint/${groupId}`);
  }

  getSuggestedGroups(groupId) {
    return this.get(`/discover/group/feed`);
  }

}

export default new Api();
