import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import GroupStore from 'mewe/stores/group-store';
import ProfileStore from 'mewe/stores/profile-store';
import ContactsApi from 'mewe/api/contacts-api';
import { dateStringLongMonth } from 'mewe/utils/datetime-utils';
import { isMeWeTeam } from 'mewe/helpers/is-mewe-team';
import { getElWidth, getOffset, getWindowScrollTop } from 'mewe/utils/elements-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwProfilePopup extends PopupOpener {
  layout = layout;

  _dropdownId = 'profile-popup';
  popupType = 'profile-popup';
  closeOnClickOutside = true;
  closeOnScroll = true;
  closeOnHoverOutside = true;
  delayUntilOpen = 350;
  margin = 15;
  spaceForPopup = 245;
  regularAvatarHeight = 36;
  chatMessageAvatarHeight = 32;
  insideAnotherScrollable = false;

  @service dynamicDialogs;
  @service account;
  @service router;
  @service chat;

  @tracked showPopup = true;
  @tracked popupOnTop = false;
  @tracked widePopupInChat = false;
  @tracked userNotFound = false;

  @tracked user;
  @tracked avatar;
  @tracked hasGroupInStore;
  @tracked profileDropdownOpened = false;

  constructor() {
    super(...arguments);

    this.user = ProfileStore.getState({ uuid: this.ownerId });

    if (this.user?.links?.avatar) {
      this.setAvatar(this.user._links.avatar.href);
    }
  }

  get parent() {
    return this.args.params.parent;
  }

  get group() {
    return this.args.params.group;
  }

  get isGroup() {
    return this.group?.id && this.hasGroupInStore && this.group.id !== 'contacts' && !this.isEvent;
  }

  get event() {
    return this.args.params.event;
  }

  get isEvent() {
    return this.event?.id;
  }

  get owner() {
    return this.args.params.owner;
  }

  get ownerId() {
    let ownerId = this.owner?.id || this.owner?.userId || this.args.params.userId;

    if (ownerId && ownerId.indexOf('id=') > -1) {
      ownerId = ownerId.substr(3);
    }

    return ownerId;
  }

  get popupHeight() {
    return this.userNotFound ? 55 : 240;
  }

  get isNsfwBlurred() {
    if (this.owner?.id == this.account.activeUser.id) return false;
    return this.owner?._links?.avatar?.nsfw;
  }

  get isNsfwCoverBlurred() {
    if (this.user?.id == this.account.activeUser.id) {
      return false;
    }

    return this.owner?._links?.cover?.nsfw;
  }

  get publicLinkId() {
    return this.owner?.publicLinkId || this.user?.publicLinkId;
  }

  @action
  setupPopup() {
    let groupId;

    if (this.group?.id) {
      // group profile only if group is in user's confirmed groups, otherwise main profile will be loaded
      const groupInStore = GroupStore.getGroupIfMember(this.group.id);
      if (groupInStore) {
        groupId = this.group.id;
        this.hasGroupInStore = true;
      }
    }

    this.fetchProfile(this.ownerId, groupId);

    super.setupPopup(...arguments);
  }

  fetchProfile(ownerId, groupId) {
    const doneCb = () => {
      ProfileStore.send('updateData', {
        id: ownerId,
        isLoaded: true,
      });
    };

    ContactsApi.getUserDetails(ownerId, groupId)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) return;

        ProfileStore.send('handleRawData', data);
        this.setAvatar(data._links.avatar.href);
        doneCb();
      })
      .catch((resp) => {
        if (this.isDestroyed || this.isDestroying) return;

        if (resp && resp.status === 404) {
          // fetching group profile failed, try fetching main profile
          if (groupId) {
            this.fetchProfile(ownerId);
          } else {
            this.userNotFound = true;
            this.position();
            doneCb();
          }
        } else {
          doneCb();
        }
      });
  }

  setAvatar(avatar) {
    if (!this.isDestroyed && !this.isDestroying) {
      if (this.args.params.avatar) {
        this.avatar = this.args.params.avatar;
      } else if (!this.avatar) {
        this.avatar = avatar.replace('{imageSize}', '150x150');
      }
    }
  }

  getPlacement() {
    // 30px for possible badge width that is not included in width before loading profile data
    const popupWidth = getElWidth(this.element) + 30;
    const parentEl = this.parent;

    if (!parentEl) return;

    const elementOffset = getOffset(parentEl);

    const placement = {
      top: elementOffset.top + this.parentHeight,
      left: elementOffset.left - this.margin,
    };

    const spaceToBottom = window.innerHeight - (elementOffset.top - getWindowScrollTop() + this.parentHeight);

    if (spaceToBottom < this.spaceForPopup) {
      placement.top -= this.popupHeight + this.parentHeight;
      this.popupOnTop = true;
    }

    // vertical positioning adjustments, experimental values
    if (this.args.params.isMention) {
      if (this.popupOnTop) {
        placement.top += 10;
      } else {
        this.args.params.inChatMessage ? (placement.top -= 10) : (placement.top -= 20);
      }
    } else if (this.popupOnTop) {
      placement.top += 10;
    }

    if (this.args.params.inChatMessage) {
      if (this.args.params.isMention) {
        placement.left -= 50;
      } else {
        const maxWidthInSmallChat = 295;
        const isRtl = document.dir;

        if (isRtl) {
          placement.left = elementOffset.left - popupWidth + 50;

          if (popupWidth > maxWidthInSmallChat) {
            placement.left += 30;
            this.widePopupInChat = true;
          }
        }

        if (popupWidth > maxWidthInSmallChat && placement.left > 30) {
          placement.left -= 30;
          this.widePopupInChat = true;
        }
      }
    }

    if (placement.left + popupWidth + this.margin > window.innerWidth) {
      placement.left = window.innerWidth - popupWidth - this.margin;
    }

    return placement;
  }

  get parentHeight() {
    return this.args.params.inChatMessage
      ? this.chatMessageAvatarHeight
      : this.args.params.regularAvatarHeight || this.regularAvatarHeight;
  }

  get isMentionAndChatMsg() {
    return this.args.params.inChatMessage && this.args.params.isMention;
  }

  get canInvite() {
    return (
      this.user &&
      this.user.canBeInvited &&
      !this.user.isContact &&
      !this.user.followRequestSent &&
      !this.user.following &&
      !this.user.contactInvitationReminder
    );
  }

  get userRegisteredDate() {
    if (this.user?.registeredAt) {
      return dateStringLongMonth(this.user.registeredAt, this.account.activeUser.jsLocale);
    }
  }

  get widePopup() {
    return this.isGroup && this.canInvite && this.user?.canChat;
  }

  closeAllDialogs() {
    this.close();
    this.dynamicDialogs.closeAll();
  }

  @action
  doFollow() {
    this.closeAllDialogs();

    dispatcher.dispatch('contact', 'doFollow', this.user, (data) => {
      this.args.params.onCloseAfterFollow?.(data);
    });
  }

  @action
  openUserChat() {
    this.closeAllDialogs();
    this.chat.openThreadByParticipants([this.user]);
    this.profileDropdownOpened = false;
  }

  @action
  goToUserGroupProfile() {
    this.closeAllDialogs();
    this.router.transitionTo('app.group.members.profile', this.group.id, this.ownerId);
  }

  @action
  goToUserEventProfile() {
    this.closeAllDialogs();
    this.router.transitionTo('app.event.attendees.profile', this.event.id, this.ownerId);
  }

  @action
  goToUserMainProfile() {
    this.closeAllDialogs();
    this.router.transitionTo('app.publicid.posts', this.user.publicLinkId);
  }

  @action
  openUserAvatar() {
    let avatarUrl = this.avatar.replace('150x150', '1600x1600');
    avatarUrl = avatarUrl.slice(avatarUrl.indexOf('/api'));

    this.closeAllDialogs();

    this.dynamicDialogs.openDialog('media-dialog', {
      photoUrl: avatarUrl,
      mediaType: 'photoUrl',
      allowMultipleInstances: true,
    });
  }

  @action
  toggleProfileDropdown() {
    this.profileDropdownOpened = !this.profileDropdownOpened;
  }

  @action
  reportUser() {
    this.close();

    const reportWithoutConfirmation = isMeWeTeam();
    const groupId = this.isGroup ? this.group.id : null;

    dispatcher.dispatch('contact', 'reportUser', this.user, groupId, null, reportWithoutConfirmation);
  }
}
