import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';

import PS from 'mewe/utils/pubsub';
import { getFirstInsert, setFirstInsert } from 'mewe/utils/first-insert';
import { SplitRetry } from 'mewe/utils/split-retry';

export default class AppRoute extends Component {
  @service purchase;
  @service journals;

  constructor() {
    super(...arguments);

    this.journals.sendSeen();

    if (window.performance && performance.navigation.type == 1) {
      const purchase = this.purchase; // initialize purchase-service before Publishing reload event because it's Subscribed there
      PS.Pub('navigation.reload');
    }
  }

  initPageDragOver() {
    let dragOverlay = document.querySelector('.app-dragover-overlay');

    if (dragOverlay) {
      document.addEventListener('dragover', function (e) {
        e.preventDefault();

        const timeoutId = 'app-dragover-timeout';
        const timeout = window[timeoutId];

        if (!timeout) {
          // limit to trigger only once while dragover
          dragOverlay.classList.add('active');
        } else {
          clearTimeout(timeout);
        }

        window[timeoutId] = setTimeout(function () {
          window[timeoutId] = null;
          dragOverlay.classList.remove('active');
        }, 100);
      });
      document.addEventListener('drop', function (e) {
        const files = e.dataTransfer;
        if (files && files.items && files.items[0] && files.items[0].kind !== 'file') {
          e.preventDefault();
          return;
        }
        if (e.target.classList.contains('app-dragover-overlay') || e.target.closest('.dialog_wrapper'))
          e.preventDefault();
      });
    }
  }

  initDomFocusObserver() {
    // function used for hiding focused element's outline only when clicked by mouse by adding 'focus--mouse' class
    let usingMouse;

    const preFocus = (event) => (usingMouse = event.type === 'mousedown');
    const removeFocus = (event) => {
      if (event.target.classList.contains('ql-editor')) {
        const textEditor = event.target.closest('.text-editor');
        textEditor?.classList.remove('focus--mouse');
      } else {
        event.target.classList.remove('focus--mouse');
      }
    };
    const addFocus = (event) => {
      if (usingMouse) {
        if (event.target.classList.contains('ql-editor')) {
          const textEditor = event.target.closest('.text-editor');
          textEditor?.classList.add('focus--mouse');
        } else {
          event.target.classList.add('focus--mouse');
        }
      }
    };

    document.body.addEventListener('keydown', preFocus);
    document.body.addEventListener('mousedown', preFocus);
    document.body.addEventListener('focusin', addFocus);
    document.body.addEventListener('focusout', removeFocus);
  }

  @action
  onInsert() {
    // boost my world

    Promise.all([
      SplitRetry(() => import('mewe/pods/components/app-main-frame/sidebars/sidebar-left-myworld')),
      SplitRetry(() => import('mewe/pods/components/app-main-frame/sidebars/sidebar-right-myworld')),
      SplitRetry(() => import('mewe/pods/components/others/mw-postbox-placeholder')),
    ]).then(() => {
      if (!getFirstInsert()) {
        setFirstInsert(true);
      }
    });

    this.initPageDragOver();

    this.initDomFocusObserver();

    Promise.all([
      SplitRetry(() => import('mewe/pods/components/chats/mw-chats-opened')),
      SplitRetry(() => import('mewe/ws-controllers/index')),
    ]).then((modules) => {
      let { postWsController, commentWsController } = modules[1].default;

      postWsController.create({ ctx: this });
      commentWsController.create({ ctx: this });

      next(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.chats = 'chats/mw-chats-opened';
        }
      });
    });

    next(() => {
      PS.Pub('app.ready');
    });
  }
}
