import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AppProfilePosts extends Component {
  @service account;
  @service router;

  @tracked isUnblurred = false;

  get model() {
    return this.args.model;
  }

  @computed('model.contact.{isContact,isMe,deactivated,nonContactCannotInvite}', 'model.tag')
  get isPostboxVisible() {
    return this.model.contact.isMe && !this.model.tag;
  }

  @computed('model.contact')
  get contactUser() {
    let user = this.model.contact.user;

    if (user) return user;
    else return this.model.contact;
  }

  @computed('model.{isMyProfile,feed.posts.length}', 'contact.{following,public}')
  get isDisplayedPosts() {
    const privateUserHasPosts = !this.model.contact.public && this.model.feed.posts?.length;

    if (this.model.isMyProfile || this.model.contact.public || this.model.contact.following || privateUserHasPosts) {
      return true;
    }
    return false;
  }

  @computed('model.feed.{isFetching,posts.length}', 'model.contact.isMe')
  get showFeedPlaceholder() {
    return this.model.contact.isMe && !this.model.feed.posts?.length && !this.model.feed.isFetching;
  }

  get isNsfwBlurred() {
    if (this.isUnblurred) return false;
    if (this.model.contact.isContact) return false;
    if (this.model.contact.following) return false;
    if (this.model.contact.id == this.account.activeUser.id) return false;

    return this.model.contact._links?.avatar?.nsfw;
  }

  @action
  unblur() {
    this.isUnblurred = true;
  }
}
