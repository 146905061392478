//TODO: local storage caching (MW)
import { assert } from '@ember/debug';

import EmberObject from '@ember/object';
import Collection, { getOrCreateCollectionFromHash } from 'mewe/stores/models/collection';
import { getOrCreate } from 'mewe/utils/fp';

const Hash = EmberObject.extend();

const HashOfCollections = EmberObject.extend({
  for: function (id) {
    assert('id', 'Missing id');
    return getOrCreateCollectionFromHash(this, id);
  },
});

const STORE = EmberObject.create();

let resource = (name, _class) => {
  Object.defineProperty(ds, name, {
    get: function () {
      return getOrCreate(STORE, _class)(name);
    },
  });
};

let hash = (name) => resource(name, Hash);
let hashOfCollections = (name) => resource(name, HashOfCollections);
let collection = (name) => resource(name, Collection);

export const ds = {};

// these are things that need to be indefined by some id, eg. pageId, groupId etc.
// hash can be a hash of collections, arrays, objects - anything really

hash('authors');
hash('feeds');
hash('simpleGroups');
hash('eventsCounters');
hash('eventPages');
hashOfCollections('pagesForCategory');
hashOfCollections('notifications');
hashOfCollections('followers');
hashOfCollections('banned');
hashOfCollections('groupsForCategory');
hashOfCollections('pageFollowersCountries');
hashOfCollections('pageFollowersIntervals');
hashOfCollections('pageAnalyticsOverviews');
hashOfCollections('entriesByTeller');
hashOfCollections('entriesByJournal');
hashOfCollections('journalsByTeller');
hashOfCollections('events');

// these are more of things that current user have in one instance

collection('administrated');
collection('followed');
collection('pages');
collection('buckets');
collection('featuredAll');
collection('trendingForBucket'); // TODO change to hashOfCollections
collection('categoriesForBucket'); // TODO change to hashOfCollections
collection('categoriesAll');
collection('invitations');
collection('searchedPages');
collection('groups');
collection('searchedGroups');
collection('searchedOpenGroups');
collection('groupCategories');
collection('featuredGroupCategories');
collection('tellers');
collection('wrappers');
collection('challenges');

ds.eventPublic = EmberObject.extend({
  isFetching: true,
  eventData: null,
  participation: null,
  eventPublicId: null,
}).create();

window.ds = STORE;
