//package mp3;

var III_psy_xmin = require('./III_psy_xmin.js');

function III_psy_ratio() {
	this.thm = new III_psy_xmin();
	this.en = new III_psy_xmin();
}

module.exports = III_psy_ratio;
