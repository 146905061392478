import { action, set } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';

import IndicatorsStore from 'mewe/stores/indicators-store';
import FunctionalUtils from 'mewe/shared/functional-utils';
import PostApi from 'mewe/api/post-api';

export default class MwSharingInfo extends Component {
  indicators = IndicatorsStore.getState();

  @service account;

  @reads('args.post') post;

  get isCommentsAllowed() {
    return !this.post.commentsDisabled;
  }

  @tracked isSavingChanges;
  @tracked sharingDropdownOpened = false;

  get showEveryoneOption() {
    return this.post.everyone || !this.account.activeUser.public;
  }

  get isEveryoneDisabled() {
    return this.post.everyone && this.account.activeUser.public;
  }

  get showDropdownOptions() {
    return this.post.isCurrentUserPostOwner && !this.args.inMediaDialog;
  }

  @action
  toggleDropdown() {
    this.sharingDropdownOpened = !this.sharingDropdownOpened;
  }

  @action
  togglePublicPost(value, event) {
    event.preventDefault();

    if (this.isSavingChanges) return;
    if (value === this.post.sharedTo) return;
    if (value == 'friends' && this.indicators.favorites == 0) return;

    this.isSavingChanges = true;

    if (value === 'friends') {
      PostApi.publishToFriends(this.post.postItemId, this.post.scheduled)
        .then(() => {
          set(this, 'post.public', false);
          set(this, 'post.everyone', false);
          set(this, 'post.closeFriends', true);

          set(this, 'post.permissions.reshare', false);
          set(this, 'post.permissions.repost', true);
        })
        .finally(() => (this.isSavingChanges = false))
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    } else {
      PostApi.togglePublicPost(this.post.postItemId, this.post.scheduled, value)
        .then(() => {
          set(this, 'post.public', value == 'public');
          set(this, 'post.everyone', value == 'everyone');
          set(this, 'post.closeFriends', false);

          set(this, 'post.permissions.reshare', value !== 'private');
          set(this, 'post.permissions.repost', value === 'private');
        })
        .finally(() => (this.isSavingChanges = false))
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    }
  }

  @action
  togglePostComments() {
    this.isSavingChanges = true;
    PostApi.toggleCommentsDisabled(this.post.postItemId, this.post.scheduled, {
      commentsDisabled: !this.post.commentsDisabled,
    })
      .then(() => {
        this.post.commentsDisabled = !this.post.commentsDisabled;
      })
      .finally(() => (this.isSavingChanges = false))
      .catch(() => FunctionalUtils.showDefaultErrorMessage());
  }
}
