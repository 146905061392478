import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { scheduleOnce, later, bind } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';

import { isDefined } from 'mewe/utils/miscellaneous-utils';
import { utcTimeOfDayMillis, shiftFromUtc, is12HourFormat } from 'mewe/utils/datetime-utils';

export const filterTimeOptions = (text, timeOptions, is12HourFormat) => {
  if (!text) return timeOptions.filter((o) => o.minutes % 15 === 0);

  const match = text.match(/[\s]*([\D]*)?[\s]*(\d\d?)([.:,; ]*)(\d\d?)?[\s]*([\D]*)?/);

  // show all 15 min options if text contains no digits
  if (!match || match.length < 4 || !match[2]) {
    return timeOptions.filter((o) => o.minutes % 15 === 0);
  }

  let amPmTextFront = match[1], // e.g. korean has am/pm text in front
    hours = match[2],
    divider = match[3], // .: ,;<- for typos
    mins = match[4],
    amPmText = match[5],
    filtered,
    hoursInt = parseInt(hours, 10);

  //ambiguous user input "13", "110", etc.
  if (hours.length === 2 && !divider) {
    if (is12HourFormat) {
      // "13", "61", "131" etc, so probably hours[1] meant as minutes
      if (hoursInt > 12) {
        const withDivider = filterTimeOptions(
          amPmTextFront + ' ' + hours[0] + ':' + hours[1] + mins + ' ' + amPmText,
          timeOptions,
          is12HourFormat
        );

        if (withDivider.length) return withDivider; // TODO: also add 24h formats?
      } else if (mins && mins.length === 1) {
        // user input e.g. "110", can mean 1:10 or 11:0 in 12h format
        const withDivider = filterTimeOptions(
            amPmTextFront + ' ' + hours + ':' + mins + ' ' + amPmText,
            timeOptions,
            is12HourFormat
          ),
          hoursCouldBeMins = filterTimeOptions(
            amPmTextFront + ' ' + hours[0] + ':' + hours[1] + mins + ' ' + amPmText,
            timeOptions,
            is12HourFormat
          );

        hoursCouldBeMins.forEach((o) => {
          if (!withDivider.find((w) => w.millis === o.millis)) withDivider.push(o);
        });

        return withDivider.sort((a, b) => a.millis - b.millis);
      }
      // "32", "64" etc
    } else if (hoursInt > 24) {
      const withDivider = filterTimeOptions(
        amPmTextFront + ' ' + hours[0] + ':' + hours[1] + mins + ' ' + amPmText,
        timeOptions,
        is12HourFormat
      );
      if (withDivider.length) return withDivider;
    }
  }

  if (hoursInt === 0 || hoursInt === 24) {
    filtered = timeOptions;
  } else if (mins && mins.length === 2 && hoursInt > 12 && hoursInt <= 23) {
    //trying to input 24h format, make sure not filtered by am/pm text
    filtered = timeOptions.filter((o) => o.hours === hoursInt % 24);

    amPmText = null;
    amPmTextFront = null;
  } else {
    // 1:45, show only 1:XX AM/PM
    if (
      hours.length === 2 ||
      (hours.length === 1 &&
        ((divider && divider.length && divider.replace(/\s/, '').length) ||
          (mins && mins.length) ||
          amPmText ||
          amPmTextFront))
    ) {
      if (is12HourFormat) {
        filtered = timeOptions.filter((o) => o.hours === hoursInt % 12 || o.hours === (hoursInt % 12) + 12);
      } else {
        filtered = timeOptions.filter((o) => o.hours === hoursInt % 24);
      }
    } else if (is12HourFormat) {
      filtered = timeOptions.filter((o) => o.hours === hoursInt % 12 || o.hours === (hoursInt % 12) + 12);
    } else {
      filtered = timeOptions.filter((o) => o.hours.toString().indexOf(hours) === 0);
    }
  }

  if (filtered.length && is12HourFormat && (amPmText || amPmTextFront)) {
    let hasAmPm = () => false,
      hasAmPmFront = () => false;

    if (amPmText) {
      amPmText = amPmText.toLowerCase();
      hasAmPm = (lowerCased) => lowerCased.indexOf(amPmText) !== -1;
    }
    if (amPmTextFront) {
      amPmTextFront = amPmTextFront.toLowerCase();
      hasAmPmFront = (lowerCased) => lowerCased.indexOf(amPmTextFront) !== -1;
    }

    const amPmFiltered = filtered.filter((o) => {
      const lowerCased = o.formatted.toLowerCase();
      return hasAmPm(lowerCased) || hasAmPmFront(lowerCased);
    });

    if (amPmFiltered.length) filtered = amPmFiltered;
  }

  if (!filtered.length) filtered = timeOptions;

  if (isDefined(mins)) {
    const minsInt = parseInt(mins, 10);
    const is5MinInterval = (o) => o.minutes % 5 === 0;

    if (isNaN(minsInt) || minsInt > 60) {
      return filtered.filter(is5MinInterval);
    }

    if (mins.length === 1) {
      // start of minutes input, show 5 min intervals
      if (minsInt === 0) {
        //0: show only 0, 05
        filtered = filtered.filter((o) => o.minutes === 0 || o.minutes === 5);
      } else if (minsInt < 6) {
        filtered = filtered.filter((o) => {
          return is5MinInterval(o) && o.minutes.toString()[0] === mins;
        });
      } else {
        filtered = filtered.filter((o) => {
          const m = o.minutes.toString();
          return (m.length > 1 ? m[1] : m[0]) === mins;
        });
      }
    } else {
      filtered = filtered.filter((o) => o.minutes === minsInt);
    }
  } else {
    // no minutes, show 15 min intervals
    filtered = filtered.filter((o) => o.minutes % 15 === 0);
  }

  if (!filtered.length) filtered = timeOptions.filter((o) => o.minutes % 15 === 0);

  return filtered;
};

export const createTimeOptions = (locale = 'en', intervalInMins = 1) => {
  let options = [],
    maxTimeInMins = 23 * 60 + 45,
    date,
    time;

  for (time = 0; time <= maxTimeInMins; time += intervalInMins) {
    date = new Date(Date.UTC(1970, 0, 1, 0, time, 0));
    options.push({
      formatted: date.toLocaleTimeString(locale, {
        timeZone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
      }),
      millis: date.getTime(),
      minutes: date.getUTCMinutes(),
      hours: date.getUTCHours(),
    });
  }

  return options;
};

export default class MwTimePicker extends Component {
  @service account;

  @tracked _time;
  @tracked isOpened;
  @tracked shownOptions;
  @tracked selectedOption;
  @tracked highlightedOptionIndex;

  constructor() {
    super(...arguments);

    const locale = this.account.activeUser.jsLocale;

    this.is12HourFormat = is12HourFormat(locale);

    this._time = ''; // what user inputs into text field
    // a valid time option, selected either from dropdown or inputting a value that matches a time from allOptions
    this.selectedOption = null;
    // the contents of the dropdown list, filtered by user input from '_time'
    this.shownOptions = A();
    // all possible times, 1 minute intervals
    this.allOptions = createTimeOptions(locale, 1);
    // user can scroll the dropdown list with arrow keys, highlighted option gets set as selected with enter
    this.highlightedOptionIndex = -1;

    this.setClosestTimeOption(this.allOptions);

    addObserver(this, 'args.timeMillisSuggestion', this.timeSuggestionSetByParent);
    addObserver(this, '_time', this.timeInputChange);

    this._clickOutsideListener = bind(this, this.clickOutside);
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    removeObserver(this, 'args.timeMillisSuggestion', this.timeSuggestionSetByParent);
    removeObserver(this, '_time', this.timeInputChange);

    this.closeAndDestroyDropdown();
  }

  // observe time passed from parent component also after init as it can be set with delay
  timeSuggestionSetByParent() {
    if (this.selectedOption.millis !== this.args.timeMillisSuggestion) {
      this.setClosestTimeOption(this.allOptions);
    } else {
      this._millis = this.selectedOption.formatted;
      this.args.timeChanged(this.selectedOption.millis);
    }
  }

  // update dropdown options on every input change
  timeInputChange() {
    if (!this.isOpened) return;

    this.highlightedOptionIndex = 0;

    let found = this._time && this.allOptions.find((o) => o.formatted === this._time);

    if (found) {
      this.selectedOption = found;
      this.shownOptions = [found];
      this.isOpened = true;

      this.args.timeChanged(found.millis);
    } else {
      const opts = this.getDropdownOptions();

      this.shownOptions = opts;
      this.isOpened = true;
    }

    this.highlightOptionFromIndex(0);
  }

  get placeholder() {
    return this.selectedOption ? this.selectedOption.formatted : __('Time');
  }

  setClosestTimeOption(options) {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) return;

      let millis;

      if (isDefined(this.args.timeMillisSuggestion)) {
        millis = this.args.timeMillisSuggestion;
      } else {
        millis = utcTimeOfDayMillis(shiftFromUtc(this.account.activeUser.timezone));
      }

      millis = Math.floor(millis / 1000) * 1000; // seconds and millis to 0

      const found = options.find((o) => o.millis === millis) || options[0];

      this._time = found.formatted;
      this.selectedOption = found;

      this.args.timeChanged(found.millis);
    });
  }

  getDropdownOptions(isFirstOpen) {
    // this is to show all dropdown options if there is initial value filled and user focused on input
    if (isFirstOpen && this._time === this.selectedOption.formatted) {
      return this.allOptions.filter((o) => o.minutes % 15 === 0);
    } else {
      return filterTimeOptions(this._time, this.allOptions, this.is12HourFormat);
    }
  }

  clickOutside(e) {
    if (!this.element.contains(e.target)) {
      this.closeAndDestroyDropdown();
    }
  }

  closeAndDestroyDropdown() {
    this.isOpened = false;

    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) return;
      this._time = this.selectedOption.formatted;
    });

    document.body.removeEventListener('click', this._clickOutsideListener);
  }

  highlightOptionFromIndex(i) {
    this.highlightedOptionIndex = i;

    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) return;

      const list = this.element.querySelector('.time-picker .dropdown-menu_list');

      if (!list) return;

      const elements = Array.from(list.getElementsByClassName('time-picker-option'));

      if (!elements.length) return;

      if (i === -1) {
        list.scrollTop = 0;
      } else {
        const el = elements[i % elements.length];

        if (el) list.scrollTop = el.offsetTop;
      }
    });
  }

  highlightPrevOrNextOption(prev) {
    if (!this.shownOptions.length) return;

    let i = this.highlightedOptionIndex;

    if (i === -1) {
      i = prev ? this.shownOptions.length - 1 : 0;
    } else {
      i += prev ? -1 : 1;

      if (i < 0) i = this.shownOptions.length - 1;
      else i = i % this.shownOptions.length;
    }

    this.highlightOptionFromIndex(i);
  }

  @action
  focusIn(e) {
    if (this.args.disabled) {
      e.preventDefault();
      return;
    }

    this.hasFocus = true;

    if (!this.isOpened) {
      const options = this.getDropdownOptions(true);

      this.shownOptions = options;
      this.isOpened = options.length > 0;

      if (this.selectedOption) {
        const foundIndex = this.shownOptions.findIndex((o) => o.millis === this.selectedOption.millis);
        if (foundIndex !== -1) this.highlightOptionFromIndex(foundIndex);
        else this.highlightOptionFromIndex(0);
      } else this.highlightOptionFromIndex(0);

      document.body.addEventListener('click', this._clickOutsideListener);
    }
  }

  @action
  focusOut() {
    this.hasFocus = false;

    // close with delay otherwise clicked dropdown option will disappear before click event
    later(
      this,
      () => {
        if (!this.hasFocus && !this.isDestroyed && !this.isDestroying) {
          this.closeAndDestroyDropdown();
        }
      },
      250
    );
  }

  @action
  changeTime(option) {
    this.selectedOption = option;
    this._time = option.formatted;

    this.args.timeChanged(option.millis);

    // blur because dropdown will be destroyed and can be opened only by focusing agian
    // so blur to aviod user continue typing after selecting option
    document.activeElement.blur();
    this.closeAndDestroyDropdown();
  }

  @action
  keyDown(e) {
    if (e.keyCode === 9 || e.keyCode === 13) {
      // tab or enter, select highlighted option or keep current
      e.preventDefault();

      if (
        this.isOpened &&
        this.shownOptions.length &&
        this.highlightedOptionIndex >= 0 &&
        this.highlightedOptionIndex < this.shownOptions.length
      ) {
        const found = this.shownOptions[this.highlightedOptionIndex];

        if (found) this.changeTime(found);
      }

      document.activeElement.blur();
      this.closeAndDestroyDropdown();
    } else if (this.isOpened) {
      if (e.keyCode === 27) {
        // close on Esc key
        document.activeElement.blur();
      } else if (e.keyCode === 38) {
        e.preventDefault();
        this.highlightPrevOrNextOption(true);
      } else if (e.keyCode === 40) {
        e.preventDefault();
        this.highlightPrevOrNextOption(false);
      }
    }
  }
}
