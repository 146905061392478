import { computed } from '@ember/object';
import createDisplayEditServerModel from 'mewe/utils/store-utils/model/display-edit-server-model.js';

import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';

var model = createDisplayEditServerModel(['listening', 'watching', 'eating', 'drinking', 'reading', 'quoting']).extend(
  modelEmojisReady,
  {
    isFilled: computed(
      'listeningServer',
      'watchingServer',
      'eatingServer',
      'drinkingServer',
      'readingServer',
      'quotingServer',
      function () {
        return (
          this.get('listeningServer') ||
          this.get('watchingServer') ||
          this.get('eatingServer') ||
          this.get('drinkingServer') ||
          this.get('readingServer') ||
          this.get('quotingServer')
        );
      }
    ),
  }
);

model.reopenClass({
  resourceName: 'status',
});

export default model;
