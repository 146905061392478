import { computed } from '@ember/object';
import { each } from 'lodash';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';

import toDisplay from 'mewe/stores/text-parsers/to-display';
import { parsersDefaultBasic } from 'mewe/stores/text-parsers/to-display';
import toEdit from 'mewe/stores/text-parsers/to-edit';
import { parsersNoMarkdown } from 'mewe/stores/text-parsers/to-edit';
import toServer from 'mewe/stores/text-parsers/to-server';

import { modelEmojisReady } from 'mewe/stores/models/mixins/model-emojis-ready';

/* Creates a model that has fields fieldNameDisplay fieldNameEdit and fieldNameServer for each fieldName from the given fieldNames param
 * also creates aliases for fieldName -> fieldNameServer
 *
 * e.g.
 * import createDisplayEditServerModel from 'mewe/utils/store-utils/model/display-edit-server-model.js';
 * var model = createDisplayEditServerModel(["name", "job"]).extend({ id: attr("string") });
 *
 * {{ field-editable label=( i18n "Name" ) value=model.nameDisplay valueEdit=model.nameEdit enableEmoji=true }}
 * {{ field-editable label=( i18n "Job" ) value=model.jobDisplay valueEdit=model.jobEdit enableEmoji=true }}
 */
export default function createDisplayEditServerModel(fieldNames) {
  let model = {},
    aliases = {};

  each(fieldNames, (fieldName) => {
    let fieldNameServer = fieldName + 'Server',
      fieldNameEdit = fieldName + 'Edit',
      fieldNameDisplay = fieldName + 'Display';

    model[fieldNameServer] = attr('string', { defaultValue: '' });

    aliases[fieldName] = fieldNameServer;

    model[fieldNameDisplay] = computed(fieldNameServer, 'emojisReady', function () {
      return toDisplay(this.get(fieldNameServer), parsersDefaultBasic);
    });

    model[fieldNameEdit] = computed(fieldNameServer, 'emojisReady', {
      get() {
        return toEdit(this.get(fieldNameServer), parsersNoMarkdown);
      },
      set(key, value) {
        this.set(fieldNameServer, toServer(value));
        return value;
      },
    });
  });

  model = Model.extend(modelEmojisReady, model);

  model.reopenClass({
    aliases: Object.assign(aliases, model.aliases),
  });

  return model;
}
