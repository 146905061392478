import Component from '@glimmer/component';
import { computed, action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { each } from 'lodash';
import { next } from '@ember/runloop';
import { Theme } from 'mewe/constants';
import Emoji from 'mewe/utils/emoji-utils';
import FeedUtils from 'mewe/utils/feed-utils';
import dispatcher from 'mewe/dispatcher';
import { postCustomInteraction } from 'mewe/utils/post-utils';

// TODO move logic from this file and mw-postbar-emoji-button to controller (MW)

export default class MwPostbarEmoji extends Component {
  @service dynamicDialogs;

  @reads('args.model') model;
  @reads('args.emoji') emoji;

  @tracked emojiReady = false;
  @tracked mouseOver;

  @tracked isEmojiListVisible;
  @tracked emojiListParent;
  @tracked element;

  constructor() {
    super(...arguments);

    if (this.args.scope === Theme.PROFILE || this.args.scope === 'myworld') {
      this.scope = Theme.CONTACTS;
    } else {
      this.scope = this.args.scope;
    }

    Emoji.allEmojiDeferred.promise.then(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.emojiReady = true;
      }
    });
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @computed('args.{simple,isMessage}')
  get showEmojiCounters() {
    return !this.args.isMessage && !this.args.simple;
  }

  @computed('args.isMessage', 'emoji.isUser')
  get isMyChatEmoji() {
    return this.args.isMessage && this.emoji?.isUser;
  }

  @action
  handleClick(e) {
    /**
     * click on user in senders list should not add emoji
     * but only redirect to profile (pure 'bubbles' param won't work with cmd+click)
     */
    const emojiSenderLink = e.target.closest('.emoji-senders_user');
    if (emojiSenderLink) return;

    const post = this.args.commentPost || this.model;

    if (post.isGroupPreview) return;

    if (post.customInteraction && this.args.scope !== 'discover') {
      postCustomInteraction(this.dynamicDialogs, post);
      return;
    }

    if (!this.model.canEmojify) return;
    if (this.model.inProgress) return;

    const emoji = this.args.emoji;

    if (emoji === undefined) {
      this.openEmojiSendersDialog();
      return;
    }

    const params = { emojis: emoji.unicode };
    const userEmojis = this.model.emojis?.userEmojis;
    const options = {
      model: this.model,
      scope: this.scope,
    };

    if (userEmojis.includes(emoji.unicode)) {
      set(emoji, 'counter', emoji.counter - 1);
      set(emoji, 'isUser', false);

      if (emoji.counter === 0) {
        this.model.emojisItems?.removeObject(this.args.emoji);
      }

      dispatcher.dispatch('emojify', 'removeEmoji', this.model, emoji.unicode);

      // besides current model update also find posts/comments in stores to update them
      if (!this.args.isMessage) {
        let posts = FeedUtils.matchPostsFromAllFeeds(this.model.postItemId).concat(this.model);

        each(posts, (post) => {
          if (this.model.isComment) {
            let commentPost = FeedUtils.getCommentOrReplyFromPost(this.model, post);
            if (commentPost) dispatcher.dispatch('emojify', 'removeEmoji', commentPost, emoji.unicode);
            return true;
          } else {
            dispatcher.dispatch('emojify', 'removeEmoji', post, emoji.unicode);
          }
        });
      }

      this.args.api.deleteEmoji(options, params);
    } else {
      set(emoji, 'isUser', true);

      dispatcher.dispatch('emojify', 'addEmoji', this.model, emoji.unicode);

      // besides current model update also find posts/comments in stores to update them
      if (!this.args.isMessage) {
        let posts = FeedUtils.matchPostsFromAllFeeds(this.model.postItemId).concat(this.model);

        each(posts, (post) => {
          if (this.model.isComment) {
            let commentOrReply = FeedUtils.getCommentOrReplyFromPost(this.model, post);
            if (commentOrReply) dispatcher.dispatch('emojify', 'addEmoji', commentOrReply, emoji.unicode);
            return true;
          } else {
            dispatcher.dispatch('emojify', 'addEmoji', post, emoji.unicode);
          }
        });
      }

      // small emoji picker is destroyed on emoji click
      if (!this.args.smallEmojiPicker) {
        set(this, 'model.inProgress', true);
      }

      this.args.api?.postEmoji(options, params.emojis).finally(() => {
        if (this.isDestroyed || this.isDestroying) return;
        set(this, 'model.inProgress', false);
      });
    }

    this.closeEmojiListPopup();
  }

  @action
  handleMouseEnter() {
    const post = this.args.commentPost || this.model;
    if (post.discoverScope === 'group') return;
    if (post.isGroupPreview) return;
    if (post.customInteraction) return;

    if (this.args.smallEmojiPicker) return;
    if (this.args.simple && !this.args.isMessage) return;

    // to prevent SG-14993
    if (this.model.inProgress) {
      set(this, 'model.inProgress', false);
      return;
    }

    this.mouseOver = true;

    next(() => {
      if (this.openingDialog) {
        this.openingDialog = false;
      } else {
        this.showEmojisListPopup();
      }
    });
  }

  @action
  handleMouseLeave() {
    this.mouseOver = false;
  }

  showEmojisListPopup() {
    this.emojiListParent = this.element;
    this.isEmojiListVisible = true;
  }

  @action
  closeEmojiListPopup() {
    this.emojiListParent = null;
    this.isEmojiListVisible = false;
  }

  openEmojiSendersDialog() {
    // prevent opening popup on mobile where click event is preceeded by mouseEnter (SG-32164)
    this.openingDialog = true;

    this.closeEmojiListPopup();

    this.dynamicDialogs.openDialog('emoji-senders-dialog', {
      api: this.args.api,
      model: this.model,
      commentPost: this.args.commentPost,
      scope: this.scope,
      emoji: this.args.emoji,
      isAllTab: this.args.isAllTab,
    });
  }
}
