import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { scheduleOnce } from '@ember/runloop';

import { Theme, Target } from 'mewe/constants';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { getElHeight, getOffset } from 'mewe/utils/elements-utils';

export default class MwPostboxPlaceholder extends Component {
  @service account;
  @service router;
  @service dynamicDialogs;
  @service('postboxUpload') uploadService;

  @tracked open;
  @tracked postbox;
  @tracked filesToUpload;
  @tracked posterPopupOpened = false;
  @tracked postedByPage = true; // currently hardcoded to true, in future we want an option to post to page as user
  @tracked dropable;

  @action
  onInsert(element) {
    this.element = element;

    this.dragoverHandlerBind = this.dragoverHandler.bind(this);
    document.addEventListener('dragover', this.dragoverHandlerBind);

    this.dropHandlerBind = this.dropHandler.bind(this);
    this.element.addEventListener('drop', this.dropHandlerBind);
  }

  @action
  onDestroy() {
    document.removeEventListener('dragover', this.dragoverHandlerBind);
    document.removeEventListener('drop', this.dropHandlerBind);
  }

  dragoverHandler(e) {
    const files = e.dataTransfer;
    if (files && files.items && files.items[0] && files.items[0].kind !== 'file') {
      e.preventDefault();
      return;
    }
    if (this.uploadService.postboxOpened) {
      e.preventDefault();
      return;
    }
    // don't show drop zone when some dialog is opened
    if (this.dynamicDialogs.components.length) {
      e.preventDefault();
      return;
    }
    if (!this.isDestroyed && !this.isDestroying) {
      this.initDropZone();
    }
  }

  dropHandler(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    if (this.uploadService.postboxOpened) return;

    this.filesToUpload = ev.dataTransfer.files;
    this.openPostbox();
  }

  initDropZone() {
    const timeout = this.dropZoneTimeout;

    if (!timeout) {
      this.dropable = true;
    } else {
      clearTimeout(timeout);
    }

    const dropZoneTimeout = setTimeout(() => {
      if (!this.isDestroying && !this.isDestroyed) {
        this.dropZoneTimeout = null;
        this.dropable = false;
      }
    }, 100);

    this.dropZoneTimeout = dropZoneTimeout;
  }

  get groupIdOrEventGroupId() {
    return this.args.targetId || this.args.eventState?.rootEvent?.groupId;
  }

  get postboxPlaceholder() {
    const theme = this.args.theme;

    if (theme === 'event') {
      return __('Post to this event');
    } else if (theme === 'group') {
      return __('Post to this group');
    } else if (theme === 'groups') {
      return __('Share something with one of your groups');
    } else if (theme === 'mycloud') {
      return __('Add photos and files to My Cloud');
    } else if (theme == 'page') {
      return __('Share a post with your followers');
    } else {
      return __(`Tell your friends what’s happening`);
    }
  }

  @action
  openPostbox(e) {
    // don't open when clicking on close button. event not present when action called from dropHandler
    if (e?.target.closest('.postbox_close-button')) return;
    // don't open when clicking on page overlay
    if (e?.target.closest('.overlay-postbox')) return;

    this.open = true;
    this.posterPopupOpened = false;
    this.recalculatePostboxScroll();
  }

  get avatarUrl() {
    if (this.args.page && this.postedByPage) {
      return this.args.page.avatar;
    } else {
      // might be not present yet on first load after login
      if (this.account.activeUser._links?.avatar?.href) {
        let userAvatar =
          EnvironmentUtils.getImgHost(true) +
          this.account.activeUser._links.avatar.href.replace('{imageSize}', '150x150');
        return this.groupIdOrEventGroupId
          ? userAvatar + `&group=${this.groupIdOrEventGroupId}&f=${this.account.activeUser.fprint}`
          : userAvatar;
      }
    }
  }

  get target() {
    if (typeof this._target !== 'undefined') {
      return this._target;
    }

    switch (this.args.theme) {
      case Theme.PROFILE:
      case Theme.CONTACTS:
        return Target.CONTACTS;

      case Theme.EVENT:
      case Theme.EVENTS:
        return Target.EVENT;

      case Theme.GROUP:
      case Theme.GROUPS:
        return Target.GROUP;

      case Theme.PRIVATEPOSTS:
        return Target.PRIVATEPOST;

      case Theme.PAGES:
      case Theme.PAGE:
        return Target.PAGE;

      default:
        return '';
    }
  }

  @action
  handleAvatarClick(event) {
    event.stopPropagation(); // prevent opening postbox
    this.openUserProfile();
  }

  openUserProfile() {
    const theme = this.args.theme;

    if (theme === 'event' && this.args.eventState?.rootEvent?.id) {
      this.router.transitionTo(
        'app.event.attendees.profile',
        this.args.eventState.rootEvent.id,
        this.account.activeUser.id
      );
    } else if (theme === 'group' && this.args.targetId) {
      this.router.transitionTo('app.group.members.profile', this.args.targetId, this.account.activeUser.id);
    } else {
      this.router.transitionTo('app.publicid', this.account.activeUser.profileId);
    }
  }

  @action
  close() {
    this.filesToUpload = null;
    this.open = false;

    const bodyEl = document.body;
    bodyEl.style.overflowY = '';
    bodyEl.style.height = '';
    bodyEl.classList.remove('postbox-open');
  }

  @action
  sendPostboxToParent(postbox) {
    this.postbox = postbox;
  }

  @action
  closeWithConfirm() {
    let postbox = this.postbox;
    if (postbox) {
      postbox.close(); // sends "close" back if user confirms
    } else {
      this.close();
    }
  }

  @action
  recalculatePostboxScroll() {
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroying || this.isDestroyed) return;

      let postboxEl = this.element.querySelector('.postbox');
      if (!postboxEl) return;

      let fileOrPollDropdown = postboxEl.querySelectorAll('.dropdown-menu, .select-menu:not(.hidden)'); // .dropdown-menu for file options dropdown, .select-menu for poll length selectors

      const offset = getOffset(postboxEl);
      const offsetTop = offset ? offset.top : 0;
      const spaceBottom = this.datePickerOpened ? 190 : 20;

      let postboxHeight =
          getElHeight(postboxEl) +
          spaceBottom +
          offsetTop +
          (fileOrPollDropdown && fileOrPollDropdown.length ? getElHeight(fileOrPollDropdown[0]) : 0),
        scrollHeight = window.innerHeight - 20,
        newBodyHeight = scrollHeight < postboxHeight ? postboxHeight : scrollHeight;

      const bodyEl = document.body;
      bodyEl.style.overflowY = 'hidden';
      bodyEl.classList.add('postbox-open');

      if (getElHeight(bodyEl) !== newBodyHeight) {
        bodyEl.style.height = `${newBodyHeight}px`;
      }
    });
  }

  @action
  datePickerOpenedUpdate(value) {
    this.datePickerOpened = value;
  }

  @action
  closeDropdown() {
    this.posterPopupOpened = false;
  }
}
