/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { getOwner } from '@ember/application';

export default class MwDialog extends Component {
  zIndex = 21;

  constructor() {
    super(...arguments);

    if (this.args.blurApp) {
      this.setBlur(true);
    }

    this.registerListeners();
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (!this.args.doNotEscapeClose) {
      document.removeEventListener('keyup', this.generalEscapeHandlerBind);
    }

    if (this.args.blurApp) {
      this.setBlur(false);
    }
  }

  get style() {
    return `z-index:${this.zIndex};${this.args.style ?? ''}`;
  }

  registerListeners() {
    if (!this.args.doNotEscapeClose) {
      this.generalEscapeHandlerBind = this.generalEscapeHandler.bind(this);
      document.addEventListener('keyup', this.generalEscapeHandlerBind);
    }
  }

  generalEscapeHandler(e) {
    if (this.args.doNotEscapeClose) {
      return;
    }

    // escape key maps to keycode `27`
    if (e.keyCode == 27) {
      this.args.onClose?.();
    }
  }

  setBlur(to) {
    getOwner(this).lookup('route:app').controller.model.set('blurApp', to);
  }
}
