import EmberObject from '@ember/object';
import { ActionHandler } from '@ember/-internals/runtime';

let state = EmberObject.create({
  followers: 0,
  favorites: 0,
  requestsReceived: 0,
  requestsSent: 0,
  blocked: 0,
  imported: 0,
  chats: 0,
});

const self = EmberObject.extend(ActionHandler, {
  getState() {
    return state;
  },

  actions: {
    handle: (res) => state.setProperties(res),
  },
});

export default self.create();
