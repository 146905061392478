import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { each } from 'lodash';

import PhotosApi from 'mewe/api/photos-api';
import Scrolling from 'mewe/utils/scrolling-utils';
import PS from 'mewe/utils/pubsub';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { Theme } from 'mewe/constants';
import { inject as service } from '@ember/service';

// scope: Theme.GROUP/EVENT/MYCLOUD/PROFILE, groupId, eventId, userId

export default class MwPhotoTag extends Component {
  @service dynamicDialogs;

  @tracked loading = true;
  @tracked hashtags = A();
  @tracked hasMoreResults = false;

  maxResults = 20;
  offset = 0;

  constructor() {
    super(...arguments);

    this.scrolling = Scrolling();
    addObserver(this, 'args.order', this, this.reRenderView);

    PS.Sub('photoTags.reload', () => {
      this.reRenderView();
    });

    this.renderView();
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown();
    PS.Unsub('photoTags.reload', this.reRenderView);
    removeObserver(this, 'args.order', this, this.reRenderView);
  }

  renderView() {
    this.loading = true;

    const params = {
      offset: this.offset,
      maxResults: this.maxResults,
      order: this.args.order || 0,
      sort: 'hashtags', // sorting order with hashtags, can be 'images' also
    };

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      const hashtagsData = data.hashtags || data.feed;
      let hashtags = hashtagsData;

      each(
        hashtags,
        (hashtag) =>
          (hashtag.backgroundImageStyle = htmlSafe(
            `background-image: url(${
              EnvironmentUtils.getImgHost(true) + hashtag._links.mainPic.href.replace('{imageSize}', '400x400')
            });`
          ))
      );

      this.hashtags.pushObjects(hashtags);

      if (hashtagsData.length >= this.maxResults) {
        this.scrolling.bindScrollDown(() => {
          this.renderView();
        });

        // hashtag elements are small in UI, there is a big chance that there won't be scrollbar
        // to scroll for more results. So for that reason we show also a button to load more results
        this.hasMoreResults = true;
      } else {
        this.scrolling.unbindScrollDown();
        this.hasMoreResults = false;
      }

      this.offset += this.maxResults;
      this.loading = false;
    };

    let scopeId;

    if (this.args.scope === Theme.EVENT) scopeId = this.args.eventId;
    else if (this.args.scope === Theme.GROUP) scopeId = this.args.groupId;
    else if (this.args.scope === Theme.PROFILE) scopeId = this.args.userId;
    else if (this.args.scope === Theme.PAGE) scopeId = this.args.pageId;

    PhotosApi.getPhotosets(this.args.scope, scopeId, params).then(callback);
  }

  reRenderView() {
    if (this.isDestroyed || this.isDestroying) return;

    this.offset = 0;
    this.hashtags.clear();
    this.renderView();
  }

  @action
  openPhotoTag(phototag) {
    const options = {
      hashtag: phototag.name,
      postId: phototag.mediaId || phototag.imageId || phototag.mainPostId,
      galleryView: (phototag.count || phototag.photosCount) !== 1,
      groupId: this.args.groupId,
      eventId: this.args.eventId,
      pageId: this.args.pageId,
      scope: this.args.scope,
      allowMultipleInstances: true,
    };

    this.dynamicDialogs.openDialog('media-dialog', options);
  }

  @action
  loadMoreAction() {
    this.renderView();
  }
}
