import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { map, filter } from 'lodash';
import { getEmojiUrlCdn, getStickersDeferred, getStickersHashMap } from 'mewe/utils/emoji-utils';
import { get } from 'mewe/utils/fp';

let getStickerUrl = (packId, stickerId) =>
  map(filter(get('stickers', get(packId, getStickersHashMap())), { name: stickerId }), 'png.default').shift();

export default class TheSticker extends Component {
  @tracked areStickersLoaded = false;
  height = 144;

  constructor() {
    super(...arguments);
    this.loadStickers();
  }

  async loadStickers() {
    await getStickersDeferred().promise;
    this.areStickersLoaded = true;
  }

  @computed('args.sticker', 'areStickersLoaded')
  get src() {
    let url = getStickerUrl(this.args.sticker.package, this.args.sticker.id);
    return url && this.areStickersLoaded ? getEmojiUrlCdn() + url : null;
  }
}
