import Component from '@glimmer/component';
import { computed } from '@ember/object';

import PaymentsApi from 'mewe/api/payments-api';
import { ds } from 'mewe/stores/ds';

export default class AppPageAbout extends Component {
  @computed('args.model')
  get page() {
    return this.args.model.appPage ? this.args.model.appPage.page : this.args.model.page;
  }

  constructor() {
    super(...arguments);

    if (this.page?.isOpenPage) return;

    this.buckets = ds.buckets;

    PaymentsApi.getCountries().then((data) => {
      if (this.isDestroyed || this.isDestroying) return;
      this.countries = data.countries;
    });
  }

  @computed('page.isOpenPage')
  get category() {
    return this.page.isOpenPage ? this.page.category : this.page.category.name;
  }

  @computed('page.category.bucketId', 'buckets.items.length')
  get currentBucket() {
    return this.buckets?.items.find((i) => i.id === this.page?.category.bucketId);
  }

  @computed('countries.length', 'page.address.country')
  get country() {
    if (this.page?.address.country && this.countries?.length) {
      return this.countries.find((el) => {
        return el.code === this.page?.address.country || el.name === this.page?.address.country;
      });
    }

    return { name: '', code: '' };
  }
}
