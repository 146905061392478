import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject, { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

import Storage from 'mewe/shared/storage';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import CurrentUserStore from 'mewe/stores/current-user-store';
import AccountApi from 'mewe/api/account-api';
import { emailRegex, dsnpHandleRegex, getRedirToInnerAppQueryParam } from 'mewe/shared/utils';
import { trimAndLower, getWalletHost, isDefined } from 'mewe/utils/miscellaneous-utils';
import { timeFromNow } from 'mewe/utils/datetime-utils.js';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import { getQueryStringParams } from 'mewe/shared/utils';
import { publicIdEmailParams } from 'mewe/constants';
import Session from 'mewe/shared/session';
import cookie from 'mewe/shared/cookie';
import config from 'mewe/config';
import i18n from 'i18next';

export default class MwHomeLoginFormComponent extends Component {
  challengeOrigin = 'login';
  arkoseKey = config.arkoseKeyLog;
  hcaptchaSiteKey = config.hcaptcha.login;
  hcaptchaElemClass = 'h-captcha_login';
  challengeToken;
  challengeProvider;
  onChallengeFailed;

  @tracked afterChallengeCallback;
  @tracked challengeReady;

  @service router;
  @service analytics;
  @service phoneInput;
  @service dynamicDialogs;
  @service authentication;

  @tracked email = '';
  @tracked password = '';
  @tracked handle = '';
  @tracked stepNumber; // unknown by default, to be set in constructor
  @tracked isEmailFlow = true; // on Web show email input by default
  @tracked isDsnpLogin; // true if login via AA, false if login using password
  @tracked isDsnpUser; // true if user is DSNP user

  @tracked invalidEmail = true; // invalid because empty by default
  @tracked invalidPhone = true; // invalid because empty by default
  @tracked invalidPassword = false; // invalidated only by API error response
  @tracked loginBlocked = false;
  @tracked needConfirmEmail = false;
  @tracked emailSentText;
  @tracked initialCountry = 'us';
  @tracked isIdentifierSubmitted;
  @tracked isPasswordSubmitted;
  @tracked isCheckingIndentity;
  @tracked loginRequestInProgress;
  @tracked isLoadingIframeView;
  @tracked dsnpParams;
  @tracked error;

  @tracked migrationIdentifiers = [];
  @tracked isLoadingIdentifiers = false;
  @tracked isSubmitMigrationIdentifiersDisabled = false;

  minPasswordLength = 6;

  // This component is used for 2 flows:
  // - login flow, can be done with password or with AA authentication
  // - migration flow, including login as first step, then handle claiming, then identifiers selection and then amplica iframe view

  // ====== STEPS ======
  // 0 - email/phone input, identification step
  // 1 - password input, authentication step
  // 2 - handle input, handle selection step (migration flow only)
  // 3 - migration identifiers selection step
  // 4 - amplica iframe view, authentication of DSNP user or migration of non-DSNP user
  // 5 - forgot password

  constructor() {
    super(...arguments);

    const blockedUntil = cookie.get('loginBlockedUntil') || 0;
    const isLockedError = +blockedUntil > new Date().getTime();
    if (isLockedError) {
      this.loginLockedUntil = timeFromNow(+blockedUntil, Date.now(), i18n.language);
      this.loginBlocked = true;
      return;
    }

    this.urlParams = getQueryStringParams();

    this.iframeLoginMessageListenerBind = this.iframeLoginMessageListener.bind(this);
    this.iframeMigrationMessageListenerBind = this.iframeMigrationMessageListener.bind(this);

    Session.isAuthenticated().then(({ isAuthenticated }) => {
      this.isLoggedIn = isAuthenticated;

      if (this.isLoggedIn) {
        if (this.isMigrationPage) {
          this.initialiseHandleStep();
        } else {
          this.goToApp();
        }
      } else {
        // not logged in user, show identification step
        this.phoneInput.load();
        this.stepNumber = 0;

        if (~document.location.search.indexOf('next=')) {
          this.error = __(
            `The page you are trying to reach is only available to logged in users. Please login to access.`
          );
        }

        // email can be passed to be prefilled in the form
        if (this.urlParams?.email) {
          this.email = this.urlParams.email;
          this.emailUpdated();
        }

        // remove 'xTraceId' that could remain e.g. from previous loggin attempt
        Storage.remove(Storage.keys.xTraceId);
      }
    });
  }

  get isMigrationPage() {
    return this.args.isMigrationPage && !this.migrationAbandoned;
  }

  get emailValue() {
    return trimAndLower(this.email);
  }

  @action
  emailUpdated() {
    // reset submitted state on any change
    this.isIdentifierSubmitted = false;

    const isValid = emailRegex.test(this.emailValue);
    this.invalidEmail = !isValid;
  }

  get showEmailError() {
    return this.isIdentifierSubmitted && this.invalidEmail;
  }

  get handleValue() {
    return trimAndLower(this.handle).replaceAll(' ', '');
  }

  get isHandleValid() {
    return dsnpHandleRegex.test(this.handleValue);
  }

  @action
  phoneUpdated(number, params) {
    // hack for testers to be able to use +89 as a country code
    if (number.length === 12 && number.slice(0, 3) === '+89') {
      params.selectedCountryData.dialCode = '89';
      params.isValidNumber = true;
    }

    // reset submitted state on any change
    this.isIdentifierSubmitted = false;

    this.phoneCode = `+${params.selectedCountryData?.dialCode}`;
    this.phoneNumber = number.replace(this.phoneCode, '');
    this.invalidPhone = !params.isValidNumber;
  }

  get showPhoneError() {
    return this.isIdentifierSubmitted && this.invalidPhone;
  }

  get passwordValue() {
    return this.password.trim();
  }

  get showPasswordError() {
    return this.isPasswordSubmitted && (this.invalidPassword || this.passwordValue.length < this.minPasswordLength);
  }

  @action
  passwordUpdated() {
    this.invalidPassword = false;
  }

  getTraceId() {
    return this.isMigrationPage ? 'migration' : `login/${this.isEmailFlow ? 'email' : 'sms_code'}`;
  }

  get isSubmitLoginIdentifierDisabled() {
    return (
      !this.challengeReady ||
      this.isCheckingIndentity ||
      (this.isEmailFlow && this.invalidEmail) ||
      (!this.isEmailFlow && this.invalidPhone)
    );
  }

  get isConfirmPasswordDisabled() {
    return this.loginRequestInProgress || this.passwordValue.length < this.minPasswordLength;
  }

  get dsnpRequestUrl() {
    const host = getWalletHost();

    if (this.isMigrationPage) {
      return `${host}/signup/onboard/form?version=3`;
    } else {
      return `${host}/login/${this.isEmailFlow ? 'email' : 'sms_code'}/form?version=3`;
    }
  }

  get showFooter() {
    return ~[0, 2].indexOf(this.stepNumber);
  }

  get showBackArrow() {
    // step 0, nothing to go back to
    // step 5 reset password has no stepping back
    if (this.stepNumber === 0 || this.stepNumber === 5) return false;
    // step 2, handle claim. Can't go back to login from here, user already logged in
    if (this.stepNumber === 2 && this.isMigrationPage) return false;

    return isDefined(this.stepNumber); // initially stepNumber is not defined
  }

  get hasErrors() {
    if (this.isEmailFlow) {
      return this.invalidEmail;
    } else {
      return this.invalidPhone;
    }
  }

  initialiseHandleStep() {
    const currentUser = CurrentUserStore.getState();

    // user already migrated, redirect to app
    if (currentUser.dsnpDetails?.handle || currentUser.dsnpHandle) {
      return this.goToApp(true);
    }

    // prefill dsnp handle with mewe handle
    this.handle = currentUser.publicLinkId;
    this.stepNumber = 2;
  }

  fetchIdentifier() {
    const afterFetch = () => {
      if (this.migrationIdentifiers.length === 1) {
        this.submitMigrationIdentifiers();
      } else {
        this.stepNumber = 3;
      }
    };

    // no need to refetch identifiers if user did step back and forth again
    if (this.migrationIdentifiers.length) {
      afterFetch();
      return;
    }

    this.isLoadingIdentifiers = true;

    // need to fetch user data to get his email/phone for migration
    AccountApi.getIdentifiers()
      .then((data) => {
        if (data.emails?.length) {
          data.emails.forEach((email) => {
            this.migrationIdentifiers.push(
              EmberObject.create({
                emailOrPhone: email,
                type: 'email',
                isSelected: true,
              })
            );
          });
        }

        if (data.phone) {
          this.migrationIdentifiers.push(
            EmberObject.create({
              emailOrPhone: data.phone,
              type: 'phone',
              isSelected: true,
            })
          );
        }

        afterFetch();
      })
      .finally(() => {
        this.isLoadingIdentifiers = false;
      });
  }

  @action
  submitLoginIdentifier(isDsnpLogin) {
    this.isDsnpLogin = isDsnpLogin;

    this.analytics.sendEvent('logInIdentifierEntered', {
      is_web3_migration: this.isMigrationPage,
      login_type: this.isEmailFlow ? 'email' : 'phone',
    });

    // we track user choice of login method only on login page, not on migration page
    if (!this.isMigrationPage) {
      this.analytics.sendEvent('buttonClicked', isDsnpLogin ? 'Login with Custodial Wallet' : 'Login with Password');
    }

    this.checkIdentifier();
  }

  // checkIdentifier is called after submitting email/phone in step 0 when user is not logged in
  checkIdentifier() {
    // display possible errors
    this.isIdentifierSubmitted = true;

    if (this.hasErrors) {
      return false;
    }

    this.isCheckingIndentity = true;

    if (this.isEmailFlow) {
      PublicPagesApi.checkAccountByEmail(this.emailValue)
        .then((res) => this.identitifierCheckCallback(res))
        .catch((err) => this.identifierCheckFailback(err));
    } else {
      PublicPagesApi.checkAccountByPhone(this.phoneCode, this.phoneNumber)
        .then((res) => this.identitifierCheckCallback(res))
        .catch((err) => this.identifierCheckFailback(err));
    }
  }

  identitifierCheckCallback(res = {}) {
    // identifier check should return `trc` which is an ID generated by the backend
    // and used to identify user in following requests in order to debugg issues
    // !IN MIGRATION FLOW! we get this trc from migrationPayload request
    if (!this.isMigrationPage) {
      this.traceId = res.trc;
    }

    Storage.set(Storage.keys.xTraceId, res.trc);

    // non-existing account for this identificator
    if (!res.exists) {
      if (this.isEmailFlow) {
        this.invalidEmail = true;
      } else {
        this.invalidPhone = true;
      }

      this.isCheckingIndentity = false;
      return;
    }

    // === logged in user ===
    // - if migration page then go to handle claiming (step 2)
    // - if login page then redirect to app
    if (this.isLoggedIn) {
      this.stepNumber = this.isMigrationPage ? 2 : this.goToApp();
      this.isCheckingIndentity = false;
      return;
    }

    // === non-logged in user ===
    // ======= existing DSNP account - Amplica login ========
    if (res.dsnp && res.hasMsaId && this.isDsnpLogin) {
      this.isDsnpUser = true;

      if (this.isMigrationPage) {
        // if we detect DSNP user trying to do migration then we switch to regular login flow,
        // it's important to do this before setting dsnpParams to get proper dsnpRequestUrl
        this.migrationAbandoned = true;
      }

      this.afterChallengeCallback = (challengeToken, provider, onChallengeFailed) => {
        this.onChallengeFailed = onChallengeFailed;

        const loginPayloadParams = {
          sessionToken: challengeToken,
          challengeProvider: provider,
        };

        if (this.isEmailFlow) {
          loginPayloadParams.email = this.emailValue;

          // passing origin of registration flow, later it will be used for redirection after confirming email.
          // in sms flow it's not needed because user is redirected from current component so it will be checked here.
          publicIdEmailParams.forEach((param) => {
            if (this.urlParams?.[param]) {
              loginPayloadParams[param] = this.urlParams[param];
            }
          });

          PublicPagesApi.getEmailLoginPayload(loginPayloadParams, this.traceId)
            .then((res) => this.handleDsnpLoginPayload(res))
            .catch((err) => this.onChallengeFailed(err));
        } else {
          loginPayloadParams.countryCode = this.phoneCode;
          loginPayloadParams.phone = this.phoneNumber;

          PublicPagesApi.getPhoneLoginPayload(loginPayloadParams, this.traceId)
            .then((res) => this.handleDsnpLoginPayload(res))
            .catch((err) => this.onChallengeFailed(err));
        }
      };
    } else {
      this.stepNumber = 1;
      this.isCheckingIndentity = false;
    }
  }

  identifierCheckFailback(error) {
    if (error.data?.errorCode === 100) {
      this.invalidEmail = true;
    } else if (error.data?.errorCode === 115) {
      this.invalidPhone = true;
    } else {
      FunctionalUtils.showDefaultErrorMessage();
    }

    this.isCheckingIndentity = false;
  }

  handleDsnpLoginPayload(res = {}) {
    // existing DSNP account - login payload exists and can be used for DSNP login,
    // setting payload to 'dsnpParams' triggers loading Amplica screen in the iframe
    // (login payload is returned also when user is already logged in)
    this.dsnpParams = JSON.stringify(res.login);

    // send this event only when user actually submits handle and not during login process
    if (this.isMigrationPage) {
      this.analytics.sendEvent('web3HandleSubmitted');
    }

    // remove possible previous listener if user moved to previous step and then back to this step
    window.removeEventListener('message', this.iframeLoginMessageListenerBind);
    window.addEventListener('message', this.iframeLoginMessageListenerBind);
  }

  iframeLoginMessageListener(e = {}) {
    if (e.origin !== getWalletHost()) {
      return;
    }

    if (e.data?.type === 'smsPayloadV3') {
      this.doLogin(e.data.payload);
    }

    if (e.data?.type === 'error') {
      this.custodialWalletFailed(e.data);
    }
  }

  iframeMigrationMessageListener(e = {}) {
    if (e.origin !== getWalletHost()) {
      return;
    }

    if (e.data?.type === 'onboardPayload') {
      PublicPagesApi.confirmMigration({ sessionId: e.data.payload.sessionId }, this.traceId)
        .then(() => (window.location = '/myworld#dsnp'))
        .catch(() => FunctionalUtils.showDefaultErrorMessage());
    }

    if (e.data?.type === 'error') {
      this.custodialWalletFailed(e.data);
    }
  }

  custodialWalletFailed(data) {
    this.analytics.sendEvent('custodialWalletFailed', {
      error_id: data.payload.id,
      description: data.payload.description,
      stack_trace: data.payload.stackTrace,
      session_id: this.sessionId ? this.sessionId : null,
      trace_id: this.getTraceId(),
    });
  }

  // called when iframe with form is inserted into DOM
  @action
  dsnpFormReady() {
    // submit form in the iframe to load DSNP login view
    document.querySelector('#dsnp-form').submit();

    this.loadIframe = new Promise((resolve) => {
      // wait until iframe is loaded and display the iframe when it's ready
      const iframe = document.getElementById('dsnp-iframe');
      iframe.addEventListener('load', () => {
        8;
        resolve();
        this.stepNumber = 4;
        this.isCheckingIndentity = false;
        this.isLoadingIframeView = false;

        this.analytics.sendEvent('amplicaWebpageLoaded', {
          trace_id: this.getTraceId(),
        });
      });
    });
  }

  @action
  submitPassword(e) {
    // prevent default form submittion
    e.preventDefault();

    this.isPasswordSubmitted = true;
    this.loginRequestInProgress = true;

    this.afterChallengeCallback = (challengeToken, provider, onChallengeFailed) => {
      this.doLogin({ challengeToken, provider, onChallengeFailed });
    };
  }

  @action
  submitHandle() {
    if (this.isHandleValid) {
      this.fetchIdentifier();
    }
  }

  @action
  toggleMigrationIdentifier(identifier) {
    set(identifier, 'isSelected', !identifier.isSelected);
    this.isSubmitMigrationIdentifiersDisabled = this.migrationIdentifiers.every((identifier) => !identifier.isSelected);
  }

  @action
  submitMigrationIdentifiers() {
    this.isLoadingIframeView = true;

    const params = {
      handle: this.handleValue,
      skipEmails: [],
    };

    this.migrationIdentifiers.forEach((identifier) => {
      if (!identifier.isSelected) {
        if (identifier.type === 'phone') {
          params.skipPhone = identifier.emailOrPhone;
        } else {
          params.skipEmails.push(identifier.emailOrPhone);
        }
      }
    });

    PublicPagesApi.getMigrationPayload(params)
      .then((res) => {
        this.traceId = res.trc;

        // it's migration flow if 'signup' payload is returned
        if (res.payload) {
          // setting params will initialise iframe load and it will be displayed when ready
          this.dsnpParams = JSON.stringify(res.payload);
        }

        // send this event only when user actually is moved to next step by setting dsnpParams
        this.analytics.sendEvent('web3HandleSubmitted');

        // remove possible previous listener if user moved to previous step and then back to this step
        window.removeEventListener('message', this.iframeMigrationMessageListenerBind);
        window.addEventListener('message', this.iframeMigrationMessageListenerBind);
      })
      .catch((res) => {
        this.isLoadingIframeView = false;

        const err = res.data;

        // 122 - handle is taken, 120 - handle is forbidden
        if (err?.errorCode === 122 || err?.errorCode === 120) {
          this.stepNumber = 2;

          this.dynamicDialogs.openDialog('simple-dialog-new', {
            message: __('Please use a different handle. This one is forbidden or taken.'),
            okButtonText: __('Ok'),
          });
          return;
        }

        FunctionalUtils.showDefaultErrorMessage();
      });
  }

  // login can be called from 2 flows:
  // - login of DSNP user with phone using SMS code,
  //   in this case 'options.code' param should be passed
  // - migration of non-DSNP user after login with regular password,
  //   in this case options should contain captcha challenge data
  doLogin(options = {}) {
    let params;
    let reqOptions = {
      traceId: this.traceId,
    };

    // non-DSNP user can try DSNP login,
    // checking both conditions to be sure he's elligible for DSNP login
    if (this.isDsnpLogin && this.isDsnpUser) {
      reqOptions.xDsnp = true;

      params = {
        username: `${this.phoneCode}${this.phoneNumber}`,
        sessionId: options.sessionId,
        code: options.token,
      };
    } else {
      params = {
        password: this.password,
        username: this.isEmailFlow ? this.emailValue : `${this.phoneCode}${this.phoneNumber}`,
        session_token: options.challengeToken,
        challenge_provider: options.provider,
      };
    }

    AccountApi.login(params, reqOptions)
      .then((response) => {
        let migrationLoginCallback;

        // login during migration redirects user to handle claiming step instead of redirecting to app
        if (this.isMigrationPage) {
          migrationLoginCallback = () => {
            this.initialiseHandleStep();
          };
        }

        this.authentication.loginCallback(params, response, this.urlParams, migrationLoginCallback);
      })
      .catch((response) => {
        this.loginRequestInProgress = false;

        if (response.status === 409) {
          if (!this.isDestroying && !this.isDestroyed) {
            this.needConfirmEmail = true;
          }
        } else if (response.status === 400 && response.data.errorCode == 117) {
          options.onChallengeFailed();
        } else if (
          response.status === 400 &&
          ((response.data && response.data.message) || '').indexOf('Something wrong happened') === 0
        ) {
          FunctionalUtils.showDefaultErrorMessage();
          later(this, () => window.location.reload(), 1000);
        } else if (response.status === 401 && response.data.block) {
          if (!this.isDestroying && !this.isDestroyed) {
            this.loginLockedUntil = timeFromNow(response.data.block * 1000, Date.now(), i18n.language);
            this.loginBlocked = true;
          }

          cookie.set('loginBlockedUntil', response.data.block * 1000);
        } else {
          if (!this.isDestroying && !this.isDestroyed) {
            this.invalidPassword = true;
          }
        }
      })
      .then(() => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.sendingLoginRequest = false;
        }
      });
  }

  @action
  toggleEmailOrPhone() {
    this.email = '';
    this.phoneNumber = '';
    this.isIdentifierSubmitted = false;
    this.isEmailFlow = !this.isEmailFlow;
  }

  @action
  stepBack() {
    if (this.isMigrationPage) {
      if (this.stepNumber === 4) {
        // event send when user moved back from AA iframe
        this.analytics.sendEvent('web3ToSCancelled', { login_type: this.isEmailFlow ? 'email' : 'phone' });

        // if user has only one identifier, then he is redirected to handle claiming step
        if (this.migrationIdentifiers.length === 1) {
          this.stepNumber = 2;
        } else {
          this.stepNumber = 3;
        }
      } else {
        if (this.stepNumber === 3) {
          this.isLoadingIframeView = false;
          this.stepNumber = 2;
        }
      }
    } else {
      // moving back to step 0 - if there was abandoned migration it is allowed again
      this.migrationAbandoned = false;

      this.stepNumber = 0;
    }

    this.password = '';
    this.isPasswordSubmitted = false;
    // remove loaded iframe, it will need to be loaded with new params
    this.dsnpParams = null;
    this.sessionId = null;
  }

  @action
  goToApp(isAlreadyMigrated) {
    // already migrated user is redirected to app and sees proper info popup
    if (isAlreadyMigrated) {
      this.urlParams.hash = '#migrated';
    }

    this.urlParams.next = getRedirToInnerAppQueryParam();

    this.authentication.redirectAfterLogin(this.urlParams);
  }

  @action
  resendEmail(e) {
    e?.preventDefault();

    AccountApi.resendConfirmationLink(this.emailValue, this.traceId).then(() => {
      this.emailSentText = __('A new validation email has been sent to {email}', { email: this.emailValue });
    });
  }

  @action
  learnMoreClicked() {
    this.analytics.sendEvent('learnMoreClicked', { location: 'password screen', element: 'set a new password' });
  }

  @action
  challengeReadyCallback() {
    this.challengeReady = true;
  }
}
