import Model from 'mewe/utils/store-utils/model/model.js';
import { computed } from '@ember/object';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import EnvironmentUtils from 'mewe/utils/environment-utils';

const defaultAvatar = '/assets/images/group-photo-placeholder-full.png';

var model = Model.extend({
  id: attr('string'),

  name: attr('string'),

  _links: attr('object'),

  // used in discover feed to know if user applied to group that contains the post
  alreadyApplied: attr('boolean', {
    defaultValue: function () {
      return false;
    },
  }),

  // copy from group model, useful to have avatar link done once instead of separately in components
  avatarLink: computed('_links.{publicAvatar,groupAvatar,avatar}', function () {
    if (this.get('_links.publicAvatar.href')) {
      return EnvironmentUtils.getImgHost(true) + this.get('_links.publicAvatar.href');
    } else if (this.get('_links.avatar.href')) {
      return EnvironmentUtils.getImgHost(true) + this.get('_links.avatar.href');
    } else if (this.get('_links.groupAvatar.href')) {
      return EnvironmentUtils.getImgHost(true) + this.get('_links.groupAvatar.href');
    } else if (this.isFetching) {
      return '';
    } else {
      return defaultAvatar; // cdnHost is added automatically in build process
    }
  }),
});

model.reopenClass({
  resourceName: 'simple-group',
});

export default model;
